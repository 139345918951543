const DateTimeInput = {
  template: `
  <div><!-- for class -->
    <div class="row">
      <label class="col-form-label col-sm-3">{{ labelText }}</label>
      <div class="col-sm-9">
        <input class="form-control"
          type="datetime-local"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
           />
      </div>
    </div>
  </div>
  `,
  emits: ['update:modelValue'],
  props: {
    labelText: { type: String },
    modelValue: { type: String },
  }
}

export default DateTimeInput

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listPagedEntitesAsync } from '../api'
import { usePaging } from "../components/Paging"

const Materials = {
  name: "Materials",
  template: `
  <div class="row">
    <div class="col-sm-4">
      <label class="form-label" for="is-active">Prikaži aktivne</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-active" v-model="filters.displayActive"  />
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <label class="col-form-label col-sm-3">Išči</label>
        <div class="col-sm-9">
          <input class="form-control" v-model.lazy="filters.queryBy" />
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Naziv</th>
              <th scope="col">Ident</th>
              <th scope="col">CE prosto</th>
              <th scope="col">CE rezervirano</th>
              <th scope="col">Zaloga vozila</th>
              <th scope="col">Zaloga ostala skladišča</th>
              <th scope="col">Naročilo 020 prosto</th>
              <th scope="col">Naročilo 021 namensko</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" @click="select(w)" v-for="w in entities">
              <td>{{ w.name }}xxx</td>
              <td>{{ w.opPisProductID }}</td>
              <td v-show="w.isStockUpToDate">{{ w.freeStockCe }}  </td>
              <td v-show="w.isStockUpToDate">{{ w.reservedCe }}</td>
              <td v-show="w.isStockUpToDate">{{ w.stockVehicles }}</td>
              <td v-show="w.isStockUpToDate">{{ w.stockOther }}</td>
              <td v-show="w.isStockUpToDate">{{ w.quantityOrderedStock }}</td>
              <td v-show="w.isStockUpToDate">{{ w.quantityOrderedReserved }}</td>
              <td v-show="!w.isStockUpToDate" colspan="8" class="text-muted">Zaloga ni ažurirana</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <Paging v-if="entities?.length" :paging="paging" @change="setPageNum" />
  `,
  setup(props, { emit }) {
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const filters = ref({
      displayActive: true,
      queryBy: ""
    })

    const entities = ref([])

    const select = entity => router.push({ name: 'MaterialEdit', params: { id: entity.id } })

    const loadEntitiesAsync = async () => {
      const result = await listPagedEntitesAsync(instance, "Materials", paging.value, "all",
        `&variant=Full&isActive=${filters.value.displayActive}&q=${encodeURIComponent(filters.value.queryBy)}`)
      setNumberOfRecords(result.numberOfRecords)
      entities.value = result.entities
    }

    watch(
      () => ({ ...filters.value }),
      () => resetPaging() // will also fire getProjectsAsync()
    )

    onMounted(async () => {
      await loadEntitiesAsync()
    })

    const { paging, setNumberOfRecords, setPageNum, resetPaging } = usePaging(loadEntitiesAsync)

    return { filters, entities, select, worker, paging, setPageNum, resetPaging }
  }
}

export default Materials

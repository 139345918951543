import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { useMsal } from "../composition-api/useMsal"
import { changePauseAsync } from "../api"

const Pause = {
  template: `
  <span>
    <button v-show="!!worker.workPauseStartDateTime.value" class="btn btn-sm btn-outline-danger" @click="endPauseAsync">Zaključi pavzo</button>
    <button v-show="!worker.workPauseStartDateTime.value && !!worker.id.value" class="btn btn-sm btn-outline-success" @click="pauseAsync">Pavza</button>
  </span>
  `,
  setup(props, { emit }) {
    const { worker, loadCurrentWorkerAsync } = useIsAuthorized()
    const { instance } = useMsal()

    const pauseAsync = async () => {
      await changePauseAsync(instance)
      await loadCurrentWorkerAsync(true)
    }

    const endPauseAsync = async () => {
      await changePauseAsync(instance, true)
      await loadCurrentWorkerAsync(true)
    }

    return { pauseAsync, endPauseAsync, worker }
  }
}

export default Pause

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'

import { useMsal } from "../composition-api/useMsal"
import { fetchAuthenticated } from "../utils/fetchAuthenticated"
import { setError, startLoading, stopLoading } from "./Notification"
import Camera from "./Camera"

const AttachFromCamera = {
  name: 'AttachFromCamera',
  components: { Camera },
  template: `
  <Modal :visible="visible" @close="$emit('close')" title="Dodaj fotografijo">
    <div class="row">
      <div class="col text-center">
        <Camera @captured="capture" v-if="!hasFiles && visible" />
        <img ref="imgEl" style="width: 100%" />
      </div>
    </div>

    <template v-slot:buttons>
      <button class="btn btn-primary me-3" type="button"
        :disabled="!hasFiles"
        @click="uploadFiles">Naloži fotografijo</button>
    </template>
  </Modal>

  `,
  emits: ["add", "close"],
  props: {
    visible: { type: Boolean, default: false },
    type: { type: String, required: true },
    id: { type: String },
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const imgEl = ref(null)
    const hasFiles = ref(false)
    const id = toRef(props, 'id')
    const type = toRef(props, 'type')

    const capture = m => {
      hasFiles.value = true
      imgEl.value.src = m
    }

    // duplicate to Attachments
    const uploadFileAsync = async f => {
      if (type.value === "Standalone") {
        console.warn("Standalone operation.")
        emit('add', f)
        return
      }

      startLoading("uploadFileAsync")
      try {
        const form = new FormData()
        form.append('file', f)
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Attachments/${type.value}/${id.value}`, "POST", form)

        if (raw.status !== "ok" || !raw.attachment) {
          setError(raw)
        } else {
          emit('add', raw.attachment)
          hasFiles.value = false
          imgEl.value.src = ""
        }
      } catch (e) {
        setError(e)
      }
      stopLoading("uploadFileAsync")
    }

    const uploadFiles = async () => {
      if (!hasFiles.value) {
        return
      }
      const now = dayjs().format('s-m-H-DD-MM-YY')
      const f = dataURLtoFile(imgEl.value.src, `slika-${now}.jpg`)
      uploadFileAsync(f)
    }

    const dataURLtoFile = (dataurl, filename) => {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    }

    return { capture, hasFiles, imgEl, uploadFiles }
  },
}


const Upload = {
  template: `
  <Modal :visible="visible" @close="$emit('close')" title="Nalaganje datotek">
    <div class="row" v-show="type !== 'Standalone'">
      <div class="col">
        <p>Datoteka se poveže z nalogom takoj (pred klikom na "shrani")!</p>
      </div>
    </div>

    <div class="alert alert-warning" role="alert" v-show="message">
      {{ message }}
    </div>

    <div class="row">
      <div class="col">
        <input ref="fileEl" v-on:change="validateFiles" type="file" multiple /> <!-- v-on:change="handleFileUpload()" accept="image/*;capture=camera" -->
      </div>
    </div>

    <div class="row">
      <div class="col">
        {{ fileEl?.files.length }} <span class="badge bg-secondary" v-show="type !== 'Standalone'">{{ type }}</span>
      </div>
    </div>

    <template v-slot:buttons>
      <button class="btn btn-success me-3" type="button" @click="uploadFiles" :disabled="!hasFiles">Naloži</button>
    </template>
  </Modal>
  `,
  emits: ["add", "close"],
  props: {
    allowedType: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    type: { type: String, required: true },
    id: { type: String },
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const fileEl = ref(null)
    const hasFiles = ref(false)
    const id = toRef(props, 'id')
    const type = toRef(props, 'type')
    const message = ref('')

    const validateFiles = () => {
      message.value = ""
      for (let i = 0; i < fileEl.value.files.length; i++) {
        if (!fileEl.value.files.item(i).type.startsWith(props.allowedType)) {
          message.value = "Napaka pri nalaganju datoteke. Datoteka ni tipa " + props.allowedType
          return
        }
      }
      hasFiles.value = true
    }

    // duplicate to AttachFromCamera
    const uploadFileAsync = async f => {
      if (type.value === "Standalone") {
        console.warn("Standalone operation.")
        emit('add', f)
        return
      }

      startLoading("uploadFileAsync")
      try {
        const form = new FormData()
        form.append('file', f)
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Attachments/${type.value}/${id.value}`, "POST", form)

        if (raw.status !== "ok" || !raw.attachment) {
          setError(raw)
        } else {
          emit('add', raw.attachment)
        }
      } catch (e) {
        setError(e)
      }
      stopLoading("uploadFileAsync")
    }

    const uploadFiles = async () => {
      for (let i = 0; i < fileEl.value.files.length; i++) {
        const f = fileEl.value.files.item(i)
        uploadFileAsync(f)
      }
    }

    return { uploadFiles, hasFiles, fileEl, validateFiles, message }
  }
}

const Attachments = {
  template: `
  <div class="row">
    <div class="col">
      <table class="table table-sm table-hover" v-show="attachments?.length">
        <thead class="thead-light">
          <tr>
            <th>Naziv</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="a in attachments">
            <td v-if="a.hasData"><a :href="AZURE_URI + '/Attachment/' + a.id" target="_blank" class="upload-img-link">{{ a.fileName }}</a></td>
            <td v-else><a :href="'https://summer.samal.si/Media/Attachment/' + a.id" target="_blank">{{ a.fileName }} </a> [na starem sistemu !!]</td>
            <td>{{ a.contentType }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
`,
  props: {
    attachments: { type: Array, required: true }
  },
  emits: [],
  setup(props) {
    return { AZURE_URI }
  }
}

const useAttachments = () => {
  const attachments = ref([])

  const addAttachment = attachment => {
    if (attachment) {
      attachments.value.push(attachment)
    }
  }

  return {
    attachments,
    addAttachment
  }
}

export { Attachments, Upload, AttachFromCamera, useAttachments }

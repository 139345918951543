const hasRole = (worker, roles) => {
  if (!Array.isArray(roles)) {
    console.error('roles should be an array hasRole')
    return false
  }
  return roles.some(role => worker?.userRoleText?.value === role)
}

const hasRoleStartsWith = (worker, roles) => {
  if (!Array.isArray(roles)) {
    console.error('roles should be an array hasRoleStartsWith')
    return false
  }
  return roles.some(role => worker?.userRoleText?.value.startsWith(role))
}

const hasRoleEndsWith = (worker, roles) => {
  if (!worker) {
    console.error('worker is null')
    return false
  }
  if (!worker.userRoleText) {
    console.error('worker.userRoleText is null')
    return false
  }
  if (typeof worker.userRoleText.value !== 'string') {
    console.error('worker.userRoleText is not a string')
    return false
  }
  if (!Array.isArray(roles)) {
    console.error('roles should be an array hasRoleEndsWith')
    return false
  }
  return roles.some(role => worker?.userRoleText?.value.endsWith(role))
}

export { hasRole, hasRoleStartsWith, hasRoleEndsWith }

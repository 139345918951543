const ChangeHistory = {
  template: `
  <div class="row" v-if="modelValue">
    <div class="col">
      <small>Ustvaril: <DisplayEntity v-model="modelValue.userCreatedID" apiEntity="Users" />; {{ $dayjs(modelValue.dateCreated)  }}</small>
      <small v-show="modelValue.userModifiedID !== '' && modelValue.userModifiedID !== $EMPTY_GUID ">&nbsp;Spremenil: <DisplayEntity v-model="modelValue.userModifiedID" apiEntity="Users" />; {{ $dayjs(modelValue.dateModified)  }}</small>
    </div>
  </div>`,
  props: {
    modelValue: { type: Object },
  }
}

export default ChangeHistory

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "../composition-api/useMsal"
import { EMPTY_GUID } from "../utils/constants"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { useSerialNumbers } from "../composition-api/useSerialNumbers"
import { listEntitiesAsync } from "../api"
import Barcode from "./Barcode"
import { MaterialBatchList } from "./MaterialBatchList"
import { searchMaterialsAsync } from "../api"

const SelectMaterial = {
  name: "SelectMaterial",
  components: { Barcode, MaterialBatchList },
  template: `
  <Modal :visible="visible" @close="{ reset(); $emit('close') }" title="Izberi material">

    <div class="alert alert-warning" role="alert" v-show="!!info">
      {{ info }}
    </div>

    <div class="alert alert-primary" role="alert" v-show="material?.requiresSerialNumbers">
      Izbrani material zahteva serijske številke
      {{ !serialsRequired ? " (ignoriramo serijske)" : "" }}
      {{ !serialNumbersValid ? " (neveljavne serijske)" : "" }}
      {{ material?.isBatchSerial ? " (Šarža)" : "" }}
    </div>

    <div class="row mb-3" v-show="useBarcode && quantity > 0">
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="auto-add" v-model="autoAdd" />
          <label class="form-check-label" for="auto-add">Avtomatsko dodajanje</label>
        </div>
      </div>
    </div>

    <div class="row" v-if="material">
      <div class="col-lg-6">
        <div class="card text-white bg-secondary">
          <div class="card-body">
            <h5 class="card-title">{{ material.materialName }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ material.catalogNumber }} {{ material.opPisProductID }}</h6>
            <p class="card-text">{{ material.quantity }} {{ material.measurementUnit }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="!noSection">
        <div class="card text-dark bg-light">
          <div class="card-body">
            <h5 class="card-title">{{ section.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">zaporedna številka: {{ section.order }}</h6>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2" v-if="material">
      <label class="col-form-label col-sm-3">Količina **</label>
      <div class="col-sm-3">
        <div class="input-group">
          <input class="form-control"
                  placeholder="Količina"
                  id="quantity"
                  type="text"
                  pattern="[0-9.]+"
                  v-model="material.quantity" />
          <span class="input-group-text" v-show="!material?.isBatchSerial">{{ material.measurementUnit }}</span>
          <span class="input-group-text" v-show="material?.isBatchSerial">KOL.EMBALAŽE</span>
        </div>
      </div>
    </div>

    <MaterialBatchList
       v-if="material"
        :material="material"
        v-model="batchSerialNumbers"
      />

    <div class="row" v-if="!material">
      <div class="col" v-if="useBarcode">
        <Barcode @detected="searchBarcodeAsync" v-if="visible" />
      </div>
      <div class="col" v-else>
        <div class="mb-3">
          <label class="form-label">Poišči material</label>
          <Autocomplete :max="20" ref="autoCompleteEl" class="form-control" @input="getMaterialsAsync" @onSelect="selectMaterialAsync" :results="materials"></Autocomplete>
        </div>
      </div>
    </div>

    <template v-slot:buttons>
      <button class="btn btn-outline-secondary" type="button" @click="material = null" v-show="!autoAdd" :disabled="!material">Izber drug material</button>
      <button class="btn btn-success me-3"
        type="button"
        @click="addToWorkorder"
        v-show="!autoAdd"
        :disabled="!material || (!section && !noSection)  || (material.requiresSerialNumbers && serialsRequired && !serialNumbersValid) || !material.quantity">Dodaj material na nalog</button>
    </template>
  </Modal>`,
  emits: ["add", "close"],
  props: {
    quantity: { type: Number, default: 1 },
    section: { type: Object },
    noSection: { type: Boolean, default: false },
    noWarehouse: { type: Boolean, default: false },
    visible: { type: Boolean },
    useBarcode: { type: Boolean, default: false },
    serialsRequired: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const materials = ref([])
    const material = ref(null)
    const { worker } = useIsAuthorized()
    const autoCompleteEl = ref(null)
    const info = ref(null)
    const autoAdd = ref(false)

    const { batchSerialNumbers, numberOfSerialNumbers, serialNumbersValid, initializeWatchers } =
      useSerialNumbers(material)
    initializeWatchers()

    const loadSerialsAsync = async (serial, materialId) => {
      const url = `MaterialBatch/${materialId}`
      const batchSerials = await listEntitiesAsync(instance, url, {})
      const matchingBatchSerial = batchSerials.find((bs) => bs.serialNumber === serial)
      if (matchingBatchSerial) {
        return matchingBatchSerial.stock
      }
      return null
    }

    const selectMaterialAsync = async (m, serialNumbers = "", quantityFromBarcode) => {
      let quantity = props.quantity * (m.quantity || 1)
      if (quantityFromBarcode && serialNumbers) {
        quantity = 1
        const stock = await loadSerialsAsync(serialNumbers, m.id)
        if (stock !== null && stock > 0) {
          quantityFromBarcode = parseFloat((stock * 1.2).toFixed(2))
        }
        serialNumbers += `;${quantityFromBarcode}`
      }

      material.value = {
        materialID: m.id,
        materialName: m.name,
        catalogNumber: m.catalogNumber,
        opPisProductID: m.opPisProductID,
        quantity,
        measurementUnit: m.measurementUnit,
        requiresSerialNumbers: m.requiresSerialNumbers,
        isBatchSerial: m.isBatchSerial,
        serialNumberTypeRaw: m.serialNumberTypeRaw,
        serialNumbers,
      }
      if (!props.noSection) {
        material.value.sectionOrder = props.section.order
        material.value.sectionName = props.section.name
      }
      if (!props.noWarehouse) {
        material.value.warehouseID = worker.activeWarehouse.value.id
      }
    }

    const searchBarcodeAsync = async (bc) => {
      const temp = await searchMaterialsAsync(instance, "acCode", bc.ident)
      if (temp.length > 0) {
        await selectMaterialAsync(temp[0], bc.serialNumbers?.trim(), bc.quantity)
        if (!autoAdd.value) {
          return
        }
        if (bc.serialNumbers) {
          setTimeout(() => addToWorkorder(), 1000)
        } else if (!temp[0].requiresSerialNumbers || !props.serialsRequired) {
          setTimeout(() => addToWorkorder(), 1000)
        }
      } else {
        info.value =
          "Ni identa: " +
          bc.ident +
          " (količina:" +
          bc.quantity +
          ") serijske:" +
          (bc.serialNumbers ? " [" + bc.serialNumbers + "]" : "")
      }
    }

    const getMaterialsAsync = async (q) => {
      materials.value = await searchMaterialsAsync(instance, "q", q)
      materials.value = materials.value.map((m) => {
        if (m.isStockUpToDate) {
          m.name += " [" + m.freeStockCe + " " + m.measurementUnit + "@CE]"
        } else {
          m.name += " [zaloga ni ažurna]"
        }
        return m
      })
    }

    const reset = () => {
      info.value = ""
      material.value = null
      materials.value = []
      autoCompleteEl.value?.setText("")
    }

    const addToWorkorder = () => {
      if (material.value.materialID && material.value.materialID != EMPTY_GUID) {
        emit("add", [{ ...material.value }])
      }
      reset()
    }

    return {
      info,
      reset,
      autoAdd,
      material,
      materials,
      getMaterialsAsync,
      selectMaterialAsync,
      autoCompleteEl,
      addToWorkorder,
      searchBarcodeAsync,
      serialNumbersValid,
      numberOfSerialNumbers,
      batchSerialNumbers,
    }
  },
}

export default SelectMaterial

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onUnmounted } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess, setWarning } from "../components/Notification"
import { getWorkerTimesAsync } from "../api"
import { DATETIME_FOR_LOCAL_INPUT, EMPTY_GUID  } from "../utils/constants"
import { useEnums } from '../composition-api/useEnums'
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { WorkersWithWorkOrdersCard, assignOrderAsync } from "../components/WorkersWithWorkOrdersCard"
import FacilitiesCard from "../components/FacilitiesCard"
import ServiceOrdersCard from "../components/ServiceOrdersCard"
import InternalOrdersCard from "../components/InternalOrdersCard"
import ServiceOrdersWithCompletedMovementsCard from "../components/ServiceOrdersWithCompletedMovementsCard"
import WorkersCard from "../components/WorkersCard"
import Notifications from "../components/Notifications"

export default HomeLeaderService = {
  name: 'HomeLeaderService',
  components: { ServiceOrdersCard, WorkersWithWorkOrdersCard, FacilitiesCard, InternalOrdersCard, ServiceOrdersWithCompletedMovementsCard, WorkersCard, Notifications },
  template: `
  <div>
    <div class="row">
      <div class="col-md-3">
        <FacilitiesCard class="card mb-3" />
      </div>
      <div class="col-md-6">
        <Notifications />
        <InternalOrdersCard class="card mb-3" title="Interni nalogi" subTitle="Accepted, zame" :ownerID="worker.id.value" statuses="Accepted" />
      </div>
      <div class="col-md-3">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bližnjice</h5>
            <ul>
              <li>
                <button class="btn btn-link" type="button" @click="$router.push({ name: 'ServiceOrdersMap' })">Zemljevid 🚩</button>
              </li>
              <li>
                <button class="btn btn-link" type="button" @click="$router.push({ name: 'InternalOrder', params: { workOrderId: 'new' } })">Nov interni nalog</button>
              </li>
              <li>
                <button class="btn btn-link" type="button" @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: 'new' } })">Nov servisni nalog</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-xl-2">
        <ServiceOrdersCard class="card mb-3" title="Servisni nalogi" subTitle="Triage" statuses="Triage" :itemsPerPage=40 @assigned="orderAssigned" />
      </div>
      <div class="col-sm-6 col-xl-3">
        <ServiceOrdersCard class="card mb-3" title="Servisni nalogi" subTitle="Active; brez +" statuses="Active" :isSpecial="false" :itemsPerPage=50 @assigned="orderAssigned" />
      </div>
      <div class="col-sm-6 col-xl-3">
        <ServiceOrdersWithCompletedMovementsCard class="card mb-3" displayType="card" @assigned="orderAssigned" />
      </div>
      <div class="col-sm-6 col-xl-4">
        <WorkersWithWorkOrdersCard class="card mb-3" ref="workersCardEl" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <WorkersCard class="card mb-3"  />
      </div>
    </div>

  </div>
  `,
  setup(props, { emit }) {
    const { worker } = useIsAuthorized()
    const workersCardEl = ref(null)

    onMounted(async () => {
      const appEl = document.getElementById('app')
      if (appEl) {
        appEl.classList.remove('container')
        appEl.classList.add('container-fluid')
      }
    })

    onUnmounted(() => {
      const appEl = document.getElementById('app')
      if (appEl) {
        appEl.classList.remove('container-fluid')
        appEl.classList.add('container')
      }
    })

    const orderAssigned = () => {
      workersCardEl.value.loadDataAsync()
    }


    return {
      worker,
      workersCardEl,
      orderAssigned
    }
  }
}

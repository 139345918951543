import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { listEntitiesAsync } from 'scripts/api'
import { useMsal } from 'scripts/composition-api/useMsal'

const MAX_DISPLAYED_FACILITIES = 10

export default FacilitiesCard = {
  name: 'FacilitiesCard',
  template: `
  <div class="card-body">
      <h5 class="card-title">2 ali več obiska v 14 dneh <small class="text-muted">(nočemo večkrat hodit na isti objekt!)</small></h5>
      <table class="table table-sm table-hover">
          <thead class="thead-light">
            <tr>
              <th>Objekt</th>
              <th>Št.zaključenih</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="f in showAllFacities ?  facilities : facilities.slice(0, MAX_DISPLAYED_FACILITIES)">
              <td>
                <router-link :to="{ name: 'FacilityEdit', params: { facilityId: f.ID } }" target="blank">{{ f.Name }}</router-link>
              </td>
              <td>{{ f.Completed14 }}</td>
            </tr>
          </tbody>
      </table>
      <a class="text-decoration-underline" role="button" v-show="facilities.length > MAX_DISPLAYED_FACILITIES" @click="showAllFacities = !showAllFacities"> {{ showAllFacities ? 'Prikaži manj' : 'Prikaži vseh ' + facilities.length }}</a>
  </div>
  `,
  setup() {
    const { instance } = useMsal()
    const facilities = ref([])
    const showAllFacities = ref(false)

    onMounted(async () => {
      const temp = await listEntitiesAsync(instance, "Analytics/FacilitiesWithCompledServiceOrders")
      facilities.value = temp.filter(f => f.Completed14 > 1)
    })

    return {
      showAllFacities,
      facilities,
      MAX_DISPLAYED_FACILITIES
    }
  }
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "../composition-api/useMsal"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess, setWarning } from "../components/Notification"
import { getWorkerTimesAsync } from "../api"
import { DATETIME_FOR_LOCAL_INPUT, EMPTY_GUID } from "../utils/constants"
import { useEnums } from "../composition-api/useEnums"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useWarehouseTransfers } from "../composition-api/useWarehouseTransfers"
import WorkersCard from "../components/WorkersCard"
import Notifications from "../components/Notifications"
import WarehouseTransfersCard from "../components/WarehouseTransfersCard"
import InternalOrdersCard from "../components/InternalOrdersCard"
import ProductionOrdersCard from "../components/ProductionOrdersCard"
import ServiceOrdersCard from "../components/ServiceOrdersCard"

export default HomeWorkerProduction = {
  name: "HomeWorkerProduction",
  components: {
    WorkersCard,
    Notifications,
    WarehouseTransfersCard,
    InternalOrdersCard,
    ProductionOrdersCard,
    ServiceOrdersCard,
  },
  template: `
  <div>
    <div class="col">
      <div class="row">
        <div class="col-md-4">
          <Notifications />
          <InternalOrdersCard class="card mb-3" title="Interni nalogi"
            subTitle="Accepted, zame"
            :ownerID="worker.id.value"
            statuses="Accepted" />
        </div>
        <div class="col-md-4">
          <WarehouseTransfersCard class="card mb-3"
            :toWarehouseID="worker.activeWarehouse.value.id"
            statuses="New,MovementOrder"
            subTitle="Zame, novi"  />
        </div>
        <div class="col-md-4">
          <ProductionOrdersCard class="card mb-3"
            :ownerID="worker.id.value" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <ServiceOrdersCard class="card mb-3" title="Servisni nalogi"
            subTitle="Triage,Accepted,InProgress + zame"
            statuses="Triage,Accepted,InProgress"
            :ownerID="worker.id.value" />
        </div>
      </div>
    </div>
  </div>
  `,
  setup() {
    const { worker } = useIsAuthorized()

    return {
      worker,
    }
  },
}

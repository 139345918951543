import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, onUnmounted, inject } from "vue"
import { useMsal } from "../composition-api/useMsal"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess, clearNotifications } from "../components/Notification"
import { saveMaterialsAsync, saveProjectAsync, getProjectAsync } from "../api"
import { EMPTY_GUID } from "scripts/utils/constants"
import { EntityState } from "../components/SelectEntity"
import { useAttachments } from "scripts/components/Attachments"
import SelectMaterial from "../components/SelectMaterial"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { TodoActions } from "../components/TodoActions"
import BomTemplates from "../components/BomTemplates"
import { hasRole } from "../utils/roles"
import { ProjectMaterials } from "../components/ProjectMaterials"

const ProjectEdit = {
  name: "ProjectEdit",
  components: { SelectMaterial, TodoActions, BomTemplates, ProjectMaterials },
  template: `

  <div class="row">
    <div class="col-sm-3"><h1></h1></div>
    <div class="col-sm-6 d-none d-lg-block"><h3>Projekt</h3></div>
    <div class="col-sm-3"><h2><OrderBadge :status="entity.statusDisplay" /></h2></div>
  </div>

  <div class="row mb-3">
    <div class="row col-md-8">
      <label class="col-form-label col-sm-4">Polno ime *</label>
      <div class="col-sm-8">
        <input class="form-control" type="text" v-model="entity.fullName" :disabled="!entity.canChange" required />
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="row col-md-8">
      <label class="col-form-label col-sm-4">Opis</label>
      <div class="col-sm-8">
        <textarea class="form-control" rows="4" v-model="entity.description" :disabled="!entity.canChange" />
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="row col-md-8">
      <label class="col-form-label col-sm-4">Dodatna dela</label>
      <div class="col-sm-8">
        <textarea class="form-control" v-model="entity.additionalWork" :disabled="!entity.canChange" rows="5" />
      </div>
    </div>
  </div>
  <div class="row col-md-8">
    <SelectCustomerEntity
      required
      :customerID="entity.customerID"
      :facilityID="entity.facilityID"
      :canChange="entity.canChange"
      @change="e => { entity.customerID = e.customerID;  entity.facilityID = e.facilityID }"
    />
  </div>

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="entity && !isNew">
    <div class="row">
      <h4 class="col-6">Naloge (odprti servisni in interni nalogi)</h4>
      <div class="col-6 text-end" v-show="entity.canChange">
        <button type="button" class="btn btn-sm btn-primary me-3 mb-2" @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: 'new', facilityID: entity.facilityID, customerID: entity.customerID, projectID: entity.id } })" v-show="!isNew">Nov servisni nalog</button>
        <button type="button" class="btn btn-warning btn-sm me-2 mb-2" @click="selectedWorkOrderId = 'new'">Dodaj TODO</button>
      </div>
    </div>
    <div class="row" v-if="orders?.length">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-stripped table-sm table-responsive">
            <thead class="thead-light">
              <tr>
                <th scope="col">Št.&nbsp;naloga</th>
                <th scope="col">Status</th>
                <th scope="col">Zadeva</th>
                <th scope="col">Delavec</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="order in orders" role="button">
                  <td class="bg-white" @click="order.orderType === 'Internal' ? orderAction(order) : selectOrder(order, true)">{{ order.orderNumber }}<OrderBadge cssClass="d-block d-lg-none" :status="order.status" :dateCompleted="order.dateCompleted" /></td>
                  <td class="bg-white" @click="order.orderType === 'Internal' ? orderAction(order) : selectOrder(order, true)"><OrderBadge :status="order.status" :dateCompleted="order.dateCompleted" cssClass="d-block" /></td>
                  <td @click="order.orderType === 'Internal' ? orderAction(order) : selectOrder(order)">{{ order.subject }}</td>
                  <td @click="order.orderType === 'Internal' ? orderAction(order) : selectOrder(order)">{{ order.owner }}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <TodoActions
    :projectId="entity.id"
    :workOrderId="selectedWorkOrderId"
    @close="e => { if (e === true) { loadProjectAsync(); }; selectedWorkOrderId = null }"
  />

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="entity && !isNew  && hasRole(worker, ['LeaderProjects', 'Administrator'])">
    <ProjectMaterials
      :canChange="entity.canChange"
      work-order-type="Project"
      :workOrderId="entity.id"
      />
  </div>

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!isNew && hasRole(worker, ['LeaderProjects', 'Administrator'])">
    <div class="row">
      <h4 class="col-2">Priloge</h4>
      <div class="col-10 text-end">
        <button class="btn btn-warning btn-sm ms-1 me-2" @click="showUpload = true" v-show="entity.canChange">Naloži datoteko</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <Attachments :attachments="attachments" />
      </div>
    </div>
  </div>

  <Upload :visible="!!showUpload"
    @close="showUpload = false"
    @add="e => { addAttachment(e) }"
    type="Project"
    :id="entity.id" />

  <RequiredWarning v-show="!isValid && entity.canChange" />

  <div class="row mb-5">
    <div class="col text-end">
      <button v-if="shouldReturnToProductionOrder" class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'ProductionOrder', params: { workOrderId: $route.params.productionOrderId } })">Prekliči</button>
      <button v-else class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Projects' })">Prekliči</button>
      <button class="btn btn-outline-info     me-3" @click="saveAndChangeStatusAsync('Canceled')"   v-show="!isNew && entity.canCancel"  type="button">Shrani in prekliči</button>
      <button class="btn btn-outline-info     me-3" @click="saveAndChangeStatusAsync('Ordered')"   v-show="!isNew && entity.canOrdered"  type="button">Shrani in "naročeno"</button>
      <button class="btn btn-outline-info     me-3" @click="saveAndChangeStatusAsync('Active')"    v-show="!isNew && entity.canActivate" type="button">Shrani in aktiviraj</button>
      <button class="btn btn-outline-success  me-3" @click="saveAndChangeStatusAsync('Finalized')" v-show="!isNew && entity.canFinalize" type="button">Shrani in "končano"</button>
      <button class="btn btn-outline-info     me-3" @click="saveAndChangeStatusAsync('Reviewed')"  v-show="!isNew && entity.canReviewed" type="button">Shrani in "pregled"</button>
      <button class="btn btn-outline-danger   me-3" @click="saveAndChangeStatusAsync('AccountingDone')"    v-show="!isNew && entity.canAccountingDone"   type="button">Shrani in "zaračunano"</button>
      <button class="btn btn-success btn-lg me-3" :disabled="!isValid" @click="saveAsync" v-show="entity.canChange" type="button">
        {{ shouldReturnToProductionOrder ? "Shrani in se vrni na proizvodni nalog" : "Shrani" }}
      </button>
    </div>
  </div>
  `,
  props: {},
  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { attachments, addAttachment } = useAttachments()
    const { worker } = useIsAuthorized()

    const showUpload = ref(false)
    const selectedWorkOrderId = ref(null)

    const entity = ref({})
    const isNew = computed(() => route.params.projectId === "new")

    const shouldReturnToProductionOrder = computed(() => route.params.productionOrderId?.length > 2)

    const { DEFAULT_FILTERS, filters, resetPaging, orders, selectOrder } = useWorkOrders("all")

    const saveAndChangeStatusAsync = async (status) => {
      entity.value.status = status
      await saveAsync()
    }

    const saveAsync = async () => {
      await saveProjectAsync(instance, entity.value, isNew.value)
      EntityState.clearAll()
      if (shouldReturnToProductionOrder.value) {
        router.push({
          name: `ProductionOrder`,
          params: { workOrderId: route.params.productionOrderId, keepMessages: true },
        })
      } else if (isNew.value) {
        router.push({ name: `Project`, params: { projectId: entity.value.id, keepMessages: true } })
      } else {
        await loadProjectAsync()
      }
    }

    const isValid = computed(() => entity.value.fullName?.length > 3)

    const loadProjectAsync = async () => {
      entity.value = {}
      if (!route.params.projectId) {
        return
      }
      entity.value = await getProjectAsync(instance, route.params.projectId)
      if (entity.value) {
        attachments.value = entity.value.attachments
        const f = DEFAULT_FILTERS()
        f.projectID = entity.value.id
        f.status = "Active,Accepted,InProgress,Completed,Finalized"
        filters.value = f
        resetPaging()
      }
      if (!entity.value) {
        setError("Ne obstaja.")
      }
    }

    const orderAction = (order) => {
      if (order.orderType === "Internal") {
        selectedWorkOrderId.value = order.id
      }
    }

    watch(
      () => route.params.projectId,
      async () => {
        await loadProjectAsync()
      }
    )

    onMounted(async () => {
      await loadProjectAsync()

      const appEl = document.getElementById("app")
      if (appEl) {
        appEl.classList.remove("container")
        appEl.classList.add("container-fluid")
      }
    })

    onUnmounted(() => {
      const appEl = document.getElementById("app")
      if (appEl) {
        appEl.classList.remove("container-fluid")
        appEl.classList.add("container")
      }
    })

    return {
      worker,
      hasRole,
      isNew,
      isValid,
      entity,
      saveAsync,
      saveAndChangeStatusAsync,
      shouldReturnToProductionOrder,
      attachments,
      addAttachment,
      showUpload,
      orders,
      selectedWorkOrderId,
      loadProjectAsync,
      orderAction,
      selectOrder,
    }
  },
}

export default ProjectEdit

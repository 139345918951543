import { EMPTY_GUID, DATETIME_FORMAT, DATETIME_FOR_LOCAL_INPUT, MAX_MINUTES_TIME_ENTRY } from '../utils/constants'

const sortTimeEntries = entries => {
  if (!Array.isArray(entries)) {
    throw new Error("not an array")
  }
  if(entries.length > 0 &&
    (!Object.getOwnPropertyDescriptor(entries[0], "startDateTime") ||
    !Object.getOwnPropertyDescriptor(entries[0], "endDateTime"))) {
      throw new Error("missing properties.")
  }

  return entries.sort((a,b) => {
    const fa = dayjs(a.startDate)
    const fb = dayjs(b.startDate)
    if (fa.isBefore(fb)) return -1
    if (fa.isAfter(fb)) return 1
    return 0
  })
}

const ensureTimeEntryExtensions = timeEntry => _ensureTimeExtensions(timeEntry, false)

const ensureTransportEntryExtensions = transportEntry => _ensureTimeExtensions(transportEntry, true)

const _ensureTimeExtensions = (timeEntry, isTransportEntry = false) => {
  if (!!Object.getOwnPropertyDescriptor(timeEntry, "isValid")) {
    return
  }

  Object.defineProperty(timeEntry, 'startDateTimeObject', {
    get: function() {
      const d = dayjs.utc(this.startDateTime)
      return (!d.isValid() || d.year() < 2000) ? undefined : d
    }
  })
  Object.defineProperty(timeEntry, 'endDateTimeObject', {
    get: function() {
      const d = dayjs.utc(this.endDateTime)
      return (!d.isValid() || d.year() < 2000) ? undefined : d
    }
  })
  Object.defineProperty(timeEntry, 'startDateTimeForInput', {
    get: function() {
      return this.startDateTimeObject?.local().format(DATETIME_FOR_LOCAL_INPUT)
    },
    set: function(v) {
      this.startDateTime = dayjs(v).utc().format()
    }
  })
  Object.defineProperty(timeEntry, 'endDateTimeForInput', {
    get: function() {
      return this.endDateTimeObject?.local().format(DATETIME_FOR_LOCAL_INPUT)
    },
    set: function(v) {
      this.endDateTime = dayjs(v).utc().format()
    }
  })
  Object.defineProperty(timeEntry, 'endTimeForInput', {
    get: function() {
      return this.endDateTimeObject?.local().format("HH:mm")
    },
    set: function(value) {
      if(!value.match(/^\d{2,}:\d{2,}$/)){
        console.warn(`Invalid time format: ${value}`)
      }
      let t = dayjs().local()
        .year(this.startDateTimeObject.year())
        .month(this.startDateTimeObject.month())
        .date(this.startDateTimeObject.date())
        .hour(value.split(':')[0])
        .minute(value.split(':')[1])
      this.endDateTime = t.utc().format()
    }
  })
  Object.defineProperty(timeEntry, 'differenceMin', {
    get: function() {
      if (!this.startDateTimeObject || !this.startDateTimeObject.isValid() ||
          !this.endDateTimeObject || !this.endDateTimeObject.isValid()) {
        return -1
      }
      return this.endDateTimeObject.diff(this.startDateTimeObject, 'minute')
    }
  })
  Object.defineProperty(timeEntry, 'difference', {
    get: function() {
      if (!this.endDateTimeObject) {
        return ``
      }
      if (this.differenceMin < 0) {
        return `Konec pred začetkom`
      }
      if (!isTransportEntry && this.differenceMin >= MAX_MINUTES_TIME_ENTRY) {
        return `Več kot delovni dan`
      }

      let mins = this.differenceMin
      const hours = parseInt(mins / 60);
      mins = dayjs().minute(mins).$m
      return `${hours}h ${mins}m`
    }
  })

  Object.defineProperty(timeEntry, 'isComplete', {
    get: function() {
      if (isTransportEntry) {
        return (this.differenceMin > 0)
          && !!this.workerId && this.workerId !== EMPTY_GUID
          && !!this.vehicleId && this.vehicleId !== EMPTY_GUID
          && ((this.relation?.length > 0 && this.distanceKm > 0) || this.hasStartEndLocation)
      } else {
        return (this.differenceMin > 0)
          && !!this.workerId && this.workerId !== EMPTY_GUID
      }
    }
  })

  Object.defineProperty(timeEntry, 'isValid', {
    get: function() {
      if (isTransportEntry) {
        return !!this.startDateTimeObject
          && this.vehicleId !== EMPTY_GUID
          && ((this.relation?.length > 0 && this.distanceKm > 0) || this.hasStartLocation)
          && this.differenceMin > -2
      } else {
        return (this.differenceMin > 0 && this.differenceMin < MAX_MINUTES_TIME_ENTRY) || !this.endDateTimeObject
      }
    }
  })
}

export { ensureTimeEntryExtensions, ensureTransportEntryExtensions, sortTimeEntries }

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { listEntitiesAsync } from 'scripts/api'
import { useMsal } from 'scripts/composition-api/useMsal'


export default WorkersCard = {
  name: 'WorkersCard',
  template: `
  <div class="card-body">
      <h5 class="card-title" v-show="title">{{ title }} <small class="text-muted" v-show="subTitle">{{ subTitle }}</small></h5>
      <table class="table table-sm table-hover">
          <thead class="thead-light">
            <tr>
              <th>Delavec</th>
              <th>Sprejet</th>
              <th>👷</th>
              <th>Mesec</th>
              <th>Dan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="w in workers" :class="{ 'bg-light': w.IsAbsent }">
              <td>
                <router-link :to="{ name: 'WorkerEdit', params: { workerId: w.ID } }" target="blank">{{ w.FirstName }} {{ w.LastName }}</router-link>
                &nbsp;
                <span class="badge rounded-pill bg-info" v-show="w.IsAbsent">Odsoten</span>
                <span class="badge rounded-pill bg-danger" v-show="w.TransportOfficeHome > 0">Pot domov</span>
                <span class="badge rounded-pill bg-warning" v-show="w.IsWorkOnWeeked">Vikend delo</span>
              </td>
              <td>{{ w.Accepted }}</td>
              <td>{{ w.InProgress }}</td>
              <td>{{ w.LoggedServiceHoursCurrentMonth }} / {{ (w.LoggedHoursCurrentMonth + w.LoggedTravelHoursCurrentMonth) }}</td>
              <td>{{ Math.floor(w.LoggedServiceMinutes / 60) }}:{{ (w.LoggedServiceMinutes % 60).toString().padStart(2, '0') }}
                /
                <span class="badge rounded-pill" :class="{ 'bg-danger': (w.LoggedMinutes + w.LoggedTravelMinutes) < 420, 'bg-success': (w.LoggedMinutes + w.LoggedTravelMinutes) >= 420 }">
                  {{ Math.floor((w.LoggedMinutes + w.LoggedTravelMinutes) / 60) }}:{{ ((w.LoggedMinutes + w.LoggedTravelMinutes) % 60).toString().padStart(2, '0') }}
                </span>
              </td>
            </tr>
          </tbody>
      </table>
  </div>
  `,
  props: {
    title: {
      type: String
    },
    subTitle: {
      type: String,
    }
  },
  setup() {
    const { instance } = useMsal()
    const workers = ref([])

    onMounted(async () => {
      const fetchedWorkers = await listEntitiesAsync(instance, "Analytics/Workers")
      fetchedWorkers.forEach(row => {
          let LoggedMinutes = 0
          let LoggedServiceMinutes = 0
          let LoggedTravelMinutes = 0
          let IsWorkOnWeeked = false
          if (row.DayOfTheWeek === 1) {
            if (row.LoggedMinutesYesterday > 0) {
              LoggedMinutes = row.LoggedMinutesYesterday
              LoggedTravelMinutes = row.LoggedTravelMinutesYesterday
              LoggedServiceMinutes = row.LoggedServiceMinutesYesterday
              IsWorkOnWeeked = true
            } else if (row.LoggedMinutesTwoDaysAgo > 0) {
              LoggedMinutes = row.LoggedMinutesTwoDaysAgo
              LoggedTravelMinutes = row.LoggedTravelMinutesTwoDaysAgo
              LoggedServiceMinutes = row.LoggedServiceMinutesTwoDaysAgo
              IsWorkOnWeeked = true
            } else {
              LoggedMinutes = row.LoggedMinutesThreeDaysAgo
              LoggedTravelMinutes = row.LoggedTravelMinutesThreeDaysAgo
              LoggedServiceMinutes = row.LoggedServiceMinutesThreeDaysAgo
            }
          } else {
            LoggedMinutes = row.LoggedMinutesYesterday
            LoggedServiceMinutes = row.LoggedServiceMinutesYesterday
          }

          workers.value.push({
            ID: row.ID,
            FirstName: row.FirstName,
            LastName: row.LastName,
            TransportOfficeHome: row.TransportOfficeHome,
            IsAbsent: row.IsAbsent,
            Accepted: row.Accepted,
            InProgress: row.InProgress,
            LoggedMinutes,
            LoggedTravelMinutes,
            LoggedServiceMinutes,
            IsWorkOnWeeked,
            LoggedHoursCurrentMonth: row.LoggedHoursCurrentMonth,
            LoggedServiceHoursCurrentMonth: row.LoggedServiceHoursCurrentMonth,
            LoggedTravelHoursCurrentMonth: row.LoggedTravelHoursCurrentMonth
          })
        })
    })

    return {
      workers
    }
  }
}

/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading } from "../components/Notification"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useWorkOrder } from '../composition-api/useWorkOrder'
import { useEnums } from '../composition-api/useEnums'
import { GoogleMap, Marker } from "../lib/vue3-google-maps.es.js" // 0.15.0 https://github.com/inocan-group/vue3-google-map
import WorkOrdersList from '../components/WorkOrdersList'

const ServiceOrdersMap = {
  name: 'ServiceOrdersMap',
  components: { GoogleMap, Marker, WorkOrdersList },
  template: `
    <div class="row mb-3">
      <div class="col"><!-- TODO: duplicate -->
        <div class="row">
          <div class="col-sm-3">
            Periodika:
          </div>
          <div class="col">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="period1" v-model="filters.isLeakageTest">
              <label class="form-check-label" for="period1">Tesnost</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="period2" v-model="filters.isService">
              <label class="form-check-label" for="period2">Servis</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="period3" v-model="filters.isCondenserCleaning">
              <label class="form-check-label" for="period3">Kondenzator</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="period3" v-model="filters.isSpecial">
              <label class="form-check-label" for="period3">Ima posebnosti</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <p>Nalogov: {{ orders.length }}. Nalogov brez koordinat: {{ orders.length - markers.length }}. Filter: <strong>{{ filters.status }}</strong> <strong>{{ filters.isSpecial === "true" ? "s +" : "brez +" }}</strong></p>
        <GoogleMap :api-key="$window.GOOGLE_API_KEY" style="width: 100%; height: calc(100vh - 150px); min-height: 500px;" :center=" { lat: 46.0524009, lng: 14.3663264 }" :zoom="9" ref="mapEl">
          <Marker v-for="marker in markers" :options="marker" @click="showInfo(marker)" />
        </GoogleMap>
      </div>
    </div>
    <Modal :title="'Delovni nalogi'" :visible="!!selectedMarker" @close="selectedMarker=null">
      <div class="row">
        <div class="col">
          <WorkOrdersList
            :workOrders="selectedMarker?.orders"
            @takeOwnership="takeOwnershipAsync"
            @assignTo="assignToAsync"
            />
        </div>
      </div>
    </Modal>
    `,
  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const { filters, orders, getOrdersAsync, resetPaging, paging } = useWorkOrders("Service")
    const { changeOwnerAsync } = useWorkOrder("Service")
    const { statuses, loadEnumsAsync } = useEnums()
    const mapEl = ref(null)
    const { worker } = useIsAuthorized()

    const isAuthenticated = useIsAuthenticated()
    const selectedMarker = ref(null)

    const showInfo = (marker) => selectedMarker.value = marker

    const takeOwnershipAsync = async wo => {
      selectedMarker.value = null
      await changeOwnerAsync(wo.id, worker.id.value)
      await getOrdersAsync()
    }

    const assignToAsync = async (req) => {
      if (!req.order || !req.selectedWorker) {
        console.warn("assignToAsync: invalid request", req)
        return
      }
      selectedMarker.value = null
      console.log(req)
      if (await changeOwnerAsync(req.order.id, req.selectedWorker)) {
        await getOrdersAsync()
      }
    }

    const markers = computed(() => orders.value
      .filter(order => order.lat && order.lng)
      .map(order => {
        return {
          position: { lat: order.lat, lng: order.lng },
          label: "1",
          title: order.facilityName,
          orders: [order],
          isSpecial: order.isSpecial,
          isService: order.isService,
          isCondenserCleaning: order.isCondenserCleaning,
          isLeakageTest: order.isLeakageTest
        }
      })
      .reduce((acc, curr) => {
        const existing = acc.find(marker => marker.position.lat === curr.position.lat && marker.position.lng === curr.position.lng)
        if (existing) {
          existing.orders.push(curr.orders[0])
          existing.label = existing.orders.length.toString()
        } else {
          acc.push(curr)
        }
        return acc
      }, [])
    )

    watch(
      () => ({ ...filters.value }),
      () => resetPaging() // will also fire getOrdersAsync()
    )

    onMounted(async () => {
      startLoading('Orders')
      try {
        await loadEnumsAsync()
        paging.value.itemsPerPage = 1000
        filters.value.status = "Active"
        if (route.params?.statuses) {
          filters.value.status = route.params.statuses
        }
        await getOrdersAsync()

      } catch (e) {
        setError(e)
      }
      stopLoading('Orders')
    })

    return {
      markers,
      isAuthenticated,
      showInfo,
      orders,
      statuses,
      filters,
      mapEl
      , selectedMarker,
      takeOwnershipAsync,
      assignToAsync
    }
  },
}

export default ServiceOrdersMap

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listEntitiesAsync } from '../api'

export default BomTemplates = {
  name: "BomTemplates",
  template: `
  <div class="row">
    <div class="col">
      <button class="btn btn-primary" type="button" @click="$router.push({ name: 'BomTemplateEdit', params: { id: 'new' } })">Nova predloga</button>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Naziv</th>
              <th scope="col">Komentar</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" @click="select(w)" v-for="w in entities">
              <td>{{ w.name }}</td>
              <td>{{ w.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  `,
  setup (props, { emit }) {
    const router = VueRouter.useRouter()
    const { instance } = useMsal()

    const entities = ref([])

    const select = entity => router.push({ name: 'BomTemplateEdit', params: { id: entity.id } })

    onMounted(async () => {
      entities.value = await listEntitiesAsync(instance, "BomTemplates", "?variant=Full")
    })

    return { entities, select }
  }

}

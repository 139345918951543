import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { setError, startLoading, stopLoading, setSuccess } from "./Notification"
import { useWorkOrder } from '../composition-api/useWorkOrder'

const TodoActions = {
  name: 'TodoActions',
  template: `
  <Modal :title="'Akcije za TODO'" :visible="!!workOrder" @close="$emit('close')">
    <div class="row">
      <div class="col" v-if="workOrder">

        <div class="row" v-show="!workOrder?.isNew">
          <div class="col-sm-4">{{ workOrder.orderNumber }}</div>
          <div class="col-sm-4"><h5>Interni nalog / {{ workOrder.typeText }}</h5></div>
          <div class="col-sm-4"><h4><OrderBadge :status="workOrder.statusText" /></h4></div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Zadeva</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="workOrder.subject" :disabled="!workOrder.canChange" />
          </div>
        </div>

        <div class="row mb-3" v-if="workOrder.typeText?.includes('ToDo')">
          <label class="col-form-label col-sm-4">Projekt</label>
          <div class="col-sm-8">
            <SelectEntity apiEntity="Projects/Active" v-model="workOrder.projectID" :canChange="false" />
          </div>
        </div>

        <div class="row mb-3" v-if="workOrder.typeText?.includes('ToDo')">
          <label class="col-form-label col-sm-4">Delavec</label>
          <div class="col-sm-8">
            <SelectEntity apiEntity="Workers" v-model="workOrder.ownerID" />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Opombe</label>
          <div class="col-sm-8">
            <textarea class="form-control" v-model="workOrder.comments" :disabled="!workOrder.canChange"></textarea>
          </div>
        </div>

      </div>
    </div>
    <template v-slot:buttons>
      <button class="btn btn-outline-success btn-lg me-3" type="button" v-show="!workOrder.isNew && workOrder.canComplete"
        @click="markCompletedLocalAsync">Označi kot opravljeno</button>
      <button class="btn btn-success me-3" type="button" :disabled="!workOrder || !workOrder.canChange" @click="saveAsync">Shrani</button>
    </template>
  </Modal>
  `,
  emits: ['close'],
  props: {
    canChange: Boolean,
    workOrderId: { type: String },
    projectId: { type: String }
  },
  setup(props, { emit }) {
    const {
      loadWorkOrderAsync,
      markCompletedAsync,
      workOrder,
      saveWorkOrderAsync
    } = useWorkOrder("Internal", true, false)

    watch(() => props.workOrderId, async (curr, next) => {
      await loadWorkOrderAsync(props.workOrderId)
      if (!workOrder.value) {
        return
      }
      if (props.workOrderId === "new") {
        workOrder.value.typeText = 'ToDo'
        workOrder.value.projectID = props.projectId
      } else if (workOrder.value.projectID !== props.projectId) {
        setError("TODO ne pripada temu projektu.")
      }
    })

    const markCompletedLocalAsync = async () => {
      let result = await markCompletedAsync(true /* skipRoute */)
      if (result) {
        emit("close", true /* reload */)
        workOrder.value = null
      } else {
        setError("Ni shranjeno!")
      }
    }

    const saveAsync = async () => {
      let result = await saveWorkOrderAsync()
      if (result) {
        emit("close", true /* reload */)
        workOrder.value = null
      } else {
        setError("Ni shranjeno!")
      }
    }

    return { workOrder, saveAsync, markCompletedLocalAsync }
  }
}

export { TodoActions }


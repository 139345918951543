import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { EMPTY_GUID, DATETIME_FORMAT, MAX_MINUTES_TIME_ENTRY } from '../utils/constants'
import { useMsal } from '../composition-api/useMsal'
import { setError, startLoading, stopLoading, setSuccess } from "./Notification"
import { ensureTransportEntryExtensions } from '../utils/timeEntryExtensions'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { saveWorkOrderTransportAsync, insertNewTransportEntryAsync  } from '../api'

const WorkOrderTransportsActions = {
  template: `
  <Modal :title="'Akcije za pot'" :visible="!!localEntry" @close="$emit('close')">
    <div class="row">
      <div class="col" v-if="localEntry">
        <div class="row  mb-3">
          <div class="col-lg-6">
            <SelectEntity apiEntity="Workers"
              v-model="localEntry.workerId"
              :canChange="canChange" />
          </div>
          <div class="col-lg-3 col-sm-6" v-show="!addPassenger">
            <div class="form-check">
              <input class="form-check-input"
                id="is-passenger"
                type="checkbox"
                v-model="localEntry.isPassenger"
                :disabled="!canChange" />
              <label class="form-check-label" for="is-passenger">
                Sopotnik
              </label>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 text-end" v-show="localEntry.isComplete && !addPassenger && !localEntry.isPassenger">
            <span class="d-none d-lg-inline">ali</span> <button class="btn btn-sm btn-outline-warning mt-1" @click="addPassenger = true; localEntry.isPassenger = false">Dodaj sopotnika</button>
          </div>
          <div class="col-sm-6" v-show="addPassenger">
            <SelectEntity apiEntity="Workers"
                v-model="passengerId"
                :canChange="canChange" />
          </div>
        </div>

        <div class="row  mb-3">
          <div class="col">
            <SelectEntity apiEntity="Vehicles"
                v-model="localEntry.vehicleId"
                :canChange="canChange && !!worker.isAdmin.value" />
          </div>
          <div class="col text-end d-none d-lg-block fs-3"><span class="badge" :class="{
            'bg-warning': !localEntry.isValid,
            'text-dark': !localEntry.isValid,
            'bg-secondary': !!localEntry.isValid && !localEntry.isComplete,
            'bg-success': !!localEntry.isComplete
            }">{{ localEntry.difference }}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="start-time" class="form-label">Začetek poti</label>
            <input class="form-control"
              id="start-time"
              type="datetime-local"
              v-model="localEntry.startDateTimeForInput"
              :disabled="!canChange">
          </div>
          <div class="col">
            <label for="end-time" class="form-label">Konec poti</label>
            <input class="form-control"
              id="end-time"
              type="datetime-local"
              v-model="localEntry.endDateTimeForInput"
              :disabled="!canChange">
          </div>
        </div>

        <div class="row mb-3" v-if="!localEntry.hasStartLocation">
          <div class="col">
            <label for="relation" class="form-label">Relacija</label>
            <input class="form-control"
              id="relation"
              placeholder="Relacija"
              required
              v-model="localEntry.relation"
              :disabled="!canChange">
          </div>
          <div class="col">
            <label for="distance" class="form-label">Razdalja</label>
            <div class="input-group">
              <input class="form-control"
                id="distance"
                type="text"
                pattern="[0-9]+"
                placeholder="km"
                required
                v-model="localEntry.distanceKm"
                :disabled="!canChange" />
              <span class="input-group-text">km</span>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-else>
          <div class="col">
            <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
              :src="'https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=' + localEntry.startLat + ',' + localEntry.startLng + '+(start)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'"></iframe>
          </div>
          <div class="col text-center" v-if="localEntry.hasEndLocation">
            <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
              :src="'https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=' + localEntry.endLat + ',' + localEntry.endLng + '+(konec)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'"></iframe>
            <button class="btn btn-outline-danger btn-sm" @click="eraseEndLocation">Zbriši končno lokacijo</button>
          </div>
          <div class="col text-center" v-else>
            <button class="btn btn-warning btn-sm" @click="closeTransportEntryAsync">Končaj pot na trenutni lokaciji</button>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="comment" class="form-label">Opomba</label>
            <textarea class="form-control"
              id="comment"
              rows="3"
              placeholder="Opomba"
              v-model="localEntry.comments"
              :disabled="!canChange"></textarea>
          </div>
          <div class="col text-center"><button class="btn btn-warning btn-sm" v-show="!localEntry.endDateTime && !localEntry.hasStartLocation" @click="closeTransportEntryAsync">Končaj pot</button></div>
        </div>

      </div>
    </div>
    <template v-slot:buttons>
      <button class="btn btn-danger me-5" type="button"   :disabled="!localEntry || !canChange" @click="saveAsync(true)">Izbriši pot <span class="d-none d-sm-inline">iz naloga</span></button>
      <button class="btn btn-success me-3" type="button" :disabled="!localEntry || !canChange || !localEntry.isValid" @click="saveAsync(false)">Shrani</button>
    </template>
  </Modal>
  `,
  emits: ['update:modelValue', 'close', 'finished'],
  props: {
    modelValue: { type: Object },
    canChange: { type: Boolean },
    workOrderType: { type: String, required: true },
    workOrderId: { type: String }
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const getLocationAsync = inject('getLocationAsync')

    const localEntry = ref(null)
    const addPassenger = ref(false)
    const passengerId = ref(null)

    const closeTransportEntryAsync = async () => {
      if (!localEntry.value) {
        throw new Error("trying to close null TransportEntry")
      }
      if (!localEntry.value.endDateTime){
        localEntry.value.endDateTime = dayjs().utc().format(DATETIME_FORMAT)
      }
      if (localEntry.value.hasStartLocation) {
        try {
          const location = await getLocationAsync()
          if (!location || !location.lat || !location.lng) {
            console.warn(location)
            setError("Lokacija ni na voljo.")
            return
          }
          localEntry.value.endLat = location.lat
          localEntry.value.endLng = location.lng
          localEntry.value.hasEndLocation = true
          localEntry.value.hasStartEndLocation = true
        } catch (err) {
          setError(err)
          emit("close", false)
          return
        }
      }
    }

    const eraseEndLocation = () => {
      localEntry.value.endLat = null
      localEntry.value.endLng = null
      localEntry.value.hasEndLocation = false
      localEntry.value.hasStartEndLocation = false
    }

    watch(() => props.modelValue, (curr, next) => {
      if (curr === null) {
        localEntry.value = null
      } else {
        const te = { ... curr }
        ensureTransportEntryExtensions(te)
        localEntry.value = te
      }
    })

    const saveAsync = async (isForDelete = false) => {
      if (isForDelete) {
        localEntry.value.isForDelete = true
      } else {
        localEntry.value.isDirty = true
      }
      if (!!localEntry.value.startLat && !!localEntry.value.startLng && !!localEntry.value.endLat && !!localEntry.value.endLng) {
        localEntry.value.hasStartEndLocation = true
      }
      let resultText = "NotSaved"
      let result = await saveWorkOrderTransportAsync(instance, localEntry.value, props.workOrderType, props.workOrderId)
      if (result) {
        resultText = "Saved"
      }
      if (result && isForDelete) {
        resultText = "Deleted"
      }
      if (result && !isForDelete
        && localEntry.value.differenceMin > 0
        && !!localEntry.value.endDateTime
        && (!!localEntry.value.hasStartEndLocation
          || (!!localEntry.value.relation && !!localEntry.value.distanceKm))
        ) {
        resultText = "Finished"
      }
      // Pair working
      if (result && resultText === "Finished" && addPassenger.value && passengerId.value?.length === 36) {
        console.log(localEntry.value.vehicleId)
        //nstance, workOrderType, workOrderId, warehouseId, location, startTimeObject
        const te = await insertNewTransportEntryAsync(instance, props.workOrderType, props.workOrderId, localEntry.value.vehicleId)
        te.workerId = passengerId.value
        te.isPassenger = true
        te.startDateTime = localEntry.value.startDateTime
        te.endDateTime = localEntry.value.endDateTime
        te.relation = localEntry.value.relation
        te.distanceKm = localEntry.value.distanceKm
        te.startLat = localEntry.value.startLat
        te.startLng = localEntry.value.startLng
        te.endLat = localEntry.value.endLat
        te.endLng = localEntry.value.endLng
        te.isDirty = true
        result = await saveWorkOrderTransportAsync(instance, te, props.workOrderType, props.workOrderId)
      }
      if (resultText === "Deleted") {
        setSuccess("Pot zbrisana", true)
      }
      if (resultText === "Saved") {
        setSuccess("Pot shranjena", true)
      }
      if (resultText === "Finished") {
        setSuccess("Pot zaključena", true)
        emit("finished", localEntry.value.endDateTime)
      }
      if (result) {
        emit("close", true)
        localEntry.value = null
        addPassenger.value = false
        passengerId.value = null
      } else {
        setError("Napaka pri shranjevanju.")
      }
    }

    return { worker, addPassenger, passengerId, localEntry, closeTransportEntryAsync, eraseEndLocation, saveAsync }
  }
}

export { WorkOrderTransportsActions }

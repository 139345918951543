import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import ProjectsCard from "../components/ProjectsCard"
import Notifications from "../components/Notifications"
import WarehouseTransfersCard from "../components/WarehouseTransfersCard"
import InternalOrdersCard from "../components/InternalOrdersCard"
import ServiceOrdersCard from '../components/ServiceOrdersCard'

export default HomeWorkerProjects = {
  name: 'HomeWorkerProjects',
  components: { ServiceOrdersCard, ProjectsCard, Notifications, WarehouseTransfersCard, InternalOrdersCard },
  template: `
  <div>
    <div class="col">
      <div class="row">
        <div class="col-md-4">
          <WarehouseTransfersCard class="card mb-3" :toWarehouseID="worker.activeWarehouse.value.id" statuses="New,MovementOrder" subTitle="Zame, novi"  />
        </div>
        <div class="col-md-4">
          <Notifications />
          <InternalOrdersCard class="card mb-3" title="Interni nalogi" subTitle="Accepted, zame" :ownerID="worker.id.value" statuses="Accepted" />
        </div>
        <div class="col-md-4">
          <ServiceOrdersCard class="card mb-3" title="Servisni nalogi" subTitle="Triage,Accepted,InProgress + zame" statuses="Triage,Accepted,InProgress" :ownerID="worker.id.value" />
        </div>
      </div>
    </div>
  </div>
  `,
  setup()  {
    const { worker } = useIsAuthorized()

    return {
      worker
    }
  }
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { fetchAuthenticated } from "../utils/fetchAuthenticated"
import { useMsal } from '../composition-api/useMsal'

const useEnums = () => {

  const { instance } = useMsal()

  const transferStatuses = ref(new Map())
  const statuses = ref(new Map())
  const types = ref(new Map())
  const internalOrderTypes = ref(new Map())
  const deviceTypes = ref(new Map())
  const translations = ref(new Map())
  const roles = ref(new Map())


  const loadEnumsAsync = async () => {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Enums`, "GET")
    if (!raw.statuses && !raw.types) {
      throw raw
    }
    transferStatuses.value = new Map(raw.transferStatuses.map(({ value, text }) => ([value, text])))
    internalOrderTypes.value = new Map(raw.internalOrderTypes.map(({ value, text }) => ([value, text])))
    statuses.value = new Map(raw.statuses.map(({ value, text }) => ([value, text])))
    types.value = new Map(raw.types.map(({ value, text }) => ([value, text])))
    deviceTypes.value = new Map(raw.deviceTypes.map(({ value, text }) => ([value, text])))
    translations.value = new Map(raw.translations.map(({ value, text }) => ([value, text])))
    roles.value = new Map(raw.roles.map(({ value, text }) => ([value, text])))
  }

  return { deviceTypes, internalOrderTypes, statuses, types, transferStatuses, translations, roles, loadEnumsAsync }

}

export { useEnums }

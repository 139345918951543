import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "../composition-api/useMsal"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { useWorkOrder } from "scripts/composition-api/useWorkOrder"
import { fetchAuthenticated } from "../utils/fetchAuthenticated"
import {
  setError,
  setSuccess,
  setWarning,
  clearNotifications,
  startLoading,
  stopLoading,
} from "../components/Notification"
import { DATETIME_FORMAT, EMPTY_GUID, DATE_FORMAT } from "../utils/constants"

const ProductionOrder = {
  name: "ProductionOrder",
  template: `
<div v-if="!!workOrder">
  <div class="row">
    <div class="col-sm-3"><h1>{{ workOrder.orderNumber }}</h1></div>
    <div class="col-sm-6 d-none d-lg-block"><h3>Proizvodni nalog</h3></div>
    <div class="col-sm-3"><h2><OrderBadge :status="workOrder.statusText" /></h2></div>
  </div>

  <div class="row mb-3">
    <div class="row col-md-8">
      <label class="col-form-label col-sm-4">Projekt</label>
      <div class="col-sm-8">
        <SelectEntity apiEntity="Projects/Active?select=true" v-model="workOrder.projectID" :canChange="workOrder.canChange" />
      </div>
    </div>
    <div class="col-md-4"><router-link :to="{  name: 'Project', params: { projectId: 'new', productionOrderId: workOrder?.isNew ? 'new' : workOrder.id } }" >Ustvari nov projekt</router-link></div>
  </div>
  <div class="row mb-3">
    <div class="col-md-8 row">
      <label class="col-form-label col-sm-4">Proizvod *</label>
      <div class="col-sm-8">
        <SelectEntity apiEntity="Materials/300" v-model="workOrder.materialID" :canChange="workOrder.canChange && !workOrder.hasSerialNumbers" />
      </div>
    </div>
    <div class="col-md-4">
      <router-link v-if="workOrder?.serialNumbers?.length" :to="{  name: 'ProductionOrderBarcode', params: { workOrderId: workOrder?.id } }" v-show="!workOrder.isNew" target="_blank">Črtne Kode</router-link>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-8 row">
      <label class="col-form-label col-sm-4">Količina *</label>
      <div class="col-sm-2">
        <input class="form-control" type="number"
        pattern="[0-9]+(?<!0)"
        required
        v-model="workOrder.quantity" :disabled="!workOrder.canChange || workOrder.hasSerialNumbers"  />
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="row col-md-8">
      <label class="col-form-label col-sm-4">Delavec</label>
      <div class="col-sm-8">
        <SelectEntity apiEntity="Workers"
          v-model="workOrder.ownerID"
          :canChange="workOrder.canChange"
          :required="!workOrder.canChooseNoWorker" />
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-8 row">
      <label class="col-form-label col-sm-4">Izvedba do</label>
      <div class="col-sm-6">
        <input class="form-control" type="date" v-model="workOrder.dueDate" :disabled="!workOrder.canChange"/>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-8 row">
      <div class="col-form-label col-sm-4">Prioriteta *</div>
      <div class="col-sm-6">
        <IntegerRadioGroup v-model="workOrder.priority" :canChange="workOrder.canChange" :numberOfValues=3 namePrefix="priority" :valueNames="['1','2', '3 (največja)']" />
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-8 row">
      <label class="col-form-label col-sm-4">Opombe</label>
      <div class="col-sm-8">
        <textarea class="form-control" v-model="workOrder.comments" :disabled="!workOrder.canChange"></textarea>
      </div>
    </div>
  </div>

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew">
    <WorkOrderTransports
      :canChange="workOrder.canChange"
      work-order-type="Production"
      :workOrderId="workOrder.id" />
  </div>

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew">
    <WorkOrderTimes
      :canChange="workOrder.canChange"
      work-order-type="Production"
      :workOrderId="workOrder.id" />
  </div>

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew">
    <WorkOrderMaterials
      :quantity="workOrder.quantity"
      :enableTemplates="true"
      :canChange="workOrder.canChange"
      work-order-type="ProductionOrder"
      :workOrderId="workOrder.id"
      :showCommissionForAll="true" />
    <div class="row">
      <div class="col">
        <h6>Dodatni material</h6>
        <textarea class="form-control" placeholder="dodatno nabavljeni material (ni v šifrantu)" v-model="workOrder.additionalMaterial" :disabled="!workOrder.canChange"></textarea>
      </div>
    </div>
  </div>

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew">
    <div class="row">
      <h4 class="col-2">Priloge</h4>
      <div class="col-10 text-end">
        <button class="btn btn-warning btn-sm ms-1 me-2" @click="showUpload = true" v-show="workOrder.canChange">Naloži datoteko</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <Attachments :attachments="attachments" />
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-2">
      <button class="btn btn-danger btn-lg me-3" type="button" v-show="!workOrder.isNew && (workOrder.canDelete || isAdmin) && workOrder?.statusText !== 'Deleted'" @click="deleteOrderAsync">Izbriši</button>
    </div>
    <div class="col-10 text-end">
      <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Production' })">Prekliči</button>
      <button class="btn btn-secondary btn-lg me-3" type="button" :disabled="!isValid" v-show="!workOrder.isNew && !workOrder.hasSerialNumbers" @click="createSerialNumbers">Shrani in ustvari serijske številke</button>
      <button class="btn btn-outline-success btn-lg me-3" type="button" v-show="workOrder.canComplete" @click="markCompletedAsync(false)">Označi kot opravljeno</button>
      <button class="btn btn-outline-success me-3 mb-2" type="button" v-show="workOrder.status === 2 && isAdmin" @click="markTriageAsync">Shrani in v Triage</button>
      <button class="btn btn-outline-success me-3 mb-2" type="button" v-show="workOrder.status === 1 && isAdmin" @click="markActiveAsync">Shrani in v Active</button>
      <button class="btn btn-success btn-lg me-3" type="button" :disabled="!isValid" @click="saveWorkOrderAsync">Shrani</button>
    </div>
  </div>

  <ChangeHistory v-model="workOrder" v-if="!workOrder.isNew" />

  <Upload :visible="showUpload"
    @close="showUpload = false"
    @add="e => { addAttachment(e) }"
    type="ProductionOrder"
    :id="workOrder.id" />
</div>
  `,
  props: {},
  setup(props, { emit }) {
    const { isAdmin } = useIsAuthorized()
    const { instance } = useMsal()

    const {
      workOrder,
      loadWorkOrderAsync,
      saveWorkOrderAsync,
      deleteOrderAsync,
      markCompletedAsync,

      attachments,
      addAttachment,
    } = useWorkOrder("Production")

    const showUpload = ref(false)

    const isValid = computed(() => {
      return (
        workOrder.value.priority > 0 &&
        workOrder.value.canChange &&
        !!workOrder.value.materialID &&
        workOrder.value.materialID !== EMPTY_GUID &&
        workOrder.value.quantity > 0
      )
    })

    const markTriageAsync = async () => {
      workOrder.value.status = 1
      await saveWorkOrderAsync()
    }

    const markActiveAsync = async () => {
      workOrder.value.status = 2
      await saveWorkOrderAsync()
    }

    const createSerialNumbers = async () => {
      const saved = await saveWorkOrderAsync()
      if (saved) {
        startLoading("createSerialNumbers")
        try {
          clearNotifications()
          const raw = await fetchAuthenticated(instance, `${AZURE_URI}/ProductionOrders/${workOrder.value.id}`, "PATCH")
          if (raw?.status !== "ok") {
            throw raw
          }

          setSuccess({ message: `Ustvarjenih ${raw.createdSerialNumbers} številk.` })
          await loadWorkOrderAsync(workOrder.value.id)
        } catch (e) {
          setError(e)
        }
        stopLoading("createSerialNumbers")
      }
    }

    return {
      isAdmin,
      workOrder,
      loadWorkOrderAsync,
      createSerialNumbers,
      attachments,
      saveWorkOrderAsync,
      showUpload,
      addAttachment,
      markCompletedAsync,
      deleteOrderAsync,
      isValid,
      markTriageAsync,
      markActiveAsync,
    }
  },
}

export default ProductionOrder

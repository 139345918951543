import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useWorkOrder } from "../composition-api/useWorkOrder"
import { useMsal } from "../composition-api/useMsal"
import { DATETIME_FORMAT, EMPTY_GUID } from "../utils/constants"
import { getEntityAsync, getCalibrationReportAsync, saveCalibrationReportAsync } from "../api"

export default CalibrationReport = {
  template: `
  <div class="row" v-if="workOrder">
    <div class="col-sm-3"><h1>{{ workOrder.orderNumber }}</h1></div>
    <div class="col-sm-6 d-none d-lg-block"><h3>Kontrolni list preverjanja delovanja termometrov</h3></div>
    <div class="col-sm-3"><h2><OrderBadge :status="workOrder.statusText" /></h2></div>
  </div>

  <div class="row mb-3 mt-3" v-if="workOrder">
    <div class="col-md-8">
    </div>
    <div class="col-md-4">
      <div class="card mb-3" v-if="device">
        <div class="card-body">
          <p class="card-text">Naprava: {{ device.deviceNumber }}</p>
          <p class="card-text">Nalepka: {{ device.label }}</p>
          <p class="card-text">Objekt:  {{ device.facility?.name }} </p>
          <p class="card-text">Tip:  {{ device.attributes.find(a => a.name === "UnitType")?.value }} </p>
          <p class="card-text">Podtip:  {{ device.attributes.find(a => a.name === "UnitSubTypes")?.value }} </p>
        </div>
      </div>
    </div>
  </div>

  <RequiredWarning v-show="!isValid" />

  <div class="row mt-5" v-show="!!device">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <th>Številka merila -: Displej - termometer</th>
            <th>T<sub>umerjeni</sub></th>
            <th>T<sub>preverjanca</sub></th>
            <th>Korekcija /korektivni ukrep</th>
            <th></th>
          </thead>
          <tbody>
            <tr role="button" v-for="e in entity.entries">
              <td><input class="form-control" v-model="e.thermometerIdent" required></td>
              <td><input class="form-control" v-model="e.measuredValue" type="number" step="0.1" required></td>
              <td><input class="form-control" v-model="e.referenceValue"  type="number" step="0.1" required></td>
              <td><input class="form-control" v-model="e.correctiveAction"></td>
              <td><button class="btn btn-sm btn-outline-danger"
                @click="entity.entries.splice(entity.entries.indexOf(e), 1)">Izbriši</button>
              </td>
            </tr>
            <tr role="button">
              <td colspan="5" class="text-end"><button class="btn btn-sm btn-primary" :disabled="!isValid"
                @click="entity.entries.push({})">Dodaj</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row mb-5">
      <div class="col-2">
        <button class="btn btn-danger btn-lg me-3" type="button" v-show="false">Izbriši</button>
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button"
          @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: workOrder.id }  })">
          Prekliči
        </button>
        <button class="btn btn-success btn-lg me-3" type="button" :disabled="!isValid" @click="saveAsync">Shrani</button>
    </div>
  </div>
`,
  setup(props) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()

    const entity = ref({})
    const device = ref(null)
    const { devices, workOrder } = useWorkOrder("Service")

    const saveAsync = async () => {
      const saved = await saveCalibrationReportAsync(instance, entity.value)
      if (saved) {
        router.push({ name: `ServiceOrder`, params: { workOrderId: workOrder.value.id, keepMessages: true } })
      }
    }

    const loadCalibrationReportAsync = async () => {
      entity.value = await getCalibrationReportAsync(instance, route.params.deviceId, route.params.workOrderId)
      device.value = await getEntityAsync(instance, "Device", entity.value.deviceID)
      console.log(device.value)
    }

    const isValid = computed(
      () =>
        entity.value.deviceID &&
        entity.value.deviceID !== EMPTY_GUID &&
        device.value?.typeShort === "THM" &&
        entity.value.entries?.every(
          (entry) => entry.thermometerIdent?.length && entry.measuredValue !== "" && entry.referenceValue !== ""
        )
    )

    watch(() => route.params.deviceId, loadCalibrationReportAsync)

    watch(() => route.params.workOrderId, loadCalibrationReportAsync)

    onMounted(async () => loadCalibrationReportAsync())

    return {
      isValid,
      saveAsync,
      device,
      devices,
      workOrder,
      entity,
    }
  },
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { listEntitiesAsync } from 'scripts/api'
import { useMsal } from '../composition-api/useMsal'

export default UniversalCard = {
  name: 'UniversalCard',
  template: `
    <div>
      <div class="card-body">
        <h5 class="card-title" v-show="title">{{ title }}</h5>
        <h6 class="card-subtitle mb-2 text-muted" v-show="subTitle">{{ subTitle }}</h6>
        <table class="table table-sm table-hover">
            <thead class="thead-light">
              <th v-for="column in columns" :key="column">{{ column }}</th>
            </thead>
            <tbody>
              <tr v-for="(row, index) in results" :key="index">
                <td v-for="column in columns" :key="column">{{ row[column] }}</td>
              </tr>
            </tbody>
        </table>
      </div>
    </div>
  `,
  props: {
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const { instance } = useMsal()
    const results = ref([])
    const columns = ref([])

    onMounted(async () => {
      results.value = await listEntitiesAsync(instance, props.url, {})
      if (results.value.length > 0) {
        columns.value = Object.keys(results.value[0]).filter(column => column !== 'ID')
      }
    })


    return {
      columns,
      results
    }
  }
}

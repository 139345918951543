import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
const SelectStatus = {
  template: `
  <div class="row">
    <label class="col-form-label col-sm-3">Status</label>
    <div class="col-sm-9" v-if="multiple">
      <div class="form-check" v-for="[key, value] in statuses" :value="key">
        <input class="form-check-input" type="checkbox"
          :value="key"
          v-model="checked"
          :id="'flexCheck' + key"
          @change="$emit('change', $event.target.value)">
        <label class="form-check-label" :for="'flexCheck' + key">{{ value }}</label>
      </div>
    </div>
    <div class="col-sm-9" v-else>
      <select class="form-select" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        <option value=""></option>
        <option v-for="[key, value] in statuses" :value="key">{{ value }}</option>
      </select>
    </div>
  </div>
`,
  props: {
    multiple: { type: Boolean, default: false },
    modelValue: String,
    statuses: Map
  },
  emits: ['update:modelValue', 'change'],
  setup(props) {
    const modelValue = toRef(props, 'modelValue')
    const checked = ref([])

    watch(() => modelValue.value, (curr, next) => checked.value = curr.split(","))

    onMounted(() => checked.value = modelValue.value.split(","))

    return {
      checked
    }
  }
}

export default SelectStatus

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listEntitiesAsync } from '../api'
import { useWorkOrders } from 'scripts/composition-api/useWorkOrders'
import ModalAssignWorkOrder from "../components/ModalAssignWorkOrder"

const ServiceOrdersWithCompletedMovementsCard = {
  name: "ServiceOrdersWithCompletedMovementsCard",
  components: { ModalAssignWorkOrder },
  template: `
  <div>
    <div class="card-body">

      <h5 class="card-title">{{ title }} <small class="text-muted" v-show="subTitle">{{ subTitle }}</small></h5>


      <div class="table-responsive" v-if="displayType === 'table'">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">SDN</th>
              <th scope="col">Objekt</th>
              <th scope="col">Zadeva</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" v-for="wo in entities">
              <td @click="selectOrder(wo, true)" class="bg-light">{{ wo.orderNumber }}</td>
              <td @click="selectOrder(wo)">{{ wo.facilityName }}</td>
              <td @click="selectOrder(wo)">{{ wo.subject }}</td>
              <td>
                <button class="btn btn-primary btn-sm" v-if="wo.status === 'Active' && worker.isAdmin" @click="orderForAssignment = wo">Dodeli</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="displayType === 'card'">

      <ul>
        <li v-for="wo in entities" class="mt-1">
          <router-link :to="{ name: 'ServiceOrder', params: { workOrderId: wo.id } }" class="me-1">{{ wo.orderNumber }}</router-link>
          <button class="btn btn-primary btn-sm float-end" v-if="wo.status === 'Active' && worker.isAdmin" @click="orderForAssignment = wo">Dodeli</button>
          <OrderBadge :status="wo.status" :isSpecial="wo.isSpecial" />&nbsp;<strong>{{ wo.facilityName }}</strong>&nbsp;
          <br>
          <small class="text-muted">{{ wo.subject }}</small>
        </li>
      </ul>

      </div>
    </div>

    <ModalAssignWorkOrder :visible="!!orderForAssignment"
      :orderForAssignment="orderForAssignment"
      @close="orderForAssignment = null"
      @assigned="orderAssigned" />
  </div>
  `,
  emits: ['assigned'],
  props: {
    displayType: {
      type: String,
      default: "table"
    },
    title: {
      type: String,
      default: "Servisni nalogi v statusu Active s komisioni, ki so 100% dobavljeni"
    },
    subTitle: {
      type: String,
      default: "Zahtevani ali alternativni material"
    },
    itemsPerPage: {
      type: Number,
      default: 25
    },
  },
  setup (props, { emit }) {
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()
    const orderForAssignment = ref(null)
    const { selectOrder } = useWorkOrders()

    const entities = ref([])

    onMounted(async () => {
      entities.value = await listEntitiesAsync(instance, "ListServiceOrdersWithCompletedMaterialMovements")
    })

    const orderAssigned = async () => {
      orderForAssignment.value = null
      entities.value = await listEntitiesAsync(instance, "ListServiceOrdersWithCompletedMaterialMovements")
      emit('assigned')
    }

    return {
      orderAssigned,
      orderForAssignment,
      entities, worker, selectOrder }
  }

}

export default ServiceOrdersWithCompletedMovementsCard

/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated"
import { setError, startLoading, stopLoading } from "../components/Notification"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useEnums } from "../composition-api/useEnums"

const Production = {
  name: "Production",
  template: `
    <div class="row mb-3">
      <div class="col">
        <button v-show="isAuthenticated" class="btn btn-primary" type="button" @click="$router.push({ name: 'ProductionOrder', params: { workOrderId: 'new' } })">Nov proizvodni nalog</button>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-8">

        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Proizvod</label>
              <div class="col-sm-9">
                <SelectEntity apiEntity="Materials/300" v-model="filters.materialID" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Projekt</label>
              <div class="col-sm-9">
                <SelectEntity apiEntity="Projects/Active?select=true" v-model="filters.projectID"  />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Št. naloga</label>
              <div class="col-sm-9">
                <input class="form-control" type="text" v-model.lazy="filters.orderNumber" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Delavec</label>
              <div class="col-sm-9">
                <SelectEntity apiEntity="Workers" v-model.lazy="filters.ownerID" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Izvedba do</label>
              <div class="col-sm-9">
                <input class="form-control" type="date" v-model.lazy="filters.dueDate" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Prioriteta</label>
              <div class="col-sm-9">
                <IntegerRadioGroup v-model="filters.priority" :numberOfValues=3 namePrefix="priority" :valueNames="['1','2', '3 (največja)']" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Serijska</label>
              <div class="col-sm-9">
                <input class="form-control" type="text" pattern="[0-9.]+" v-model.lazy="filters.serialNumber" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">

            </div>
          </div>
        </div>

        <DateTimeFilterSet v-model="filters" />

      </div>
      <div class="col-md-4">
        <SelectStatus :statuses="statuses" v-model="filters.status" @change="changeSelectStatus" :multiple="true" />
        <div class="row mt-2"><div class="col"><a class="btn btn-sm btn-secondary" @click="resetProductionFilters">Pobriši iskalne pogoje</a></div></div>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-stripped table-sm table-hover">
            <thead>
              <tr>
                <th scope="col" class="bg-light">Št.&nbsp;naloga</th>
                <th scope="col" class="bg-light">Status</th>
                <th scope="col">Proizvod</th>
                <th scope="col">Projekt</th>

                <th scope="col">Količina</th>
                <th scope="col">Delavec</th>
                <th scope="col">Izvedba do</th>
              </tr>
            </thead>
            <tbody>
              <tr role="button" :class="{ 'table-danger': order.priority === 3, 'table-warning': order.priority === 2, 'table-light': order.priority === 1  }" v-for="order in orders">
                <td @click="selectOrder(order, true)" class="bg-light">{{ order.orderNumber }}</td>
                <td @click="selectOrder(order, true)" class="bg-light"><OrderBadge :status="order.status" /></td>
                <td @click="selectOrder(order)">{{ order.subject }}</td>
                <td @click="selectOrder(order)">{{ order.project }}</td>
                <td @click="selectOrder(order)">{{ order.quantity }}</td>
                <td @click="selectOrder(order)">{{ order.owner }}</td>
                <td @click="selectOrder(order)">{{ order.dueDate ? $dayjs.utc(order.dueDate).local().format($DATE_FORMAT_DISPLAY) : '' }}</td>
              </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>
    <Paging v-if="orders?.length" :paging="paging" @change="setPageNum" />
  `,
  props: {},
  setup(props, { emit }) {
    const { paging, resetPaging, setPageNum, filters, orders, DEFAULT_FILTERS } = useWorkOrders("Production")
    const { statuses, loadEnumsAsync } = useEnums()

    const router = VueRouter.useRouter()
    const isAuthenticated = useIsAuthenticated()

    const selectOrder = (order, openInNewWindow = false) => {
      const routeData = router.resolve({ name: "ProductionOrder", params: { workOrderId: order.id } })
      if (openInNewWindow) {
        window.open(routeData.href, "_blank")
      } else {
        router.push(routeData)
      }
    }

    const DEFAULT_SERVICE_STATUS_FILTERS = "Active,Accepted,InProgress"

    const changeSelectStatus = (e) => {
      let s = filters.value.status.split(",")
      const existingIndex = s.findIndex((el) => el === e)
      if (existingIndex > -1) {
        s.splice(existingIndex, 1)
      } else {
        s.push(e)
      }
      filters.value.status = s.toString()
    }

    const resetProductionFilters = () => {
      let f = DEFAULT_FILTERS()
      f.status = DEFAULT_SERVICE_STATUS_FILTERS
      filters.value = f
    }

    watch(
      () => ({ ...filters.value }),
      () => resetPaging() // will also fire getOrdersAsync()
    )

    onMounted(async () => {
      startLoading("Orders")
      try {
        await loadEnumsAsync()
        resetProductionFilters()
      } catch (e) {
        setError(e)
      }
      stopLoading("Orders")
    })

    return {
      paging,
      setPageNum,
      isAuthenticated,
      selectOrder,
      orders,
      statuses,
      filters,
      resetProductionFilters,
      changeSelectStatus,
    }
  },
}

export default Production

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "./useMsal"
import { fetchAuthenticated } from "../utils/fetchAuthenticated"
import {
  setError,
  setSuccess,
  setWarning,
  clearNotifications,
  startLoading,
  stopLoading,
} from "../components/Notification"
import { useAttachments } from "../components/Attachments"
import { getWorkOrderAsync, patchWorkOrderAsync, listServiceOrderDevicesAsync } from "../api"

const useWorkOrder = (workOrderType, skipRoute = false, redirectIfNew = true) => {
  const route = VueRouter.useRoute()
  const router = VueRouter.useRouter()
  const { instance } = useMsal()
  const { attachments, addAttachment } = useAttachments()

  const workOrder = ref(null)
  const devices = ref([])

  const loadWorkOrderAsync = async (id) => {
    const temp = await getWorkOrderAsync(instance, workOrderType, id)
    if (!!temp) {
      temp.isNew = id === "new"
    }
    workOrder.value = temp
    attachments.value = temp?.attachments
    if (!!workOrder.value && workOrderType === "Service") {
      devices.value = await listServiceOrderDevicesAsync(instance, id)
    }
  }

  const changeWorkOrderStatusAsync = async (status, location) => {
    if (typeof status !== "string") {
      setError("status must be a string")
      return false
    }
    const saveResult = await saveWorkOrderAsync(location)
    const result = await patchWorkOrderAsync(instance, { status }, workOrderType, workOrder.value.id)
    if (result === true && saveResult === true) {
      setSuccess({ message: "Shranjeno in v nov status" })
      await loadWorkOrderAsync(workOrder.value.id)
    }
    return result
  }

  const verifyStockOrderAsync = async () => {
    const saveResult = await saveWorkOrderAsync()
    const result = await patchWorkOrderAsync(instance, { status: "OrderMaterial" }, workOrderType, workOrder.value.id)
    if (result === true && saveResult === true) {
      setSuccess({ message: "Shranjeno in v naročanje materiala" })
      await loadWorkOrderAsync(workOrder.value.id)
    }
  }

  const clearCommissionsAsync = async () => {
    const result = await patchWorkOrderAsync(instance, { clearCommissions: true }, workOrderType, workOrder.value.id)
    if (result === true) {
      setSuccess({ message: "Komisioni resetirani." })
      await loadWorkOrderAsync(workOrder.value.id)
    }
  }

  const deleteOrderAsync = async () => {
    const result = await patchWorkOrderAsync(instance, { status: "Deleted" }, workOrderType, workOrder.value.id)
    if (result === true) {
      setSuccess({ message: "Izbrisano" })
      await loadWorkOrderAsync(workOrder.value.id)
    }
  }

  const markCompletedAsync = async (skipSuccessRoute = false) => {
    if (typeof skipSuccessRoute !== "boolean") {
      console.warn("markCompletedAsync parameter incorrect type", skipSuccessRoute)
    }
    const result = await patchWorkOrderAsync(instance, { status: "Completed" }, workOrderType, workOrder.value.id)
    if (result === true) {
      setSuccess({ message: 'Označeno za "Opravljeno".' })
      if (!skipSuccessRoute) {
        console.log("push")
        router.push({ name: workOrderType, params: { keepMessages: true } })
      }
    }
    return result
  }

  const changeOwnerAsync = async (workOrderId, newOwnerId) => {
    if (newOwnerId?.length !== 36) {
      setError("missing newOwnerId" + newOwnerId)
      return false
    }
    const result = await patchWorkOrderAsync(instance, { newOwnerId }, workOrderType, workOrderId)
    if (result === true) {
      setSuccess({ message: "Spremenjen lastnik." })
    }
    return result
  }

  const saveLocationAsync = async (location) => {
    if (location && (location.lat > 0 || location.lat < 0)) {
      const raw2 = await fetchAuthenticated(instance, `${AZURE_URI}/Location`, "POST", location)
      if (raw2?.status !== "ok") {
        console.error(raw2)
      }
    }
  }

  const saveWorkOrderAsync = async (location = null) => {
    let result = false
    startLoading("saveWorkOrderAsync")
    try {
      clearNotifications()
      if (workOrder.value.dueDate === "Invalid Date") {
        workOrder.value.dueDate = ""
      }

      if (!workOrder.value.isNew && workOrderType === "Service") {
        const payload = devices.value.map((d) => {
          return { deviceId: d.id, ...d }
        })
        const raw1 = await fetchAuthenticated(
          instance,
          `${AZURE_URI}/ServiceOrder/Devices/` + workOrder.value.id,
          "PUT",
          payload
        )
        if (raw1?.status !== "ok" || raw1?.result !== true) {
          throw raw1
        }
      }

      const raw = await fetchAuthenticated(
        instance,
        `${AZURE_URI}/${workOrderType}Order`,
        workOrder.value.isNew ? "POST" : "PUT",
        workOrder.value
      )
      if (raw?.status !== "ok") {
        throw raw
      }

      await saveLocationAsync(location)

      setSuccess({ message: "Shranjeno" })
      if (workOrder.value.isNew && redirectIfNew) {
        router.push({ name: `${workOrderType}Order`, params: { workOrderId: workOrder.value.id, keepMessages: true } })
      }
      result = true
      await loadWorkOrderAsync(workOrder.value.id)
    } catch (e) {
      setError(e)
    }
    stopLoading("saveWorkOrderAsync")
    return result
  }

  const saveAsNewWorkOrderAsync = async (location = null) => {
    let result = false
    startLoading("saveAsNewWorkOrderAsync")
    try {
      clearNotifications()
      if (workOrder.value.dueDate === "Invalid Date") {
        workOrder.value.dueDate = ""
      }

      const raw = await fetchAuthenticated(instance, `${AZURE_URI}/${workOrderType}OrderAsNew`, "POST", workOrder.value)
      if (raw?.status !== "ok" || !raw?.id) {
        throw raw
      }
      const workOrderId = raw.id

      /*
      if (workOrderType === 'Service') {
        const payload = devices.value.map(d => { return { deviceId: d.id, ...d } })
        const raw1 = await fetchAuthenticated(instance, `${AZURE_URI}/ServiceOrder/Devices/` + workOrder.value.id, "PUT", payload)
        if (raw1?.status !== "ok" || raw1?.result !== true) {
          throw raw1
        }
      }*/

      await saveLocationAsync(location)

      setSuccess({ message: "Shranjeno kot nov" })
      router.push({ name: `${workOrderType}Order`, params: { workOrderId, keepMessages: true } })
      result = true
      await loadWorkOrderAsync(workOrderId)
    } catch (e) {
      setError(e)
    }
    stopLoading("saveAsNewWorkOrderAsync")
    return result
  }

  if (!skipRoute) {
    watch(
      () => route.params.workOrderId,
      async (newId) => {
        if (newId) {
          await loadWorkOrderAsync(newId)
          if (newId === "new" && route.params.customerID && route.params.facilityID) {
            workOrder.value.facilityID = route.params.facilityID
            workOrder.value.customerID = route.params.customerID
            console.log("new with customer and facility", newId)
          }
          if (newId === "new" && route.params.projectID) {
            workOrder.value.projectID = route.params.projectID
            console.log("new with project", newId)
          }
        }
      },
      { immediate: true }
    )
  }

  return {
    workOrder,
    loadWorkOrderAsync,
    saveWorkOrderAsync,
    deleteOrderAsync,
    markCompletedAsync,
    changeOwnerAsync,
    attachments,
    devices,
    addAttachment,
    verifyStockOrderAsync,
    saveAsNewWorkOrderAsync,
    clearCommissionsAsync,
    changeWorkOrderStatusAsync,
  }
}

export { useWorkOrder }

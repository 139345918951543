import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, defineProps, defineEmits } from 'vue'

export const FacilityContactEdit = {
  name: 'FacilityContactEdit',
  template: `
  <div v-if="!!model">
    <h5>Dodaj podpisnika:</h5>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text">Ime in priimek</span>
      <input type="text" class="form-control"
      v-model="model.name">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text">Email</span>
      <input type="email" class="form-control"
      v-model="model.email">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text">Fax</span>
      <input type="tel" class="form-control"
      v-model="model.fax">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text">Telefon</span>
      <input type="tel" class="form-control"
      v-model="model.phone">
    </div>
    <div class="mb-3">
      <button class="btn btn-primary btn-sm"
        @click="$emit('update:modelValue', model); $emit('save')"
        :disabled="!(model.name?.length > 3 && model.email?.length > 6 && model.email.includes('@'))">
        Dodaj podpisnika
      </button>
    </div>
  </div>

  `,
  emits: ['update:modelValue', 'save'],
  props: {
    modelValue: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const model = ref({ ... props.modelValue})
    return { model }
  }
}

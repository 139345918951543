import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { EMPTY_GUID, DATETIME_FORMAT, MAX_MINUTES_TIME_ENTRY } from '../utils/constants'
import { useMsal } from '../composition-api/useMsal'
import { setError, startLoading, stopLoading, setSuccess } from "./Notification"
import { ensureTimeEntryExtensions } from '../utils/timeEntryExtensions'
import { useTimeEntries } from './WorkOrderTimes'

import { saveWorkOrderTimesAsync, insertNewTimeEntryAsync } from '../api'

const WorkOrderTimeActions = {
  template: `
  <Modal :title="'Akcije za delo'" :visible="!!localEntry" @close="$emit('close')">
    <div class="row">
      <div class="col" v-if="localEntry">
        <div class="row mb-3">
          <div class="col-lg-6">
            <SelectEntity apiEntity="Workers"
              v-model="localEntry.workerId"
              :canChange="canChange" />
          </div>
        </div>

        <div class="row  mb-3">
          <div class="col-sm-6 text-end" v-show="(!localEntry.isComplete && !addCoworker) || !canChange"></div>
          <div class="col-sm-6 text-end" v-show="localEntry.isComplete && !addCoworker && canChange">
            <span class="d-none d-lg-inline">ali</span> <button class="btn btn-sm btn-outline-warning mt-1" @click="addCoworker = true">Dodaj sodelavca</button>
          </div>
          <div class="col-sm-6" v-show="addCoworker">
            <SelectEntity apiEntity="Workers"
                v-model="coworkerId"
                :canChange="canChange" />
          </div>
          <div class="col-sm-6 text-end fs-2"><span class="badge" :class="{
            'bg-warning': !localEntry.isValid,
            'text-dark': !localEntry.isValid,
            'bg-secondary': !!localEntry.isValid && !localEntry.isComplete,
            'bg-success': !!localEntry.isComplete
            }">{{ localEntry.difference }}</span></div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="start-time" class="form-label">Začetek dela</label>
            <input class="form-control"
              id="start-time"
              type="datetime-local"
              v-model="localEntry.startDateTimeForInput"
              :disabled="!canChange" />
          </div>
          <div class="col">
            <label for="end-time" class="form-label">Konec dela</label>
            <input
              type="datetime-local"
              class="form-control"
              id="end-time"
              v-model="localEntry.endDateTimeForInput"
              :disabled="!canChange" />
          </div>
        </div>

        <div class="row  mb-3">
          <div class="col">
            <label for="comment" class="form-label">Opomba</label>
            <textarea class="form-control"
              id="comment"
              rows="3"
              placeholder="Opomba"
              v-model="localEntry.comments"
              :disabled="!canChange"></textarea>
          </div>
          <div class="col text-center"><button class="btn btn-warning btn-sm" v-show="!localEntry.endDateTime" @click="closeTimeEntryAsync(localEntry, workOrderType, workOrderId, addMinutes)">Končaj delo</button></div>
        </div>

      </div>
    </div>
    <template v-slot:buttons>
      <button class="btn btn-danger me-5" type="button" :disabled="!localEntry || !canChange" @click="saveAsync(true)">Izbriši delo <span class="d-none d-sm-inline">iz naloga</span></button>
      <button class="btn btn-success me-3" type="button" :disabled="!localEntry || !canChange || !localEntry.isValid" @click="saveAsync(false)">Shrani</button>
    </template>
  </Modal>
  `,
  emits: ['update:modelValue', 'close', 'finished'],
  props: {
    modelValue: { type: Object },
    canChange: { type: Boolean },
    workOrderType: { type: String, required: true },
    workOrderId: { type: String },
    addMinutes: { type: Number, default: 0 }
  },
  setup(props, { emit }) {
    const { instance } = useMsal()

    const { closeTimeEntryAsync } = useTimeEntries()

    const localEntry = ref(null)
    const addCoworker = ref(false)
    const coworkerId = ref(null)

    watch(() => props.modelValue, (curr, next) => {
      if (curr === null) {
        localEntry.value = null
      } else {
        const te = { ...curr }
        ensureTimeEntryExtensions(te)
        localEntry.value = te
      }
    })

    const saveAsync = async (isForDelete = false) => {
      if (isForDelete) {
        localEntry.value.isForDelete = true
      } else {
        localEntry.value.isDirty = true
      }
      let resultText = "NotSaved"
      let result = await saveWorkOrderTimesAsync(instance, localEntry.value, props.workOrderType, props.workOrderId)
      if (result) {
        resultText = "Saved"
      }
      if (result && isForDelete) {
        resultText = "Deleted"
      }
      if (result && !isForDelete
        && localEntry.value.differenceMin > 0
        && !!localEntry.value.endDateTime) {
        resultText = "Finished"
      }

      if (result && addCoworker.value && coworkerId.value?.length === 36 && !isForDelete) {
        const te = await insertNewTimeEntryAsync(instance, props.workOrderType, props.workOrderId, localEntry.value.startDateTimeObject)
        te.workerId = coworkerId.value
        te.endDateTime = localEntry.value.endDateTime
        te.comments = "Delo v dvoje"
        te.isDirty = true
        result = await saveWorkOrderTimesAsync(instance, te, props.workOrderType, props.workOrderId)
      }
      if (resultText === "Deleted") {
        setSuccess("Delo izbrisana", true)
      }
      if (resultText === "Saved") {
        setSuccess("Delo shranjeno", true)
      }
      if (resultText === "Finished") {
        setSuccess("Delo zaključeno", true)
        emit("finished", localEntry.value.endDateTime)
      }
      if (result) {
        emit("close", true /* reload */)
        localEntry.value = null
        addCoworker.value = false
        coworkerId.value = null
      }
    }

    return { addCoworker, coworkerId, localEntry, closeTimeEntryAsync, saveAsync }
  }
}

export { WorkOrderTimeActions }

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onBeforeUnmount } from 'vue'

const Camera = {
  name: 'Camera',
  template: `
  <div class="alert alert-danger" role="alert" v-show="!!error">
    {{ error }}
  </div>
  <div @click="capture">
    <video ref="videoEl" style="width: 100%;" />
  </div>
  <canvas ref="canvasEl" width="1024" height="768" style="width: 100%; display: none" />
  <button class="btn btn-primary" :disabled="!!error" @click="capture">Slikaj</button>
  `,
  emits: ["captured"],
  props: {
    quantity: { type: Number, default: 1 },
    section: { type: Object }
  },
  setup (props, { emit }) {
    const videoEl = ref(null)
    const canvasEl = ref(null)
    const error = ref(null)
    let stream = null

    const capture = m => {
      if (!!error.value) {
        return
      }
      canvasEl.value.getContext('2d').drawImage(videoEl.value, 0, 0, canvasEl.value.width, canvasEl.value.height);
      let image_data_url = canvasEl.value.toDataURL('image/jpeg')
      emit("captured", image_data_url)
    }

    onBeforeUnmount(async () => {
      if (stream) {
        stream.getTracks().forEach(track => { track.stop() })
      }
    })

    onMounted(async () => {
      if (typeof navigator.mediaDevices?.getUserMedia !== 'function') {
        error.value = "Vaša naprava nima video kamere"
        return
      }

      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { ideal: 'environment' },
            width: { ideal: 1024 },
            height: { ideal: 768 }
          },
          audio: false
        })
        videoEl.value.playsInline = true
        videoEl.value.srcObject = stream
        await videoEl.value.play()
      }
      catch (e) {
        if (e?.message) {
          error.value = e.message
        } else {
          error.value = e
        }
      }
    })

    return { videoEl, canvasEl, error, capture }

  },
}

export default Camera

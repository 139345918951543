import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useWorkOrder } from "scripts/composition-api/useWorkOrder"

const ProductionOrderBarcode = {
  name: "ProductionOrderBarcode",
  template: `
  <div class="productionorderbarcode barcode">
    <div v-if="!!workOrder && workOrder.status !== 12" class="row mb-5" v-for="sn in workOrder.serialNumbers">
      <div class="col-6">
        <div class="card-qrcode">
          <img src="/images/samal-logo.png" alt="Samal d.o.o. logotip">
          <h5>HLADILNA TEHNIKA</h5>
          <ul>
            <li class="tel">📞 070 20 33 33</li>
            <li class="email">✉️ servis@samal.si</li>
          </ul>
        </div>
      </div>
      <div class="col-6" v-html="innerHtmlCode(workOrder.material, sn)"></div>
      <div class="col-12 end-box">
        <h2>{{ workOrder.material.name }}</h2>
        <div class="d-flex align-items-center mar-item">
          <h6>Ident</h6>
          <h2>{{ workOrder.material.opPisProductID }}</h2>
          <h6>Oznaka serije</h6>
          <h2>{{ sn.number }}</h2>
        </div>
      </div>
    </div>
    <div v-else class="row d-print-none">
      Proizvodni nalog ali serijske številke ne obstajajo.
    </div>
    <div class="row d-print-none">
      <div class="col">
        <button class="btn btn-primary btn-lg me-3" type="button" @click="$window.print()">Natisni</button>
      </div>
    </div>
  </div>
  `,
  props: {},
  setup(props, { emit }) {
    const { workOrder } = useWorkOrder("Production")

    const innerHtmlCode = (material, sn) => {
      if (!workOrder.value) {
        return ""
      }
      const gsSeparator = "\x1d"
      const code = "240" + material.opPisProductID + gsSeparator + "21" + sn.number
      var svgNode = DATAMatrix(code)
      return svgNode.outerHTML
    }

    return {
      workOrder,
      innerHtmlCode,
    }
  },
}

export default ProductionOrderBarcode

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { setError, startLoading, stopLoading } from "../components/Notification"
import { EMPTY_GUID } from "../utils/constants"
import { useWorkOrders } from "../composition-api/useWorkOrders"

export default InternalOrdersCard = {
  name: 'InternalOrdersCard',
  template: `<div>
  <div class="card-body">
    <h5 class="card-title">{{ title }} <small class="text-muted" v-show="subTitle">{{ subTitle }}</small></h5>
    <ul>
      <li v-for="wo in orders" class="mt-1">
        <router-link :to="{ name: 'InternalOrder', params: { workOrderId: wo.id } }" target="_blank">
        {{ wo.orderNumber }}
        </router-link> <OrderBadge :status="wo.status" /> {{ wo.subject }}
      </li>
    </ul>
    <p v-show="orders.length === 0">Ni nalogov.</p>
    <p v-show="paging.itemsPerPage === orders.length">
      Obstaja več nalogov, vendar nimamo implementiranega prikaza.
    </p>
  </div>
</div>`,
  props: {
    statuses: {
      type: String,
      default: ""
    },
    ownerID : {
      type: String,
      default: EMPTY_GUID
    },
    internalOrderTypes: {
      type: String,
      default: ''
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    itemsPerPage: {
      type: Number,
      default: 25
    }
  },
  setup(props, { emit }) {
    const router = VueRouter.useRouter()
    const { resetPaging, filters, orders, paging, setPageNum } = useWorkOrders("Internal")

    watch(
      () => ({ ...filters.value }),
      () => resetPaging()  // will also fire getOrdersAsync()
    )

    onMounted(async () => {
      filters.value.status = props.statuses
      filters.value.ownerID = props.ownerID
      filters.value.internalOrderTypes = props.internalOrderTypes
      paging.value.itemsPerPage = props.itemsPerPage
    })

    return {
      orders,
      paging
    }
  }
}

const SelectOrderType = {
  template: `
  <div class="row">
    <label class="col-form-label col-sm-3">Tip naloga</label>
    <div class="col-sm-9">
      <select class="form-select" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        <option value="all">Vse vrste</option>
        <option v-for="[key, value] in types" :value="key">{{ value }}</option>
      </select>
    </div>
  </div>
`,
  props: {
    modelValue: String,
    types: Map
  },
  emits: ['update:modelValue'],
  setup(props) {

  }
}

export default SelectOrderType

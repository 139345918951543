import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { setError, startLoading, stopLoading } from "../components/Notification"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { useEnums } from '../composition-api/useEnums'
import { useWorkOrders } from '../composition-api/useWorkOrders'
import { useWarehouseTransfers } from "../composition-api/useWarehouseTransfers"
import SelectWarehouse from "../components/SelectWarehouse"

import HomeAdmin from "./HomeAdmin"
import HomeLeaderService from "./HomeLeaderService"
import HomeLeaderProduction from "./HomeLeaderProduction"
import HomeLeaderProjects from "./HomeLeaderProjects"

import HomeWorkerService from "./HomeWorkerService"
import HomeWorkerProduction from "./HomeWorkerProduction"
import HomeWorkerProjects from "./HomeWorkerProjects"
import HomeWarehouseManager from "./HomeWarehouseManager"

export default Home = {
  components: { SelectWarehouse, Notifications, HomeAdmin, HomeLeaderService, HomeWorkerService, HomeWorkerProduction,
    HomeWorkerProjects, HomeLeaderProjects, HomeLeaderProduction, HomeWarehouseManager },
  template: `
  <HomeAdmin class="row mt-3" v-if="worker.userRoleText.value === 'Administrator'" />
  <HomeLeaderService class="row mt-3" v-else-if="worker.userRoleText.value === 'LeaderService'" />
  <HomeLeaderProduction class="row mt-3" v-else-if="worker.userRoleText.value === 'LeaderProduction'" />
  <HomeLeaderProjects class="row mt-3" v-else-if="worker.userRoleText.value === 'LeaderProjects'" />
  <HomeWorkerService class="row mt-3" v-else-if="worker.userRoleText.value === 'WorkerService'" />
  <HomeWorkerProduction class="row mt-3" v-else-if="worker.userRoleText.value === 'WorkerProduction'" />
  <HomeWorkerProjects class="row mt-3" v-else-if="worker.userRoleText.value === 'WorkerProjects'" />
  <HomeWarehouseManager class="row mt-3" v-else-if="worker.userRoleText.value === 'WarehouseManager'" />

  <div class="row mt-3" v-else>
    <h1>Vsebina strani domov ni definirana za vrsto uporabnika: {{ worker.userRoleText.value }}.</h1>
  </div>

  `,
  setup() {
    const { worker } = useIsAuthorized()

    return {
      worker
    }
  }
}

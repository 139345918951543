import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { listEntitiesAsync } from "../api"

const Customers = {
  name: 'Customers',
  template: `
  <div class="row">
    <div class="col-sm-4">
      <label class="form-label" for="is-active">Prikaži aktivne</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-active" v-model="displayActive"  />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Naziv</th>
              <th scope="col">Naslov</th>
              <th scope="col">Kraj</th>
              <th scope="col">Št. objektov</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" v-for="customer in (customers.filter(w => w.isActive === displayActive))" @click="selectCustomer(customer)">
              <td>{{ customer.fullName }}</td>
              <td>{{ customer.address }}</td>
              <td>{{ customer.postCode }} {{ customer.postCity }}</td>
              <td>{{ customer.noFacilities }}</td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>

`,
  props: {

  },
  setup() {
    const router = VueRouter.useRouter()

    const displayActive = ref(true)

    const { instance } = useMsal()

    const customers = ref([])

    const selectCustomer = customer => router.push({ name: 'CustomerEdit', params: { customerId: customer.id } })

    onMounted(async () => customers.value = await listEntitiesAsync(instance, "Customers", "?variant=Full"))

    return {
      customers,
      displayActive,
      selectCustomer
    }
  }
}

export default Customers

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"

const IntegerRadioGroup = defineComponent({
  name: "IntegerRadioGroup",
  template: `
    <div v-for="n in numberOfValues" :key="n" class="form-check form-check-inline">
      <input class="form-check-input" type="radio" :name="namePrefix + n" :id="namePrefix + n" :value="n"
        v-model="value" :disabled="!canChange" @change="$emit('update:modelValue', parseInt($event.target.value))" >
      <label class="form-check-label" :for="namePrefix + n">{{ valueNames_[n-1] }}</label>
    </div>
  `,
  props: {
    modelValue: Number,
    canChange: { type: Boolean, default: true },
    numberOfValues: { type: Number, default: 3 },
    namePrefix: { type: String, default: "integerRadio" },
    valueNames: { type: Array, default: () => [] },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const modelValue = toRef(props, "modelValue")
    const valueNames = toRef(props, "valueNames")
    const value = ref("")
    watch(
      () => modelValue.value,
      (curr, next) => (value.value = curr)
    )

    const valueNames_ = computed(() =>
      Array.from({ length: props.numberOfValues }, (_, i) => i + 1).map((i) => valueNames.value[i - 1] || i)
    )

    onMounted(() => (value.value = modelValue.value))

    return {
      valueNames_,
      value,
    }
  },
})

export default IntegerRadioGroup

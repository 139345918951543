/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useWorkOrder } from '../composition-api/useWorkOrder'
import { setError, startLoading, stopLoading, setSuccess } from "../components/Notification"
import WorkOrdersList from '../components/WorkOrdersList'

const FacilityServiceOrders = {
  name: 'FacilityServiceOrders',
  components: { WorkOrdersList },
  template: `
    <div class="row" v-if="!!workOrder">
      <div class="col-sm-3"><h1>{{ workOrder.orderNumber }}</h1></div>
      <div class="col-sm-6 d-none d-lg-block"><h3>Servisni nalog</h3></div>
      <div class="col-sm-3"><h2><OrderBadge :status="workOrder.statusText" /></h2></div>
    </div>

    <div class="row">
      <div class="col"><h3>Ostali nalogi na tem objektu</h3></div>
    </div>

    <div class="row">
      <div class="col">
        <WorkOrdersList
          :workOrders="orders"
          @takeOwnership="takeOwnershipAsync"
          @assignTo="assignToAsync" />
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-2 text-start">

      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: workOrder.id }  })">Prekliči</button>
        <button class="btn btn-success btn-lg me-3" type="button"
          v-show="workOrder?.canComplete"
          @click="gotoSigning">Podpis stranke</button>
      </div>
    </div>

  `,
  props: {
    noSign: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { worker } = useIsAuthorized()
    const { paging, filters, orders, getOrdersAsync, DEFAULT_FILTERS } = useWorkOrders("Service")
    const { workOrder, loadWorkOrderAsync, changeOwnerAsync } = useWorkOrder("Service")

    const resetServiceFilters = facilityID => {
      let f = DEFAULT_FILTERS()
      f.status = "Active,Accepted,InProgress"
      f.facility = facilityID
      filters.value = f
    }

    const takeOwnershipAsync = async wo => {
      if (await changeOwnerAsync(wo.id, worker.id.value)) {
        await getOrdersAsync()
      }
    }

    const assignToAsync = async (req) => {
      if (!req.order || !req.selectedWorker) {
        console.warn("assignToAsync: invalid request", req)
        return
      }
      console.log(req)
      if (await changeOwnerAsync(req.order.id, req.selectedWorker)) {
        await getOrdersAsync()
      }
    }

    const selectOrder = (order, openInNewWindow = false) => {
      // TODO: duplicate
      const routeData = router.resolve({ name: 'ServiceOrder', params: { workOrderId: order.id } })
      if (openInNewWindow) {
        window.open(routeData.href, "_blank")
      } else {
        router.push(routeData)
      }
    }

    const gotoSigning = () => router.push({ name: 'SignOrder', params: { workOrderId: workOrder.value.id, keepMessages: true } })

    onMounted(async () => {
      startLoading('FacilityServiceOrders')
      try {
        console.log(props)
        await loadWorkOrderAsync(route.params.workOrderId)
        resetServiceFilters(workOrder.value.facilityID)
        await getOrdersAsync()
        if (orders.value.length === 1 && !props.noSign) {
          gotoSigning()
        }
      } catch (e) {
        setError(e)
      }
      stopLoading('FacilityServiceOrders')
    })

    return {
      worker,
      paging,
      orders,
      filters,
      resetServiceFilters,
      gotoSigning,
      workOrder,
      takeOwnershipAsync,
      assignToAsync,
      selectOrder
    }
  },
}

export default FacilityServiceOrders


export class CustomNavigationClient extends msal.NavigationClient{
  constructor(router) {
      super()
      this.router = router
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url, options) {
      const relativePath = url.replace(window.location.origin, '')
      if (options.noHistory) {
          this.router.replace(relativePath)
      } else {
          this.router.push(relativePath)
      }

      return false
  }
}

import { setError, startLoading, stopLoading, setSuccess, clearNotifications, setWarning } from "../components/Notification"
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { listEntitiesAsync } from 'scripts/api'
import { useMsal } from '../composition-api/useMsal'
import ProductionOrder from "scripts/views/ProductionOrder"


export const WorkersWithWorkOrdersCard = {
  name: 'WorkersWithWorkOrdersCard',
  template: `
  <div class="card-body">
      <h5 class="card-title">Delovni nalogi <small class="text-muted" v-show="subTitle">{{ subTitle }}</small></h5>

      <ul>
        <li v-for="w in uniqueWorkers">
          <router-link :to="{ name: 'WorkerEdit', params: { workerId: w.ID } }" target="blank" class="me-2">{{ w.FirstName }} {{ w.LastName }}</router-link>
            <span class="badge rounded-pill bg-dark" v-show="w.TransportOfficeHome > 0">Pot domov</span>
            <!-- span class="badge rounded-pill bg-danger" v-show="showLoggedTime && w.LoggedMinutes < 420">{{ Math.floor(w.LoggedMinutes / 60) }}:{{ (w.LoggedMinutes % 60).toString().padStart(2, '0') }}</span -->
            <button class="btn btn-primary btn-sm" v-if="orderForAssignment?.status === 'Active'" @click="$emit('selected', w.ID)">Dodeli</button>
          <ul>
            <li v-for="so in w.workOrders">
              <span v-if="so.OrderType === 'ServiceOrder'">
                <router-link  :to="{ name: 'ServiceOrder', params: { workOrderId: so.orderId } }" target="_blank" class="me-1">
                {{ so.OrderNumber }}
                </router-link>
                <OrderBadge :status="so.Status" :isSpecial="so.IsSpecial" />
                <strong class="me-1 mb-1">{{ so.FacilityName }}</strong>
                <small class="text-muted me-1">{{ so.Subject }}</small>
                <span class="badge rounded-pill bg-warning" v-show="so.IsWorkOnWeeked">Vikend delo</span>
              </span>
              <span v-else-if="so.OrderType === 'ProductionOrder'">
                <router-link  :to="{ name: 'ProductionOrder', params: { workOrderId: so.orderId } }" target="_blank" class="me-1">
                {{ so.OrderNumber }}
                </router-link>
                <OrderBadge :status="so.Status" v-if="so.Status === 'InProgress'"  />
              </span>


              <span v-else>
                <span class="badge rounded-pill bg-warning">Brez nalog</span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
  </div>
  `,
  emits: ['selected'],
  props: {
    subTitle: {
      type: String,
    },
    // TODO: remove (also below and in backend!)
    // showLoggedTime: {
    //   type: Boolean,
    //   default: true
    // },
    orderForAssignment: {
      type: Object,
    }
  },
  setup(props, { emit, expose }) {
    const { instance } = useMsal()
    const uniqueWorkers = ref([])

    const loadDataAsync = async () => {
      const fetchedWorkers = await listEntitiesAsync(instance, "Analytics/WorkersWithWorkOrders", {})
      const workerMap = new Map();

      fetchedWorkers.forEach(row => {
        if (!workerMap.has(row.ID)) {
          let LoggedMinutes = 0
          let IsWorkOnWeeked = false
          if (row.DayOfTheWeek === 1) {
            if (row.LoggedMinutesYesterday > 0) {
              LoggedMinutes = row.LoggedMinutesYesterday
              IsWorkOnWeeked = true
            } else if (row.LoggedMinutesTwoDaysAgo > 0) {
              LoggedMinutes = row.LoggedMinutesTwoDaysAgo
              IsWorkOnWeeked = true
            } else {
              LoggedMinutes = row.LoggedMinutesThreeDaysAgo
            }
          } else {
            LoggedMinutes = row.LoggedMinutesYesterday
          }

          workerMap.set(row.ID, {
            ID: row.ID,
            FirstName: row.FirstName,
            LastName: row.LastName,
            TransportOfficeHome: row.TransportOfficeHome,
            LoggedMinutes,
            workOrders: [],
            IsWorkOnWeeked
          })
        }
        const workerData = workerMap.get(row.ID)
        workerData.workOrders.push({
          id: row.ID,
          orderId: row.OrderId,
          OrderType: row.OrderType,
          OrderNumber: row.OrderNumber,
          Subject: row.Subject,
          Status: row.Status,
          FacilityName: row.FacilityName,
          IsSpecial: row.IsSpecial
        })
      })

      uniqueWorkers.value = Array.from(workerMap.values())
    }

    onMounted(async () => {
      await loadDataAsync();
    })

    expose({ loadDataAsync })

    return {
      uniqueWorkers
    }
  }
}

export const assignOrderAsync = async (instance, workerId, orderId) => {
  let success = false
  if (!workerId || !orderId) {
    console.error(`WorkerId or OrderId is missing`)
    return success
  }
  startLoading(`changeStatus`)
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/ServiceOrder/${orderId}`, "PATCH", { ownerId: workerId, newStatus: 'Accepted' })
    if (raw?.status !== "ok") {
      throw raw
    }
    if(raw.result) {
      setSuccess(`Naloga dodeljena`)
      success = true
    }
  } catch (e) {
    setError(e)
  }
  stopLoading(`changeStatus`)
  return success
}

/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { usePaging, Paging } from "../components/Paging"
import { setError, startLoading, stopLoading } from "../components/Notification"

import { EMPTY_GUID } from "../utils/constants"
import { SearchMaterial } from 'scripts/components/SearchMaterial'
import { useProjects } from 'scripts/composition-api/useProjects'

const Projects = {
  name: 'Projects',
  components: { SearchMaterial },
  template: `
    <div class="row mb-3">
      <div class="col">
        <button class="btn btn-primary" type="button" @click="$router.push({ name: 'Project', params: { projectId: 'new' } })">Nov projekt</button>
      </div>
    </div>

    <div class="row mb-5 mt-5">
      <div class="col-md-8">
        <div class="row">
          <label class="col-form-label col-sm-3">Status</label>
          <div class="col-sm-9">
            <select class="form-select" v-model="filters.status">
              <option value="Canceled">Preklicano</option>
              <option value="Ordered">Naročeno</option>
              <option value="Active">Aktivni</option>
              <option value="Finalized">Končani</option>
              <option value="Reviewed">Pregledani</option>
              <option value="AccountingDone">Obračunani</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <a class="btn btn-secondary" @click="resetFilters">Pobriši iskalne pogoje</a>
      </div>
    </div>


    <div class="row mb-2 filter-customer-entity">
      <div class="col-md-4">
          <SelectCustomerEntity
            col-class-left="col-sm-3"
            col-class-right="col-sm-9"
            :customerID="filters.customer"
            :facilityID="filters.facility"
            :canChange="true"
            @change="e => { filters.customer = e.customerID;  filters.facility = e.facilityID }"
          />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-8">
        <div class="row">
          <label class="col-form-label col-sm-3">Material</label>
          <div class="col-sm-9">
            <div class="input-group">
              <SearchMaterial @selected="e => filters.materialID = e.id" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-stripped table-sm table-hover">
            <thead>
              <tr>
                <th scope="col" class="bg-light">Stranka</th>
                <th scope="col" class="bg-light">Objekt</th>
                <th scope="col">Polno ime</th>
                <th scope="col">Status</th>
                <th scope="col">Finalized</th>
              </tr>
            </thead>
            <tbody>
              <tr role="button" v-for="project in projects">
                <td @click="selectProject(project, true)" class="bg-light">{{ project.customer }}</td>
                <td @click="selectProject(project, true)" class="bg-light">{{ project.facility }}</td>
                <td @click="selectProject(project)">{{ project.fullName }}</td>
                <td @click="selectProject(project)"><OrderBadge :status="project.statusDisplay" cssClass="d-block" /></td>
                <td @click="selectProject(project)">{{ project.dateFinalized ? $dayjs.utc(project.dateFinalized).local().format($DATE_FORMAT_DISPLAY) : '' }}</td>
              </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>
    <Paging v-if="projects?.length" :paging="paging" @change="setPageNum" />
  `,
  props: {
  },
  setup(props, { emit }) {
    const { instance } = useMsal()

    const { paging, resetPaging,  resetFilters, setPageNum, filters, selectProject, projects } = useProjects()

    watch(
      () => ({ ...filters.value }),
      () => resetPaging() // will also fire getProjectsAsync()
    )

    onMounted(async () => {
      resetFilters()
    })

    return {
      paging,
      filters,
      setPageNum,
      resetPaging,
      resetFilters,
      selectProject,
      projects
    }
  },
}

export default Projects

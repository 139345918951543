import { loginRequest } from "../authConfig"

export async function fetchAuthenticated(instance, url, method, body) {
  if (typeof instance?.acquireTokenSilent !== "function") {
    console.log(instance)
    throw Error("!(instance instanceof msal)")
  }

  const response = await instance.acquireTokenSilent({ ...loginRequest })
    .catch(async (e) => {
      if (e instanceof msal.InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest)
      }
      throw e
    })
  if (response.accessToken) { //if (inProgress.value === msal.InteractionStatus.None) {
    const headers = new Headers()

    const bearer = `Bearer ${response.accessToken}`
    headers.append("Authorization", bearer)

    const options = {
      method,
      headers: headers
    }

    if (body instanceof FormData) {
      options.body = body
    } else if (body) {
      headers.append("Content-Type", "application/json")
      options.body = JSON.stringify(body)
    }

    try {
      const response = await fetch(url, options)
      const contentType = response.headers.get("content-type");
      if (!response.headers || !contentType) {
        console.log(response)
        throw Error(`No headers in response: ${url}`)
      } else if (contentType.includes("application/json")) {
        return await response.json()
      } else if (contentType.includes("text/plain")) {
        return await response.text()
      } else {
        const blob = await response.blob()
        const file = window.URL.createObjectURL(blob)
        window.location.assign(file)
        return true
      }
    } catch (e) {
      e.url = url
      console.log(e)
      throw e
    }

  }
  throw Error(`Wrong interactionStatus: ${inProgress.value} / ${response.accessToken}`)
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onBeforeUnmount } from "vue"
// using https://github.com/PeterBrockfeld/BarcodeParser

function processString(rawValue) {
  return rawValue
    .split("")
    .map((char) => {
      const charCode = char.charCodeAt(0)
      return charCode >= 32 && charCode <= 126 ? char : `\\x${charCode.toString(16).padStart(2, "0")}`
    })
    .join("")
}

const Barcode = {
  name: "Barcode",
  template: `
  <div class="alert alert-danger" role="alert" v-show="!!error">
    {{ error }}
  </div>
  <p v-if="lastRaw"><small class="text-muted">{{ lastRaw }}</small></p>
  <video ref="videoEl" style="width: 100%;" v-show="!error" />
  <div v-if="$window.CONTEXT === 'stage'">
    <button @click="$emit('detected', { ident: '120F0005' })" class="btn btn-info btn-sm">Test 120F0005</button>
    <button @click="$emit('detected',  { ident: '55SA0205', quantity: 1, serialNumbers: '1234' })" class="btn btn-info btn-sm ms-2">Test 55SA0205 s serijsko 1234</button>
    <button @click="$emit('detected',  { ident: '55SA0205', quantity: 1, serialNumbers: '3456' })" class="btn btn-info btn-sm ms-2">Test 55SA0205 s serijsko 5367</button>
    <button @click="$emit('detected',  { ident: '46OC0024', quantity: 1, serialNumbers: '1357' })" class="btn btn-info btn-sm ms-2">Test 46OC0024 s serijsko 1357</button>
    <button @click="$emit('detected',  { ident: '55SA0205', quantity: 1, serialNumbers: '202504000000000123' })" class="btn btn-info btn-sm ms-2">Test 55SA0205 s serijsko 202504000000000123</button>
  </div>

`,
  emits: ["detected"],
  props: {},
  setup(props, { emit }) {
    const videoEl = ref(null)
    const error = ref(null)
    const lastRaw = ref("")
    const testButtons = ref(false)

    let updateTimeInterval = null
    let stream = null

    onBeforeUnmount(() => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop()
        })
      }
      if (updateTimeInterval) {
        clearInterval(updateTimeInterval)
      }
    })

    onMounted(async () => {
      if (typeof navigator.mediaDevices?.getUserMedia !== "function") {
        error.value = "Vaša naprava nima video kamere"
        return
      }
      if (!("BarcodeDetector" in window)) {
        error.value = "Vaša naprava nima podpore za branje črtne kode"
        if (window.CONTEXT === "stage") {
          testButtons.value = true
          error.value += ". Poskusite predefinirane teste:"
        }
        return
      }

      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { ideal: "environment" },
            width: { ideal: 1024 },
            height: { ideal: 768 },
          },
          audio: false,
        })
        videoEl.value.playsInline = true
        videoEl.value.srcObject = stream
        await videoEl.value.play()
        const formatsRaw = BarcodeDetector.getSupportedFormats() // { formats: ['code_128'] }
        const barcodeDetector = new BarcodeDetector(formatsRaw)
        updateTimeInterval = setInterval(async () => {
          const barcodes = await barcodeDetector.detect(videoEl.value)
          barcodes.forEach((barcode) => {
            let rawValue = barcode.rawValue
            if (rawValue.indexOf("\x1d") !== -1) {
              try {
                const answer = parseBarcode(rawValue) //  https://github.com/PeterBrockfeld/BarcodeParser
                const ident = answer.parsedCodeItems.find((item) => item.ai === "240")?.data
                const quantityKg = answer.parsedCodeItems.find((item) => item.ai === "30")?.data
                const quantityUnits = answer.parsedCodeItems.find((item) => item.ai === "37")?.data
                const serialNumbers = answer.parsedCodeItems.find((item) => item.ai === "21")?.data
                // lastRaw.value = "i:" + ident + " d:" + answer.codeName + " s:" + serialNumbers
                const quantity = quantityKg || quantityUnits
                emit("detected", { ident, quantity, serialNumbers })
              } catch (e) {
                lastRaw.value = e
              }
            } else {
              emit("detected", { ident: barcode.rawValue })
              lastRaw.value = "regular."
            }
          })
        }, 300)
      } catch (e) {
        if (e?.message) {
          error.value = e.message
        } else {
          error.value = e
        }
      }
    })

    return { videoEl, error, lastRaw }
  },
}

export default Barcode

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, defineModel } from "vue"

export const MaterialBatchList = {
  template: `
  <div class="row mt-2 p-3" v-if="material?.requiresSerialNumbers">

    <table class="table table-sm">
      <thead>
        <tr>
          <th style="width: 60%;">Serijska</th>
          <th v-if="material.isBatchSerial">Količina po embalaži</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in batchSerialNumbers" :key="index">
          <td>
            <input class="form-control" type="text" v-model="item.serial" />
          </td>
          <td v-if="material.isBatchSerial">
            <div class="input-group">
              <input class="form-control" type="number" v-model="item.quantity" step=".1" />
              <span class="input-group-text" v-show="material.isBatchSerial">{{ material.measurementUnit }}</span>
            </div>
          </td>
        </tr>
        <tr v-if="isBatchSerial.isBatchSerial">
          <td>
            Skupaj:
          </td>
          <td class="fw-bold">
            {{ batchSerialNumbers.reduce((acc, curr) => acc + curr.quantity, 0) }} {{ material.measurementUnit }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
`,
  props: {
    modelValue: Array, // batchSerialNumbers
    modelModifiers: { default: () => ({}) },
    material: { type: Object, required: true },
    isBatchSerial: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const batchSerialNumbers = ref(props.modelValue || [])

    watch(
      () => props.modelValue,
      (newVal) => {
        if (newVal) {
          batchSerialNumbers.value = newVal
        }
      }
    )

    watch(
      batchSerialNumbers,
      (newVal) => {
        emit("update:modelValue", newVal)
      },
      { deep: true, immediate: true }
    )

    return {
      batchSerialNumbers,
    }
  },
}

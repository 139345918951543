import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import InternalOrdersCard from "../components/InternalOrdersCard"
import WorkersCard from "../components/WorkersCard"
import Notifications from "../components/Notifications"
import UniversalCard from "../components/UniversalCard"

export default HomeAdmin = {
  name: 'HomeAdmin',
  components: { InternalOrdersCard, WorkersCard, Notifications, UniversalCard },
  template: `
  <div>

    <div class="row">
      <div class="col-md-3">
        <Notifications />
        <InternalOrdersCard class="card mb-3" title="Interni nalogi" subTitle="Accepted, zame" :ownerID="worker.id.value" statuses="Accepted" />
        <UniversalCard class="card mb-3" title="Delavci s prometom" subTitle="tekoči in prejšnji mesec" url="Analytics/WorkersWithRevenue" />
      </div>
      <div class="col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bližnjice</h5>
            <ul>
              <li>
                <button class="btn btn-link" type="button" @click="$router.push({ name: 'ServiceOrdersMap' })">Zemljevid 🚩 aktivnih servisnih nalogov</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <WorkersCard class="card mb-3"  />
      </div>
    </div>

  </div>
  `,
  setup()  {
    const { worker } = useIsAuthorized()

    return {
      worker
    }
  }
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from './useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { setError, startLoading, stopLoading } from "../components/Notification"
import { listPagedEntitesAsync } from "../api"

import { usePaging } from "../components/Paging"
import { EMPTY_GUID } from "../utils/constants"

export const useWarehouseTransfers = () => {
  const router = VueRouter.useRouter()
  const { instance } = useMsal()
  const { worker } = useIsAuthorized()

  const entities = ref([])

  const extraFilters = ref({
    transferType: "",
    hasMaterial: null
  })

  const DEFAULT_WAREHOUSE_TRANSFER_FILTERS = () => {
    return {
      status: "",
      fromWarehouseID: "",
      toWarehouseID: worker.activeWarehouse.value?.id,
      createdFrom: "",
      createdTo: "",
      serviceOrderID: ""
    }
  }

  const filters = ref(DEFAULT_WAREHOUSE_TRANSFER_FILTERS())

  const loadTransfersAsync = async () => {
    let filtersUrl = ''
    if (extraFilters.value.transferType) {
      filtersUrl += `&transferType=${extraFilters.value.transferType}`
    }
    if (extraFilters.value.hasMaterial) {
      filtersUrl += `&hasMaterial=${extraFilters.value.hasMaterial}`
    }
    if (filters.value.createdFrom) {
      filtersUrl += `&createdFrom=${filters.value.createdFrom}`
    }
    if (filters.value.createdTo) {
      filtersUrl += `&createdTo=${filters.value.createdTo}`
    }
    if (filters.value.fromWarehouseID && filters.value.fromWarehouseID !== EMPTY_GUID) {
      filtersUrl += `&fromWarehouseID=${filters.value.fromWarehouseID}`
    }
    if (filters.value.toWarehouseID && filters.value.toWarehouseID !== EMPTY_GUID) {
      filtersUrl += `&toWarehouseID=${filters.value.toWarehouseID}`
    }
    if (filters.value.serviceOrderID) {
      filtersUrl += `&serviceOrderID=${filters.value.serviceOrderID}`
    }
    const result = await listPagedEntitesAsync(instance, "WarehouseTransfers", paging.value, filters.value.status, filtersUrl)
    setNumberOfRecords(result.numberOfRecords)
    entities.value = result.entities
  }

  const resetTransferFilters = () => {
    let f = DEFAULT_WAREHOUSE_TRANSFER_FILTERS()
    f.status = "New,MovementOrder"
    filters.value = f
    extraFilters.value.transferType = ""
    extraFilters.value.hasMaterial = null
  }

  const selectEntity = (e, openInNewWindow = false) => {
    var routeData = router.resolve({ name: 'WarehouseTransferEdit', params: { id: e.id, type: e.typeText } })
    if (openInNewWindow) {
      window.open(routeData.href, "_blank")
    } else {
      router.push(routeData)
    }
  }

  const { paging, setNumberOfRecords, setPageNum, resetPaging } = usePaging(loadTransfersAsync)

  return {
    paging,
    resetPaging,
    entities,
    filters,
    extraFilters,
    resetTransferFilters,
    setPageNum,
    selectEntity,
    DEFAULT_WAREHOUSE_TRANSFER_FILTERS
  }
}

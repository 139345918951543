import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { searchMaterialsAsync } from '../api'
import SelectMaterial from "./SelectMaterial"

export const SearchMaterial = {
  name: 'SearchMaterial',
  template: `
  <input class="form-control" type="text" v-model="currentSelection" readonly v-show="currentSelection" />
  <Autocomplete v-show="!currentSelection"
    :max="20"
    class="form-control"
    @input="getMaterialsAsync"
    @onSelect="$emit('selected', $event);currentSelection=$event.name"
    :results="materials"
    ></Autocomplete>
    <button class="btn btn-outline-secondary" type="button" @click="currentSelection=''" :disabled="!currentSelection">Spremeni</button>
  `,
  emits: ["selected"],
  setup(props, { emit }) {
    const { instance } = useMsal()

    const materials = ref([])
    const currentSelection= ref(null)

    const getMaterialsAsync = async q => {
      materials.value = await searchMaterialsAsync(instance, "q", q)
    }

    watch(currentSelection, (newValue, oldValue) => {
      if (oldValue !== '' && newValue === '') {
        emit('selected', '')
      }
    })

    return {
      materials,
      SelectMaterial,
      getMaterialsAsync,
      currentSelection
    }
  }
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'

const Special = {
  name: 'Special',
  template: `
  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fw-bold">＋</span>
  `
}

const OrderBadge = {
  name: 'OrderBadge',
  components: { Special },
  template: `
  <span class="badge bg-danger position-relative" :class="cssClass" v-if="statusFiltered === 'Deleted'">{{ status }} <Special v-show="isSpecial" /></span>
  <span class="badge bg-warning text-dark position-relative" :class="cssClass" v-else-if="statusFiltered === 'Active'">{{ status }} <Special v-show="isSpecial" /></span>
  <span class="badge bg-primary position-relative" :class="cssClass" v-else-if="statusFiltered === 'Accepted'">{{ status }} <Special v-show="isSpecial" /></span>
  <span class="badge bg-info text-dark position-relative" :class="cssClass" v-else-if="statusFiltered === 'InProgress'">{{ status }} <Special v-show="isSpecial" /></span>
  <span class="badge bg-success position-relative" :class="cssClass" v-else-if="statusFiltered === 'Completed'">{{ status }} <Special v-show="isSpecial" /></span>
  <span class="badge bg-dark position-relative" :class="cssClass" v-else-if="statusFiltered === 'Finalized'">{{ status }} <Special v-show="isSpecial" /></span>
  <span class="badge bg-secondary position-relative" :class="cssClass" v-else>{{ status }} <Special v-show="isSpecial" /></span>

  <span :class="cssClass"><small>{{ (dateCompleted && !dateCompleted.startsWith('0001')) ? $dayjs(dateCompleted).format('&nbsp; D MMM YY') : '' }}</small></span>
`,
  props: {
    cssClass: String,
    status: String,
    dateCompleted: String,
    isSpecial: Boolean
  },
  setup(props) {
    const statusFiltered = computed(() => props.status?.includes('+') ? props.status.slice(0, -1) : props.status)
    return { statusFiltered }
  }
}

export default OrderBadge

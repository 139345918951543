import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from './useMsal'
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { setError, startLoading, stopLoading } from "../components/Notification"
import { listPagedEntitesAsync } from "../api"

import { usePaging } from "../components/Paging"
import { EMPTY_GUID } from "../utils/constants"

export const useProjects = (defaultFilters) => {
  const { instance } = useMsal()
  const projects = ref([])
  const router = VueRouter.useRouter()

  const selectProject = (project, openInNewWindow = false) => {
    const routeData = router.resolve({ name: 'Project', params: { projectId: project.id } })
    if (openInNewWindow) {
      window.open(routeData.href, "_blank")
    } else {
      router.push(routeData)
    }
  }

  const DEFAULT_FILTERS = () => {
    return {
      status: "Active",
      customer: EMPTY_GUID,
      facility: EMPTY_GUID,
      materialID: EMPTY_GUID
    }
  }

  const loadProjectsAsync = async () => {
    let filter = "&variant=Full"
    if (filters.value.customer && filters.value.customer !== EMPTY_GUID) {
      filter += `&customerId=${filters.value.customer}`
    }
    if (filters.value.facility && filters.value.facility !== EMPTY_GUID) {
      filter += `&facilityId=${filters.value.facility}`
    }
    if (filters.value.materialID && filters.value.materialID !== EMPTY_GUID) {
      filter += `&materialId=${filters.value.materialID}`
    }
    console.log("status", filters.value.status)
    const result = await listPagedEntitesAsync(instance, "Projects", paging.value, filters.value.status, filter)
    setNumberOfRecords(result.numberOfRecords)
    projects.value = result.entities
  }


  const resetFilters = async () => filters.value = DEFAULT_FILTERS()
  const filters = ref(DEFAULT_FILTERS())

  const { paging, setNumberOfRecords, setPageNum, resetPaging } = usePaging(loadProjectsAsync)

  return {
    setPageNum,
    paging,
    resetPaging,
    selectProject,
    filters,
    resetFilters,
    projects
  }
}

// TODO: used only in FacilityEdit.js.. for merging with ProjectsCard.js
export const ProjectsCard = {
  name: 'ProjectsCard',
  template: `<div>
  <div class="card-body">
    <h5 class="card-title">Projekti <small class="text-muted">{{ subTitle }}</small> </h5>
    <ul>
      <li v-for="p in projects" class="mt-1">
        <router-link :to="{ name: 'Project', params: { projectId: p.id } }" target="_blank">{{ p.fullName }}</router-link> <OrderBadge :status="p.statusDisplay" />
      </li>
    </ul>
    <p v-show="projects.length === 0">Ni projektov.</p>
    <p v-show="pagingData.itemsPerPage === projects.length">
      <router-link :to='{ name: "Projects" }'>Več zadetkov</router-link>
    </p>
  </div>
</div>`,
  props: {
    projects: {
      type: Array,
      required: true
    },
    pagingData: {
      type: Object,
      required: true
    },
    subTitle: {
      type: String,
    },
  }
}

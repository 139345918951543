import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { setError, startLoading, stopLoading } from "./Notification"
import { useMsal } from "../composition-api/useMsal"
import { DATETIME_FOR_LOCAL_INPUT, EMPTY_GUID } from "../utils/constants"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import ModalAssignWorkOrder from "../components/ModalAssignWorkOrder"

export default ServiceOrdersCard = {
  name: "ServiceOrdersCard",
  components: { ModalAssignWorkOrder },
  template: `<div>
  <div class="card-body">
    <h5 class="card-title">{{ title }} <small class="text-muted" v-show="subTitle">{{ subTitle }}</small></h5>
    <p v-show="paging.itemsPerPage === orders.length">
      <router-link :to='{ name: "Service", params: { keepMessages: false, facilityID, customerID, ownerID, statuses }}'>Več zadetkov</router-link><br>
      <router-link :to='{ name: "ServiceOrdersMap", params: { facilityID, customerID, ownerID, statuses }}' v-show="showLinkToMap">Več zadetkov na zemljevidu 🚩</router-link>
    </p>
    <ul>
      <li v-for="wo in orders.filter(o => o.id != excludeId)" class="mt-1">
        <router-link :to="{ name: 'ServiceOrder', params: { workOrderId: wo.id } }" class="me-1">{{ wo.orderNumber }}</router-link>

        <span v-if="fireAssignEvent">
          <button class="btn btn-primary btn-sm float-end"
            v-if="wo.status === 'Active' && worker.isAdmin && ownerID && ownerID !== $EMPTY_GUID"
            @click="$emit('assign', wo)">
            Dodeli
          </button>
        </span>
        <span v-else>
          <button class="btn btn-primary btn-sm float-end" v-if="wo.status === 'Active' && worker.isAdmin" @click="orderForAssignment = wo">Dodeli</button>
        </span>
        <span v-show="wo.hadUncompletedCommission" class="badge bg-warning text-dark">Delni komision!</span>
        <OrderBadge :status="wo.status" :isSpecial="wo.isSpecial" />&nbsp;<strong>{{ wo.facilityName }}</strong>&nbsp;
        <br>
        <small class="text-muted">{{ wo.subject }}</small>
      </li>
    </ul>
    <p v-show="orders.length === 0">Ni nalogov.</p>
    <p v-show="paging.itemsPerPage === orders.length">
      <router-link :to='{ name: "Service", params: { keepMessages: false, facilityID, customerID, ownerID, statuses }}'>Več zadetkov</router-link><br>
      <router-link :to='{ name: "ServiceOrdersMap", params: { facilityID, customerID, ownerID, statuses }}' v-show="showLinkToMap">Več zadetkov na zemljevidu 🚩</router-link>
    </p>
  </div>
  <ModalAssignWorkOrder :visible="!!orderForAssignment"
    :orderForAssignment="orderForAssignment"
    @close="orderForAssignment = null"
    @assigned="orderAssigned" />
</div>`,
  emits: ["assigned"],
  props: {
    statuses: {
      type: String,
      default: "",
    },
    ownerID: {
      type: String,
      default: EMPTY_GUID,
    },
    facilityID: {
      type: String,
      default: EMPTY_GUID,
    },
    customerID: {
      type: String,
      default: EMPTY_GUID,
    },
    isSpecial: {},
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    itemsPerPage: {
      type: Number,
      default: 25,
    },
    showLinkToMap: {
      type: Boolean,
      default: true,
    },
    fireAssignEvent: {
      type: Boolean,
      default: false,
    },
    excludeId: {
      type: String,
      default: EMPTY_GUID,
    },
  },
  setup(props, { emit, expose }) {
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()
    const { resetPaging, filters, orders, paging } = useWorkOrders("Service")

    const orderForAssignment = ref(null)

    watch(
      () => [props.itemsPerPage, props.statuses, props.ownerID, props.facilityID, props.customerID, props.isSpecial],
      async () => await loadDataAsync()
    )

    onMounted(async () => await loadDataAsync())

    const orderAssigned = () => {
      orderForAssignment.value = null
      resetPaging()
      emit("assigned")
    }

    const loadDataAsync = async () => {
      paging.value.itemsPerPage = props.itemsPerPage
      filters.value.status = props.statuses
      filters.value.ownerID = props.ownerID
      filters.value.facility = props.facilityID
      filters.value.customer = props.customerID
      filters.value.isSpecial = props.isSpecial
      resetPaging()
    }

    expose({ loadDataAsync })

    return {
      orderAssigned,
      orderForAssignment,
      worker,
      orders,
      paging,
    }
  },
}

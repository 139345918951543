import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { useMsal } from '../composition-api/useMsal'
import { setError, startLoading, stopLoading, setSuccess } from "./Notification"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

Array.prototype.unique = function() {
  var a = this.concat();
  for(var i=0; i<a.length; ++i) {
      for(var j=i+1; j<a.length; ++j) {
          if(a[i] === a[j])
              a.splice(j--, 1)
      }
  }

  return a
}

const BomTemplates = {
  template: `
  <Modal :visible="visible" @close="$emit('close'); template = null" :title="'Izberi material iz predloge [za ' + quantity + ' kos]' ">
    <div class="row" v-show="template === null">
      <div class="col">
        <table class="table table-sm table-hover">
          <thead class="thead-light">
            <tr>
              <th>Naziv</th>
              <th>Aktivna</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bt in bomTemplates" :class="{ 'clickable-row': bt.isActive }" @click="getBomTemplate(bt.id)">
              <td>{{ bt.name }}</td>
              <td>{{ bt.isActive }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="!!template">
      <div class="col">
        <h4>Predloga kosovnice</h4>
        <h3>{{ template?.name }}</h3>
        <div class="row mb-3 mt-4" v-for="s in template.sections">
          <div class="col">
            <h4>{{ s.name }}</h4>
            <table class="table table-sm table-hover">
              <thead class="thead-light">
                <tr>
                  <!-- th class="w-5"><input type="checkbox" @click="toggleSelectInSection($event, s)" :checked="s.selected"></th -->
                  <th class="w-30">Material</th>
                  <th class="w-20">Kataloška številka</th>
                  <th class="w-10">Ident</th>
                  <th class="w-10">Količina</th>
                  <th class="w-10">Enota</th>
                  <th class="w-5">Opozorila</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="m in s.materials">
                  <!-- td><input type="checkbox" v-model="m.selected"></td -->
                  <td>{{ m.name }} <span class="badge badge-info" v-show="m.requiresSerialNumbers">Serijske</span></td>
                  <td>{{ m.catalogNumber }}</td>
                  <td>{{ m.opPisProductID }}</td>
                  <td>
                    <input class="form-control"
                      v-model="m.quantity"
                      pattern="[0-9.]+" type="text" style="width: 70px"
                      />
                      <!-- v-bind:readonly="!m.selected" -->
                  </td>
                  <td>{{ m.measurementUnit }}</td>
                  <td>{{ m.comment }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:buttons>
      <button class="btn btn-outline-primary me-3" type="button" @click="setAllToZero" v-if="!!template">Vse količine na 0</button>
      <button class="btn btn-success btn-lg me-3" type="button" @click="addMaterialsToWorkOrder" v-if="!!template">Dodaj na nalog</button>
    </template>
  </Modal>

`,
  props: {
    quantity: { type: Number, default: 1 },
    visible: { type: Boolean, default: false }
  },
  emits: ['close', 'add'],
  setup(props, { emit }) {
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const bomTemplates = ref([])
    const template = ref(null)

    const getBomTemplates = async () => {
      startLoading('getBomTemplates')
      try {
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/BomTemplates`, "GET")
        if (raw?.status !== "ok") {
          throw raw
        }
        bomTemplates.value = raw.result
      } catch (e) {
        setError(e)
      }
      stopLoading('getBomTemplates')
    }

      /* TODO: remove if they are happy with m.quantity > 0
    watch(template, () => {
      template.value?.sections?.forEach(s => {
        if (s.selected === false && s.materials.every(m => m.selected) === true) {
          s.selected = true
        }
        else if (s.selected === true && s.materials.every(m => m.selected) === false) {
          s.selected = false
        }
      })
    }, { deep: true })*/

    const getBomTemplate = async id => {
      startLoading('getBomTemplate')
      try {
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/BomTemplate/${id}`, "GET")
        if (raw?.status !== "ok") {
          throw raw
        }
        /* TODO: remove if they are happy with m.quantity > 0
          raw.result.sections.forEach(s => {
          s.materials.forEach(m => m.selected = m.quantity > 0 ? true : false )
          s.selected = s.materials.every(m => m.selected)
        })*/
        template.value = raw.result
      } catch (e) {
        setError(e)
      }
      stopLoading('getBomTemplate')
    }

    const setAllToZero = () => {
      template.value.sections?.forEach(s => {
        s.materials.forEach(m => m.quantity = 0)
      })
    }

    const toggleSelectInSection = (e, s) => s.materials.forEach(m => m.selected = e.target.checked)

    const addMaterialsToWorkOrder = () => {
      console.log('addMaterialsToWorkOrder')
      try {
        let result = []
        template.value.sections?.forEach(s => {
          const temp = s.materials.filter(m => m.quantity > 0).map(m => {
            return {
              materialID:  m.id,
              materialName: m.name,
              catalogNumber: m.catalogNumber,
              opPisProductID: m.opPisProductID,
              quantity: m.quantity * props.quantity,
              measurementUnit: m.measurementUnit,
              sectionOrder: s.order,
              sectionName: s.name,
              warehouseID: worker.activeWarehouse.value.id
            }
          })
          result = result.concat(temp).unique()
        })
        if (result.length) {
          emit('add', result)
        }
      } catch(e) {
        setError(e)
      }
      template.value = null
    }

    onMounted(async () => {
      await getBomTemplates()
    })

    return {
      addMaterialsToWorkOrder,
      getBomTemplate,
      bomTemplates,
      toggleSelectInSection,
      template,
      setAllToZero
    }
  }
}

export default BomTemplates

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"

export function useSerialNumbers(material) {
  const batchSerialNumbers = ref([])

  const numberOfSerialNumbers = computed(() => {
    if (material.value?.isBatchSerial) {
      return (
        material.value?.serialNumbers?.split("\n").filter((l) => {
          const parts = l.split(";")
          return parts.length === 2 && parts[0].length > 2 && !isNaN(parseFloat(parts[1]))
        }).length || 0
      )
    } else {
      return material.value?.serialNumbers?.split("\n").filter((l) => l.length > 2).length || 0
    }
  })

  const serialNumbersValid = computed(() => numberOfSerialNumbers.value === parseInt(material.value?.quantity))

  const initializeWatchers = () => {
    watch(
      () => material.value?.quantity,
      (newQuantity) => {
        if (material.value?.requiresSerialNumbers && newQuantity) {
          const serialNumbersArray = material.value.serialNumbers?.split("\n").map((item) => {
            const [serial, quantity] = item.split(";")
            return { serial, quantity: Number(quantity) }
          })
          batchSerialNumbers.value = Array.from({ length: newQuantity }, (_, i) => ({
            serial: serialNumbersArray[i]?.serial || "",
            quantity: serialNumbersArray[i]?.quantity || 1,
          }))
        }
      }
    )

    watch(
      batchSerialNumbers,
      (newBatchSerialNumbers) => {
        material.value.serialNumbers = newBatchSerialNumbers.map((item) => `${item.serial};${item.quantity}`).join("\n")
      },
      { deep: true }
    )
  }

  return { numberOfSerialNumbers, serialNumbersValid, initializeWatchers, batchSerialNumbers }
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess, setWarning } from "../components/Notification"
import { getWorkerTimesAsync } from "../api"
import { DATETIME_FOR_LOCAL_INPUT, EMPTY_GUID  } from "../utils/constants"
import { useEnums } from '../composition-api/useEnums'
import { useWorkOrders } from "../composition-api/useWorkOrders"
import WarehouseTransfersCard from "../components/WarehouseTransfersCard"
import WorkersCard from "../components/WorkersCard"
import ServiceOrdersCard from "../components/ServiceOrdersCard"
import InternalOrdersCard  from '../components/InternalOrdersCard'
import Notifications from "../components/Notifications"

export default HomeWorker = {
  name: 'HomeWorker',
  components: { ServiceOrdersCard, WarehouseTransfersCard, WorkersCard, InternalOrdersCard, Notifications },
  template: `
  <div>
      <div class="row">
        <div class="col-md-4">
          <ServiceOrdersCard class="card mb-3" title="Servisni nalogi" subTitle="Triage,Accepted,InProgress + zame" statuses="Triage,Accepted,InProgress" :ownerID="worker.id.value" />
        </div>
        <div class="col-md-4">
          <Notifications />
          <InternalOrdersCard class="card mb-3" title="Interni nalogi" subTitle="Accepted, zame" :ownerID="worker.id.value" statuses="Accepted" />
        </div>
        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Bližnjice</h5>
              <ul>
                <li>
                  <button class="btn btn-link" type="button" @click="$router.push({ name: 'ServiceOrdersMap' })">Zemljevid 🚩 aktivnih servisnih nalogov</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <WarehouseTransfersCard class="card mb-3" :toWarehouseID="worker.activeWarehouse.value.id" statuses="New,MovementOrder" subTitle="Zame, novi"  />
        </div>
        <div class="col-md-4">
          <WorkersCard class="card mb-3" title="Servisni nalogi in ure" />
        </div>
      </div>

  </div>
  `,
  setup()  {
    const { worker } = useIsAuthorized()

    return {
      worker
    }
  }
}

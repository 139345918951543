import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { setError, startLoading, stopLoading } from "../components/Notification"


import { useEnums } from "../composition-api/useEnums"
import SelectWarehouse from "../components/SelectWarehouse"

import { useWarehouseTransfers } from "../composition-api/useWarehouseTransfers"

const WarehouseTransfers = {
  name: 'WarehouseTransfers',
  components: { SelectWarehouse },
  template: `

  <div class="row mb-3">
    <div class="col">
      <button class="btn btn-primary me-3" type="button" @click="$router.push({ name: 'WarehouseTransferEdit', params: { type: 'BetweenWarehouses', id: 'new' } })">Nova medskladiščnica</button>
      <button class="btn btn-info me-3" type="button" @click="$router.push({ name: 'WarehouseTransferEdit', params: { type: 'FromSupplier', id: 'new' } })">Nova nabava</button>
      <button class="btn btn-outline-info me-3" type="button" @click="showChangeActiveWarehouse = !showChangeActiveWarehouse">Dnevno skladišče</button>
    </div>
    <SelectWarehouse cssClass="col p-3 bg-info rounded" v-show="showChangeActiveWarehouse" />
  </div>

  <div class="row mt-3">
    <div class="col-md-8">

      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <label class="col-form-label col-sm-3">Iz</label>
            <div class="col-sm-9">
              <SelectEntity apiEntity="Warehouses" v-model="filters.fromWarehouseID" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <label class="col-form-label col-sm-3">V</label>
            <div class="col-sm-9">
              <SelectEntity apiEntity="Warehouses" v-model="filters.toWarehouseID" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <DateTimeInput class="col-md-6"
          label-text="Ustvarjen od"
          v-model="filters.createdFrom"  />
        <DateTimeInput class="col-md-6"
          label-text="Ustvarjen do"
          v-model="filters.createdTo"  />
      </div>

      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <label class="col-form-label col-sm-3">Vrsta</label>
            <div class="col-sm-9">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="transferType" id="transferType1" value="BetweenWarehouses"
                  v-model="extraFilters.transferType">
                <label class="form-check-label" for="transferType1">Medskladiščnica</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="transferType" id="transferType2" value="FromSupplier"
                  v-model="extraFilters.transferType">
                <label class="form-check-label" for="transferType2">Nabava</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <label class="col-form-label col-sm-3">Ima material</label>
            <div class="col-sm-9">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="hasMaterial" id="hasMaterial1" value="true"
                  v-model="extraFilters.hasMaterial">
                <label class="form-check-label" for="transferType1">Da</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="hasMaterial" id="hasMaterial2" value="false"
                  v-model="extraFilters.hasMaterial">
                <label class="form-check-label" for="hasMaterial2">Ne</label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-4">
      <SelectStatus :statuses="transferStatuses" v-model="filters.status" @change="changeSelectStatus" :multiple="true" />
      <div class="row mt-2"><div class="col"><a class="btn btn-sm btn-outline-secondary" @click="clearStatuses">Pobriši statuse</a></div>
        <div class="col"><a class="btn btn-sm btn-secondary" @click="resetTransferFilters">Pobriši iskalne pogoje</a></div>
      </div>
    </div>
  </div>


  <div class="row mt-5" v-if="entities?.length"><!-- duplicate -->
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col" class="bg-light">Številka</th>
              <th scope="col" class="bg-light">Datum</th>
              <th scope="col">Iz skladišča</th>
              <th scope="col">V skladišče</th>
              <th scope="col">Komentar</th>
              <th scope="col">Št.mat.</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" v-for="w in entities" :class="{ 'bg-warning': w.noOfMaterials === 0 }">
              <td @click="selectEntity(w, true)" class="bg-light">{{ w.number }}</td>
              <td @click="selectEntity(w, true)" class="bg-light">{{ $dayjs.utc(w.dateCreated).local().format($DATETIME_FORMAT_DISPLAY) }}</td>
              <td @click="selectEntity(w)">{{ w.from?.name }}</td>
              <td @click="selectEntity(w)">{{ w.to?.name }}</td>
              <td @click="selectEntity(w)">{{ w.comment }}</td>
              <td @click="selectEntity(w)">{{ w.noOfMaterials }}</td>
              <td @click="selectEntity(w)"><OrderBadge :status="w.statusText" :dateCompleted="w.dateCompleted" /></td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row justify-content-center" v-else>
    <p>Nobena medskladiščnica ne ustreza izbranim pogojem</p>
  </div>

  <Paging v-if="entities?.length" :paging="paging"  @change="setPageNum" />
`,
  props: {

  },
  setup() {
    const route = VueRouter.useRoute()

    const { instance } = useMsal()

    const { transferStatuses, loadEnumsAsync } = useEnums()
    const {
      paging,
      resetPaging,
      entities,
      filters,
      extraFilters,
      resetTransferFilters,
      setPageNum,
      selectEntity } = useWarehouseTransfers()

    const showChangeActiveWarehouse = ref(false)

    /* TODO de-duplicate */
    const changeSelectStatus = e => {
      let s = filters.value.status.split(",")
      const existingIndex = s.findIndex(el => el === e)
      if (existingIndex > -1) {
        s.splice(existingIndex, 1)
      } else {
        s.push(e)
      }
      filters.value.status = s.toString()
    }

    const clearStatuses = () => {
      filters.value.status = ""
    }

    watch(
      () => ({ ...filters.value, ...extraFilters.value }),
      () => resetPaging() // will also fire loadTransfersAsync()
    )


    onMounted(async () => {
      startLoading('Transfers')
      try {
        await loadEnumsAsync()
        resetTransferFilters()
      } catch (e) {
        setError(e)
      }
      stopLoading('Transfers')
    })

    return {
      filters,
      extraFilters,
      paging,
      entities,
      transferStatuses,
      clearStatuses,
      changeSelectStatus,
      resetTransferFilters,
      selectEntity,
      setPageNum,
      showChangeActiveWarehouse
    }
  }
}

export default WarehouseTransfers

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from './useMsal'
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { setError, setWarning, startLoading, stopLoading, clearNotifications } from "../components/Notification"

import { usePaging } from "../components/Paging"
import { EMPTY_GUID } from "../utils/constants"

export const useWorkOrders = (defaultWorkOrderType, defaultFilters) => {
  const { instance } = useMsal()
  const orders = ref([])
  const router = VueRouter.useRouter()

  const selectOrder = (order, openInNewWindow = false) => {
    const routeData = router.resolve({ name: 'ServiceOrder', params: { workOrderId: order.id } })
    if (openInNewWindow) {
      window.open(routeData.href, "_blank")
    } else {
      router.push(routeData)
    }
  }

  // TODO: should be dependent on current worker
  const DEFAULT_STATUS_FILTERS = () => { return "Active,Accepted,InProgress,Triage,Completed" }

  const DEFAULT_FILTERS = () => {
    const status = defaultFilters?.status ?? DEFAULT_STATUS_FILTERS()
    const ownerID = defaultFilters?.ownerID ?? ""

    return {
      status,
      type: defaultWorkOrderType,
      facility: EMPTY_GUID,
      nearFacilityId: EMPTY_GUID,
      customer: EMPTY_GUID,
      userCreated: "",
      searchTerm: "",
      orderNumber: "",
      referenceNumber: "",
      materialID: "", // only production
      projectID: "", // only production
      ownerID,
      dueDate: "", // only production
      priority: null, // only production
      serialNumber: null,
      createdFrom: "",
      createdTo: "",
      finalizedFrom: "",
      finalizedTo: "",
      isWarranty: "undefined",
      isSpecial: "undefined",
      hadUncompletedCommission: "undefined",
      isLeakageTest: "undefined",
      isService: "undefined",
      isCondenserCleaning: "undefined",
      internalOrderTypes: "", // only internal
    }
  }

  const resetFilters = async () => filters.value = DEFAULT_FILTERS()
  const filters = ref(DEFAULT_FILTERS())

  const downloadAttachmentsAsync = async (attachmentType) => {
    clearNotifications()
    startLoading('getAllOrdersAsync')
    try {
      const raw1 = await fetchAuthenticated(instance, generateWorkOrdersUrl(true), "GET")
      if (!Array.isArray(raw1.result)) {
        throw raw1
      }
      const guids = raw1.result.map(o => o.id)
      console.log(guids)
      const batchSize = 133
      let notFoundCount = 0
      let batches = 0
      for (let i = 0; i < guids.length; i += batchSize) {
        batches++
        const batch = guids.slice(i, i + batchSize);
        try {
          const raw2 = await fetchAuthenticated(instance, `${AZURE_URI}/Attachments/${filters.value.type}?attachmentType=${attachmentType}`, "POST", batch)
          if (raw2.message === "No attachments found.") {
            notFoundCount++
          } else {
            console.log(raw2)
          }

        } catch (e) {
          console.warn(e)
        }
      }
      if (notFoundCount === batches) {
        setWarning({ message: "No attachments found." })
      }
    } catch (e) {
      setError(e)
    }
    stopLoading('getAllOrdersAsync')
  }

  const generateWorkOrdersUrl = (all = false) => {
    var url = `${AZURE_URI}/WorkOrders/${filters.value.type}/${filters.value.status}?1=1`
    if (!all) {
      url += `&pageNum=${paging.value.pageNum}&itemsPerPage=${paging.value.itemsPerPage}`
    } else {
      url += `&pageNum=1&itemsPerPage=65536`
    }
    if (filters.value.customer && filters.value.customer !== EMPTY_GUID) {
      url += `&customerId=${filters.value.customer}`
    }
    if (filters.value.facility && filters.value.facility !== EMPTY_GUID) {
      url += `&facilityId=${filters.value.facility}`
    }
    if (filters.value.nearFacilityId && filters.value.nearFacilityId !== EMPTY_GUID) {
      url += `&nearFacilityId=${filters.value.nearFacilityId}`
    }
    if (filters.value.userCreated) {
      url += `&userCreatedId=${filters.value.userCreated}`
    }
    if (filters.value.searchTerm.trim()) {
      url += `&searchTerm=${filters.value.searchTerm}`
    }

    if (filters.value.referenceNumber.trim()) {
      url += `&referenceNumber=${filters.value.referenceNumber}`
    }
    if (filters.value.createdFrom) {
      url += `&createdFrom=${filters.value.createdFrom}`
    }
    if (filters.value.createdTo) {
      url += `&createdTo=${filters.value.createdTo}`
    }
    if (filters.value.completedFrom) {
      url += `&completedFrom=${filters.value.completedFrom}`
    }
    if (filters.value.completedTo) {
      url += `&completedTo=${filters.value.completedTo}`
    }
    if (filters.value.finalizedFrom) {
      url += `&finalizedFrom=${filters.value.finalizedFrom}`
    }
    if (filters.value.finalizedTo) {
      url += `&finalizedTo=${filters.value.finalizedTo}`
    }
    // new
    if (filters.value.materialID) {
      url += `&materialID=${filters.value.materialID}`
    }
    if (filters.value.projectID) {
      url += `&projectID=${filters.value.projectID}`
    }
    if (filters.value.orderNumber.trim()) {
      url += `&orderNumber=${filters.value.orderNumber}`
    }
    if (filters.value.ownerID) {
      url += `&ownerID=${filters.value.ownerID}`
    }
    if (filters.value.dueDate) {
      url += `&dueDate=${filters.value.dueDate}`
    }
    if (filters.value.priority) {
      url += `&priority=${filters.value.priority}`
    }
    if (filters.value.serialNumber) {
      url += `&serialNumber=${filters.value.serialNumber}`
    }
    if (filters.value.isWarranty && filters.value.isWarranty !== "undefined") {
      url += `&isWarranty=${filters.value.isWarranty}`
    }
    if (filters.value.isSpecial !== "undefined" && typeof filters.value.isSpecial !== 'undefined') {
      url += `&isSpecial=${filters.value.isSpecial}`
    }
    if (filters.value.hadUncompletedCommission !== "undefined" && typeof filters.value.hadUncompletedCommission !== 'undefined') {
      url += `&hadUncompletedCommission=${filters.value.hadUncompletedCommission}`
    }
    if (filters.value.isLeakageTest && filters.value.isLeakageTest !== "undefined") {
      url += `&isLeakageTest=${filters.value.isLeakageTest}`
    }
    if (filters.value.isService && filters.value.isService !== "undefined") {
      url += `&isService=${filters.value.isService}`
    }
    if (filters.value.isCondenserCleaning && filters.value.isCondenserCleaning !== "undefined") {
      url += `&isCondenserCleaning=${filters.value.isCondenserCleaning}`
    }
    if (filters.value.internalOrderTypes && filters.value.internalOrderTypes.length > 3) {
      url += `&internalOrderTypes=${filters.value.internalOrderTypes}`
    }
    return url
  }

  const getOrdersAsync = async () => {
    startLoading('getOrdersAsync')
    try {
      if (!filters.value.status.replace(',', '').trim().length) {
        setNumberOfRecords(0)
        orders.value = []
        stopLoading('getOrdersAsync')
        return
      }
      const url = generateWorkOrdersUrl()
      const raw = await fetchAuthenticated(instance, url, "GET")
      if (!Array.isArray(raw.result)) {
        throw raw
      }
      setNumberOfRecords(raw.numberOfRecords)
      orders.value = raw.result
    } catch (e) {
      setError(e)
      orders.value = []
    }
    stopLoading('getOrdersAsync')
  }

  const { paging, setNumberOfRecords, setPageNum, resetPaging } = usePaging(getOrdersAsync)

  return {
    paging,
    resetPaging,
    orders,
    filters,
    resetFilters,
    getOrdersAsync, // TODO: candidate for removal. we should just watch filters and call getOrdersAsync
    setPageNum,
    DEFAULT_FILTERS,
    DEFAULT_STATUS_FILTERS,
    downloadAttachmentsAsync,
    selectOrder
  }
}

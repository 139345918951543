import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { EMPTY_GUID } from "scripts/utils/constants"

const SelectCustomerEntity = {
  name: 'SelectCustomerEntity',
  template: `
  <div class="row mb-3 select-customer">
    <label class="col-form-label" :class="colClassLeft">Stranka</label>
    <div :class="colClassRight">
      <SelectEntity
        :required="required"
        apiEntity="Customers"
        v-model="customerIDlocal"
        :canChange="canChange" />
    </div>
  </div>
  <div class="row mb-3 select-facility">
    <label class="col-form-label" :class="colClassLeft">Objekt</label>
    <div :class="colClassRight" v-if="customerIDlocal !== $EMPTY_GUID">
      <SelectEntity
        :required="required"
        apiEntity="Facilities"
        v-model="facilityIDlocal"
        :canChange="canChange"
        :filter="customerIDlocal ? ('?customerId=' + customerIDlocal) : ''"
        noResultsMessage="Stranka nima nobenega povezanega objekta." />
    </div>
    <div :class="colClassRight" v-else>
      Najprej izberi stranko
    </div>
  </div>
  `,
  emits: [ "change" ],
  props: {
    required: { type: Boolean, default: false },
    customerID: { type: String },
    facilityID: { type: String },
    canChange: { type: Boolean },
    colClassLeft: { type: String, default: "col-sm-4" },
    colClassRight: { type: String, default: "col-sm-8" },
  },
  setup (props, { emit }) {
    const customerIDlocal = ref(EMPTY_GUID)
    const facilityIDlocal = ref(EMPTY_GUID)

    const customerID = toRef(props, "customerID")
    const facilityID = toRef(props, "facilityID")
    const canChange = toRef(props, "canChange")

    const emitChange = (curr, next) => {
      emit('change', {
          customerID: customerIDlocal.value,
          facilityID: facilityIDlocal.value
      })
    }

    watch(() => customerID.value, (curr, next) => customerIDlocal.value = curr, { immediate: true })
    watch(() => facilityID.value, (curr, next) => facilityIDlocal.value = curr, { immediate: true })
    watch(() => customerIDlocal.value, emitChange)
    watch(() => facilityIDlocal.value, emitChange)

    return { customerIDlocal, facilityIDlocal, canChange }
  },
}

export default SelectCustomerEntity

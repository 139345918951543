import { computed, provide, onUnmounted } from "vue"

import { useMsal } from "./composition-api/useMsal"
import { useIsAuthorized } from "./composition-api/useIsAuthorized"
import { useIsAuthenticated } from "./composition-api/useIsAuthenticated"
import { loginRequest } from "./authConfig"
import SignInOutButton from "./components/SignInOutButton"
import { Notification, setError, setWarning, useNotification } from "./components/Notification"
import Pause from "./components/Pause"
import SelectWarehouse from "./components/SelectWarehouse"

const App = {
  components: { SignInOutButton, Notification, Pause, SelectWarehouse },
  template: `
  <div class="row">
    <div class="col-12">
      <div class="navbar-light border-bottom border-white" :class=" { 'bg-light': $window.CONTEXT === 'production', 'bg-info': $window.CONTEXT === 'stage' } ">
        <div class="container-fluid">
          <div class="row">
            <div class="col-4 col-md-8">
              <nav class="d-print-none navbar navbar-expand-lg">
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-if="isAuthenticated">
                      <li class="nav-item">
                        <router-link :to="{ name: 'Home' }" class="nav-link" active-class="active">Domov</router-link>
                      </li>
                      <li class="nav-item" v-if="isAuthorized && ($hasRole(worker, ['Administrator', 'Accountant']) || $hasRoleEndsWith(worker, ['Projects', 'Service']) || $hasRoleStartsWith(worker, ['Leader']) )">
                        <router-link :to="{ name: 'Service' }" class="nav-link" active-class="active">Servisni</router-link>
                      </li>
                      <li class="nav-item" v-if="isAuthorized && ($hasRole(worker, ['Administrator', 'Accountant']) || $hasRoleEndsWith(worker, ['Projects']) || $hasRoleStartsWith(worker, ['Leader']))">
                        <router-link :to="{  name: 'Projects' }" class="nav-link" active-class="active">Projekti</router-link>
                      </li>
                      <li class="nav-item" v-if="isAuthorized && ($hasRole(worker, ['Administrator', 'Accountant', 'WarehouseManager']) || $hasRoleEndsWith(worker, ['Production']) || $hasRoleStartsWith(worker, ['Leader']))">
                        <router-link :to="{ name: 'Production' }" class="nav-link" active-class="active">Proizvodni</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="{ name: 'WarehouseTransfers' }" class="nav-link" active-class="active">
                          <strong v-if="!worker.activeWarehouse.value?.isPrimary">{{ worker.activeWarehouse.value?.name || "Medskladiščnice" }}</strong><span v-else>{{ worker.activeWarehouse.value?.name  || "Medskladiščnice" }}</span>
                        </router-link>
                      </li>
                      <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown">
                          Šifranti
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                          <li><router-link :to="{ name: 'Workers' }" class="dropdown-item" active-class="active">Delavci/uporabniki</router-link></li>
                          <li><router-link :to="{ name: 'Customers' }" class="dropdown-item" active-class="active">Stranke</router-link></li>
                          <li><router-link :to="{ name: 'Warehouses' }" class="dropdown-item" active-class="active">Skladišča</router-link></li>
                          <li><router-link :to="{ name: 'Facilities' }" class="dropdown-item" active-class="active">Objekti</router-link></li>
                          <li><router-link :to="{ name: 'Devices' }" class="dropdown-item" active-class="active">Naprave</router-link></li>
                          <li><router-link :to="{ name: 'Materials' }" class="dropdown-item" active-class="active">Material</router-link></li>
                          <li><router-link :to="{ name: 'BomTemplates' }" class="dropdown-item" active-class="active">BOM predloge</router-link></li>
                          <li><router-link :to="{ name: 'Commissions' }" class="dropdown-item" active-class="active">Komisioni</router-link></li>
                          <li><router-link :to="{ name: 'ServiceOrdersWithCompletedMovementsCard' }" class="dropdown-item" active-class="active">Premiki materiala</router-link></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
              </nav>
            </div>
            <div class="col-8 col-md-4">
              <div class="d-flex justify-content-end align-items-center mt-2">
                <button v-show="isAuthenticated" class="btn btn-sm btn-info me-2" type="button" @click="$router.push({ name: 'InternalOrder', params: { workOrderId: 'new' } })">Int.nalog</button>
                <Pause v-show="isAuthenticated" class="me-2" />
                <span class="navbar-text d-none d-xl-inline" v-show="!!displayName">{{ displayName }}</span>
                <SignInOutButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Notification />

  <div v-if="!state.errors.value.length && !worker.workPauseStartDateTime.value" class="mt-2">
    <div v-if="isAuthorizationDone && worker.activeWarehouse.value">
      <router-view v-if="isAuthorized" />
    </div>
    <div v-else-if="isAuthorizationDone">
      <SelectWarehouse v-if="isAuthorized" cssClass="row col mt-3 p-3 bg-info rounded" />
      <div v-else-if="isAuthenticated" class="p-5 mb-4 bg-light rounded-3" >
        <div class="container-fluid py-5" v-show="!state.loading.value && !!subject">
          <h1 class="display-5 fw-bold">Nimate dovoljenja za dostop do aplikacije</h1>
          <p class="col-md-8 fs-4">Prosim kontaktirajte administratorja.</p>
          <p class="col-md-8 fs-4">Sporočite mu tole: <strong>{{ subject }}</strong></p>
        </div>
      </div>
      <div v-else class="p-5 mb-4 bg-light rounded-3">
        <div class="container-fluid py-5" v-show="!state.loading.value">
          <h1 class="display-5 fw-bold">Niste prijavljeni</h1>
          <p class="col-md-8 fs-4">Prosim prijavite se s klikom na gumb desno zgoraj.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Preverjanje nivoja dostopa.</p>
    </div>
  </div>

  <div class="row d-print-none" v-if="$window.CONTEXT === 'stage'">
    <div class="d-block d-sm-none">xs</div>
    <div class="d-none d-sm-block d-md-none">sm</div>
    <div class="d-none d-md-block d-lg-none">md</div>
    <div class="d-none d-lg-block d-xl-none">lg</div>
    <div class="d-none d-xl-block">xl</div>
  </div>
  `,
  setup() {
    const { instance, accounts } = useMsal()
    const { isAuthorized, isAuthorizationDone, subject, worker } = useIsAuthorized()
    const isAuthenticated = useIsAuthenticated()
    const { state } = useNotification()

    const displayName = computed(() => {
      if (accounts.value.length > 0) {
        return accounts.value[0].idTokenClaims?.given_name + " " + accounts.value[0].idTokenClaims?.family_name
      }
      return ""
    })

    const msalCallback = instance.addEventCallback((event) => {
      if (event.eventType === msal.EventType.ACQUIRE_TOKEN_FAILURE) {
        console.log("ACQUIRE_TOKEN_FAILURE")
        instance.loginRedirect(loginRequest)
      }
    })

    const getLocationAsync = (options = {}) => {
      return new Promise((resolve, reject) => {
        if (!"geolocation" in window.navigator) {
          console.error("no browser support for geolocation")
          reject("no browser support")
        } else {
          window.navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                accuracy: position.coords.accuracy,
              })
            },
            (e) => {
              console.warn("getCurrentPosition error", e)
              setWarning(e.message)
              resolve(null)
              //reject(e.message)
            },
            options
          )
        }
      })
    }

    provide("getLocationAsync", getLocationAsync)
    provide("hasLocationPermissions", "geolocation" in window.navigator)

    onUnmounted(() => {
      if (msalCallback) {
        instance.removeEventCallback(msalCallback)
      }
    })

    return {
      displayName,
      isAuthorizationDone,
      isAuthenticated,
      isAuthorized,
      state,
      subject,
      worker,
    }
  },
}

export default App

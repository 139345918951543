import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { EMPTY_GUID } from '../utils/constants'
import { setError, startLoading, stopLoading, setSuccess } from "../components/Notification"
import { getEntityAsync, saveWarehouseTransferAsync, saveMaterialsAsync } from "../api"
import SelectMaterial from "../components/SelectMaterial"
import { useAttachments, AttachFromCamera } from '../components/Attachments'
import { hasRole, hasRoleStartsWith, hasRoleEndsWith } from "../utils/roles"

const WarehouseTransferEdit = {
  name: 'WarehouseTransferEdit',
  components: { SelectMaterial, AttachFromCamera },
  template: `
  <div class="row">
    <div class="col-sm-3"><h1>{{ entity?.number }}</h1></div>
    <div class="col-sm-6 d-none d-lg-block">
      <h3 v-show="entity?.typeText === 'BetweenWarehouses'">
        <span v-show="entity?.statusText === 'New'">Nalog za prenos v pripravi</span>
        <span v-show="entity?.statusText === 'MovementOrder'">Nalog za prenos</span>
        <span v-show="entity?.statusText === 'InventoryUpdated'">Medskladiščnica</span>
        <span v-show="entity?.statusText === 'Canceled'">Preklican nalog za prenos</span>
      </h3>
      <h3 v-show="entity?.typeText === 'FromSupplier'">
        <span v-show="entity?.statusText === 'New'">Nalog za prevzem v pripravi</span>
        <span v-show="entity?.statusText === 'MovementOrder'">Nalog za prevzem</span>
        <span v-show="entity?.statusText === 'InventoryUpdated'">Prevzemnica</span>
        <span v-show="entity?.statusText === 'Canceled'">Preklican nalog za prevzem</span>
      </h3>
    </div>
    <div class="col-sm-3"><h2><OrderBadge :status="entity?.statusText" /></h2></div>
  </div>

  <div class="row mb-3" v-if="entity && entity?.typeText === 'BetweenWarehouses'">
    <label class="col-form-label col-sm-4">Iz</label>
    <div class="col-sm-8">
      <SelectEntity apiEntity="Warehouses"
        v-model="entity.fromWarehouseID"
        :limit="1000"
        :canChange="entity.canChange && canChangeMaterials" />
    </div>
  </div>
  <div class="row mb-3" v-if="entity">
    <label class="col-form-label col-sm-4">V</label>
    <div class="col-sm-8">
      <SelectEntity apiEntity="Warehouses"
        v-model="entity.toWarehouseID"
        :limit="1000"
        :canChange="entity.canChange" />
    </div>
  </div>

  <div class="row mb-3" v-if="entity">
    <label class="col-form-label col-sm-4">Opombe</label>
    <div class="col-sm-8">
      <textarea class="form-control" v-model="entity.comment" :disabled="!entity.canChange" rows="3"></textarea>
    </div>
  </div>

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="entity && !isNew">
    <WorkOrderMaterials
      :showSection="false"
      :canChange="entity.canChange && canChangeMaterials"
      work-order-type="WarehouseTransfer"
      :workOrderId="entity.id" />
  </div>

  <SelectMaterial v-if="entity && !isNew"
    :useBarcode="true"
    :noSection="true"
    :noWarehouse="true"
    :visible="showAddMaterialBarcode"
    @close="showAddMaterialBarcode = false"
    @add="asyncAddMaterials" />

  <SelectMaterial v-if="entity && !isNew"
    :visible="showAddMaterial"
    :noSection="true"
    :noWarehouse="true"
    @close="showAddMaterial = false"
    @add="asyncAddMaterials" />

  <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!isNew && entity">
    <div class="row">
      <h4 class="col-2">Priloge</h4>
      <div class="col-10 text-end">
        <button class="btn btn-warning btn-sm ms-1 me-2 mb-2" @click="showUpload = true" v-show="entity.canChange">Naloži datoteko</button>
        <button class="btn btn-secondary btn-sm ms-1 me-2 mb-2" @click="showAttachFromCamera = true" v-show="entity.canChange">Naloži fotografijo</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <Attachments :attachments="attachments" />
      </div>
    </div>
  </div>

  <div class="row mb-5 mt-5" v-if="entity">
    <div class="col-2 text-start">
      <button class="btn btn-danger btn-lg me-3" type="button" v-show="!isNew && entity.canDelete" @click="deleteTransferAsync">Izbriši</button>
    </div>
    <div class="col-10 text-end">
      <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'WarehouseTransfers' })">Prekliči</button>



      <button class="btn btn-outline-success btn-lg me-3" type="button"  @click="saveAndInventoryUpdatedAsync" :disabled="!isValidForComplete">Shrani in potrdi prenos v ciljno skladišče</button>



      <button class="btn btn-success btn-lg me-3" type="button"  @click="saveAsync" :disabled="!entity.canChange">Shrani</button>

      <button class="btn btn-success btn-lg me-3" type="button"  @click="cloneAsync" v-show="entity?.typeText === 'BetweenWarehouses' && entity?.statusText === 'InventoryUpdated'">Kloniraj</button>
    </div>
  </div>

  <ChangeHistory v-model="entity" v-show="!isNew" />


  <Upload :visible="!!showUpload"
    @close="showUpload = false"
    @add="e => { addAttachment(e) }"
    type="WarehouseTransfer"
    :id="entity?.id" />
  <AttachFromCamera :visible="!!showAttachFromCamera"
    @close="showAttachFromCamera = false"
    @add="e => { addAttachment(e) }"
    type="WarehouseTransfer"
    :id="entity?.id" />

  `,
  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { isAdmin, worker } = useIsAuthorized()
    const { instance } = useMsal()
    const { attachments, addAttachment } = useAttachments()

    const entity = ref(null)

    const showAddMaterial = ref(false)
    const showAddMaterialBarcode = ref(false)
    const showUpload = ref(false)
    const showAttachFromCamera = ref(false)

    const isNew = computed(() => route.params.id === "new")

    const canChangeMaterials = computed(() => entity.value?.fromWarehouseID?.toUpperCase() !== 'D19907A1-F9CD-4B59-91FE-6752A6853515')

    const loadWarehouseTransferAsync = async () => {
      if (!route.params.id) {
        entity.value = null
        return
      }
      entity.value = await getEntityAsync(instance, "WarehouseTransfer", route.params.id)
      if (entity.value) {
        attachments.value = entity.value.attachments
      }
      if (!entity.value) {
        setError("Ne obstaja.")
      }
      else if (isNew.value) {
        entity.value.toWarehouseID = worker.activeWarehouse.value.id
        if (route.params.type === "BetweenWarehouses") {
          entity.value.type = 1
          entity.value.typeText = 'BetweenWarehouses'
        }
        else if (route.params.type === "FromSupplier") {
          entity.value.type = 2
          entity.value.typeText = 'FromSupplier'
        } else {
          setError("Data inconsistency.")
        }
      } else if (entity.value?.typeText !== route.params.type) {
        setError("Data inconsistency.")
      }
    }

    const asyncAddMaterials = async m => {
      const materialsToAdd = []
      m.forEach(m => {
        const found = entity.value.materials.find(e => e.materialID === m.materialID)
        if (!found) {
          m.isNew = true
          entity.value.materials.push(m)
          materialsToAdd.push(m)
        } else if (!found.isForDelete) {
          found.quantity += props.quantity
          found.isDirty = true
          materialsToAdd.push(found)
        } else {
          // found.quantity = props.quantity
          found.isDirty = true
          found.isForDelete = false
          materialsToAdd.push(found)
          console.warn("adding deleted material")
        }
      })
      const result = await saveMaterialsAsync(instance, materialsToAdd, "WarehouseTransfer", entity.value.id)
      if (result) {
        setSuccess("Material dodan..", true)
      } else {
        setError("Material ni dodan!")
      }
    }

    const saveAndInventoryUpdatedAsync = async () => {
      entity.value.status = 7 // InventoryUpdated
      if (await saveAsync()) {
        router.push({ name: 'WarehouseTransfers', params: { keepMessages: true } })
      }
    }

    const deleteTransferAsync = async () => {
      entity.value.status = 9 // Deleted
      await saveAsync()
    }

    const cloneAsync = async () => {
      const result = await saveWarehouseTransferAsync(instance, entity.value, true, "?cloneFrom=" + entity.value.id)
      if (result) {
        router.push({ name: 'WarehouseTransferEdit', params: { id: entity.value.id, type: entity.value.typeText, keepMessages: true } })
      }
    }

    const saveAsync = async () => {
      const result = await saveWarehouseTransferAsync(instance, entity.value, isNew.value)
      if (result) {
        if (isNew.value) {
          router.push({ name: 'WarehouseTransferEdit', params: { id: entity.value.id, type: entity.value.typeText, keepMessages: true } })
        } else {
          await loadWarehouseTransferAsync()
        }
      }
      return result
    }

    const isValidForComplete = computed(() => {
      return entity.value.canChange
        && (entity.value?.materials?.length > 0 || entity.value?.comment?.length > 0)
        && (entity.value?.toWarehouseID && entity.value?.toWarehouseID !== EMPTY_GUID)
        && ((entity.value?.fromWarehouseID && entity.value?.fromWarehouseID !== EMPTY_GUID) || entity.value?.typeText === 'FromSupplier' )
        && !(entity.value?.toWarehouseID.toUpperCase() === 'D6493EEB-E41A-4B9D-8308-08D8D1AA2B7F' && hasRoleStartsWith(worker, ['Worker'])   )
    })

    watch(() => route.params.id, () => loadWarehouseTransferAsync())

    onMounted(async () => await loadWarehouseTransferAsync())

    return {
      worker,
      isAdmin,
      isNew,
      isValidForComplete,
      entity,
      attachments,
      showAddMaterial,
      showAddMaterialBarcode,
      saveAndInventoryUpdatedAsync,
      deleteTransferAsync,
      saveAsync,
      cloneAsync,
      asyncAddMaterials,
      showUpload,
      showAttachFromCamera,
      addAttachment,
      canChangeMaterials
    }
  }
}

export default WarehouseTransferEdit

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "../composition-api/useMsal"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess } from "../components/Notification"
import { getEntityAsync } from "../api"

const MaterialEdit = {
  name: "MaterialEdit",
  template: `
  <div v-if="entity">
    <div class="row mt-3 mb-3">
      <label class="col-form-label col-sm-4">Naziv materiala</label>
      <div class="col-sm-8">
        <input class="form-control" v-model="entity.name" :disabled="true" />
      </div>
    </div>
    <div class="row mb-3">
      <label class="col-form-label col-sm-4">Ident</label>
      <div class="col-sm-8">
        <input class="form-control" v-model="entity.opPisProductID" :disabled="true" />
      </div>
    </div>

    <!-- RequiredWarning v-show="!isValid && canChange" / -->

    <div class="row mb-5">
      <div class="col-2 text-start">
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Materials' })">Prekliči</button>
        <!--
        <button  v-show="false" class="btn btn-success btn-lg me-3" type="button" @click="saveEntityAsync" :disabled="!isValid || !canChange">Shrani</button>
        -->
      </div>
    </div>

    <div class="row mt-5" v-if="entity.isActive">
      <div class="col">
        <MaterialWarehouses :materialID="$route.params.id" />
      </div>
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="warehouseTransferFilter" id="wtf1" value="1" v-model="filters.warehouseTransferStatuses">
          <label class="form-check-label" for="priority1">V pripravi</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="warehouseTransferFilter" id="wtf2" value="2,7" v-model="filters.warehouseTransferStatuses">
          <label class="form-check-label" for="priority2">Zaključeni</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="warehouseTransferFilter" id="wtf3" value="9" v-model="filters.warehouseTransferStatuses">
          <label class="form-check-label" for="priority3">Zbrisani</label>
        </div>
        <div class="table-responsive">
          <table class="table table-stripped table-sm">
            <thead>
              <tr>
                <th scope="col">Izvorno skladišče</th>
                <th scope="col">Ciljno skladišče</th>
                <th scope="col">Količina</th>
                <th scope="col">Opomba</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="wm in warehouseTransfers">
                <td>{{ wm.fromWarehouse }}</td>
                <td>{{ wm.toWarehouse }}</td>
                <td>{{ wm.quantity }}</td>
                <td>{{ wm.note }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>`,
  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const { instance } = useMsal()

    const searchTerm = ref("")

    const entity = ref(null)

    const warehouseTransfers = ref([])

    const filters = ref({ warehouseTransferStatuses: "1" })

    const loadMaterialAsync = async () => {
      if (!route.params.id) {
        entity.value = null
        return
      }
      entity.value = await getEntityAsync(instance, "Material", route.params.id)
      warehouseTransfers.value = await getEntityAsync(
        instance,
        "Material",
        route.params.id,
        "/WarehouseTransfers?statuses=" + filters.value.warehouseTransferStatuses
      )
      if (!entity.value) {
        setError("Ne obstaja.")
      }
    }

    watch(
      () => route.params.id,
      () => loadMaterialAsync()
    )

    watch(
      () => ({ ...filters.value }),
      () => loadMaterialAsync()
    )

    onMounted(async () => await loadMaterialAsync())

    return { entity, warehouseTransfers, searchTerm, filters }
  },
}

export default MaterialEdit

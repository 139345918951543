import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listEntitiesAsync } from "../api"
import { useEnums } from '../composition-api/useEnums'

const Workers = {
  name: 'Workers',
  template: `
  <div class="row mb-3">
    <div class="col">
      <button class="btn btn-primary" type="button" @click="$router.push({ name: 'WorkerEdit', params: { workerId: 'new' } })">Nov delavec</button>
    </div>
  </div>

  <div class="row" v-show="['Leader', 'Admin'].some(role => worker.userRoleText.value?.startsWith(role))">
    <div class="col-sm-4">
      <label class="form-label" for="is-active">Prikaži aktivne</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-active" v-model="filters.active"  />
      </div>
    </div>
    <div class="col-sm-4">
      <label class="form-label" for="is-absent">Prikaži odsotne</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-absent" v-model="filters.absent"  />
      </div>
    </div>
  </div>

  <!-- div class="row mb-3" v-show="['Leader', 'Admin'].some(role => worker.userRoleText.value?.startsWith(role))">
    <label class="col-form-label col-sm-4">Vloga uporabnika</label>
    <div class="col-sm-8">
      <select class="form-select" v-model="filters.role">
        <option value="all">Vse vloge</option>
        <option v-for="[key, value] in roles" :value="key">{{ value }}</option>
      </select>
    </div>
  </div -->


  <div class="row" v-if="['Leader', 'Admin'].some(role => worker.userRoleText.value?.startsWith(role)) && filters">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Delavec</th>
              <th scope="col">Vloga</th>
              <th scope="col">Preskus uhajanja</th>
              <th scope="col">Podpis</th>
              <th scope="col">Azure prijava</th>
              <th scope="col">Na pavzi od</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button"
              v-for="w in (flattedWorkers.filter(w => w.isActive === filters.active && w.isAbsent === filters.absent))"
              :key="w.id"
              :class="{ 'bg-warning': w.isAbsent }">

              <td :rowspan="w.rowSpan" v-if="!w.isServiceOrder" @click="select(w)">{{ w.fullName }} <span class="badge bg-primary" v-show="w.isAbsent">Odsoten</span></td>
              <td v-if="!w.isServiceOrder" @click="select(w)">{{ w.userRoleText }}</td>
              <td v-if="!w.isServiceOrder" @click="select(w)">{{ w.canSignLeakageForm ? "DA" : "" }}</td>
              <td v-if="!w.isServiceOrder" @click="select(w)">{{ w.hasBase64Signature ? "DA" : "" }}</td>
              <td v-if="!w.isServiceOrder" @click="select(w)">{{ w.sub?.length === 36 ? "DA" : "" }}</td>
              <td v-if="!w.isServiceOrder" @click="select(w)"><span v-if="!!w.workPauseStartDateTime">{{ $dayjs(w.workPauseStartDateTime).format('ddd HH:mm')  }}</span></td>
              <td v-if="w.isServiceOrder" @click="select(w)" class="smt">{{ w.orderNumber }}</td>
              <td v-if="w.isServiceOrder" @click="select(w)" class="smt">{{ w.status }}</td>
              <td v-if="w.isServiceOrder" @click="select(w)" class="smt">{{ w.facilityName }}</td>
              <td v-if="w.isServiceOrder" @click="select(w)" colspan="2" class="smt">{{ w.subject }}</td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else class="alert alert-warning" role="alert">
    <p>Seznam dostopen le administratorjem</p>
    <hr>
    <p class="mb-0"><a role="button" @click="select({ id: worker.id.value })" class="alert-link">Poglej lastne podatke</a>.</p>
  </div>
`,
  props: {

  },
  setup() {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()

    const { instance } = useMsal()

    const { worker } = useIsAuthorized()

    const workers = ref([])

    const DEFAULT_FILTERS = () => { return { role: 'Worker', active: true, absent: false } }

    const filters = ref(DEFAULT_FILTERS())

    const { roles, loadEnumsAsync } = useEnums()

    const flattedWorkers = computed(() => workers.value.flatMap(w => {
      const result = []

      result.push({
        id: w.id,
        fullName: w.fullName,
        userRoleText: w.userRoleText,
        canSignLeakageForm: w.canSignLeakageForm,
        hasBase64Signature: w.hasBase64Signature,
        sub: w.sub,
        workPauseStartDateTime: w.workPauseStartDateTime,
        isActive: w.isActive,
        isAbsent: w.isAbsent,
        isServiceOrder: false,
        rowSpan: (w.serviceOrders?.length || 0) + 1
      })
      if (w.serviceOrders?.length) {
        result.push(... w.serviceOrders.map(so => { return {
          fullName: w.fullName,
          userRoleText: w.userRoleText,
          canSignLeakageForm: w.canSignLeakageForm,
          hasBase64Signature: w.hasBase64Signature,
          sub: w.sub,
          workPauseStartDateTime: w.workPauseStartDateTime,
          isActive: w.isActive,
          isAbsent: w.isAbsent,
          isServiceOrder: true,
          ... so
        } }))
      }
      return result
    }))

    const select = s => {
      if (s.isServiceOrder)
        router.push({ name: 'ServiceOrder', params: { workOrderId: s.serviceOrderId } })
      else
        router.push({ name: 'WorkerEdit', params: { workerId: s.id } })
    }

    onMounted(async () => {
      await loadEnumsAsync()
      workers.value = await listEntitiesAsync(instance, "Workers", "?variant=Extended")
    })

    return {
      worker,
      workers,
      flattedWorkers,
      select,
      roles,
      filters
    }
  }
}

export default Workers

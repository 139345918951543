import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { EMPTY_GUID, DATETIME_FORMAT, MAX_MINUTES_TIME_ENTRY } from '../utils/constants'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, setSuccess, startLoading, stopLoading } from "./Notification"
import { sortTimeEntries, ensureTimeEntryExtensions } from '../utils/timeEntryExtensions'
import { WorkOrderTimeActions } from './WorkOrderTimeActions'
import { insertNewTimeEntryAsync, getTimeAsync, saveWorkOrderTimesAsync } from '../api'


const WorkOrderTimes = {
  components: { WorkOrderTimeActions },
  template: `
  <div class="row">
    <h4 class="col-2">Delo</h4>
    <div class="col-10 text-end">
      <button class="btn btn-success btn-sm ms-1 me-2"
        @click="newTimeEntryAsync(workOrderType, workOrderId, defaultStartTimeObject, addMinutes)"
        v-show="!openTimeRecord && canChange">Začni uro
        <strong v-if="defaultStartTimeObject && worker.isAdmin.value">{{ defaultStartTimeObject.local().format('HH:mm') }}</strong>
        <span v-show="worker.isAdmin.value"> ⌊{{ addMinutes }}⌋ min</span>
      </button>
      <button class="btn btn-outline-success btn-sm"
        @click="closeTimeEntry"
        v-show="!!openTimeRecord && canChange" >Ustavi uro
          <span v-show="worker.isAdmin.value"> ⌈{{ addMinutes }}⌉ min</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col d-block d-md-none">
      <div class="card"
        v-for="timeEntry in timeEntries.filter(te => te.isForDelete !== true)"
        @click="timeEntryForActions = timeEntry"
        role="button"
        :class="{
          'border-info': timeEntry.isNew,
          'border-light': timeEntry.isDirty,
          'border-warning': !timeEntry.isValid }">
        <div class="card-body">
          <p class="card-text"><DisplayEntity apiEntity="Workers" v-model="timeEntry.workerId" /></p>
          <p class="card-text">
            {{ timeEntry.startDateTimeObject?.local().format($DATE_FORMAT_DISPLAY) }}
            <small>{{ timeEntry.startDateTimeObject?.local().format('HH:mm') }}</small>
            <span v-show="!!timeEntry.endDateTimeObject">⟶
              {{ timeEntry.endDateTimeObject?.local().format('D.M.') }}
              <small>{{ timeEntry.endDateTimeObject?.local().format('HH:mm') }}</small>
            </span>&nbsp;
             <strong class="mb-3"> {{ timeEntry.difference }}</strong>
          </p>
          <p class="card-text">{{ timeEntry.comments }}</p>
        </div>
      </div>
    </div>
    <div class="col d-none d-md-block d-lg-block">
      <div class="table-responsive">
        <table class="table table-sm" v-show="timeEntries.filter(te => te.isForDelete !== true).length">
          <thead>
            <tr>
              <th>Delavec</th>
              <th>Pričetek</th>
              <th>Zaključek</th>
              <th>Trajanje</th>
              <th>Opomba</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="timeEntry in timeEntries.filter(te => te.isForDelete !== true)"
                role="button"
                :class="{
                  'table-active': timeEntry.isNew,
                  'table-info': timeEntry.isDirty,
                  'table-warning': !timeEntry.isValid }"
                  @click="timeEntryForActions = timeEntry">
                <td><DisplayEntity apiEntity="Workers" v-model="timeEntry.workerId" /></td>
                <td>{{ timeEntry.startDateTimeObject?.local().format($DATETIME_FORMAT_DISPLAY) }}</td>
                <td>{{ timeEntry.endDateTimeObject?.local().format('HH:mm') }}</td>
                <td>{{ timeEntry.difference }}</td>
                <td>{{ timeEntry.comments }}</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <WorkOrderTimeActions
  v-model="timeEntryForActions"
  @close="e => { if (e === true) { loadTimeEntriesAsync(workOrderType, workOrderId); }; timeEntryForActions = null }"
  @finished="endDateTime => { $emit('finished', endDateTime) }"
  :canChange="canChange"
  :workOrderType="workOrderType"
  :workOrderId="workOrderId"
  :addMinutes="addMinutes"
  />
`,
  emits: [ "finished" ],
  props: {
    canChange: { type: Boolean },
    workOrderType: { type: String, required: true },
    workOrderId: { type: String },
    defaultStartTime: { type: String },
    addMinutes: { type: Number, default: 0 }
  },
  setup(props, { emit }) {
    const { worker } = useIsAuthorized()
    const { loadTimeEntriesAsync, newTimeEntryAsync, closeTimeEntryAsync, timeEntries } = useTimeEntries()

    const timeEntryForActions = ref(null)

    const defaultStartTimeObject = computed(() => {
      if (props.defaultStartTime)  {
        return dayjs.utc(props.defaultStartTime)
      }
      return null
    })

    const openTimeRecord = computed(() => timeEntries.value.find(t => !t.endDateTimeObject))

    watch(() => props.workOrderId, async newId => {
      if (newId) {
        await loadTimeEntriesAsync(props.workOrderType, props.workOrderId)
      }
    }, { immediate: true })

    const closeTimeEntry = () => {
      closeTimeEntryAsync(openTimeRecord.value, props.workOrderType, props.workOrderId, props.addMinutes)
      .then(te => {
        if (te) {
          emit("finished", te.endDateTime)
        }
      })
    }

    return {
      worker,
      timeEntries,
      timeEntryForActions,
      newTimeEntryAsync,
      openTimeRecord,
      closeTimeEntry,
      loadTimeEntriesAsync,
      defaultStartTimeObject
    }
  }
}

const useTimeEntries = () => {
  const { instance } = useMsal()

  const timeEntries = ref([])

  const loadTimeEntriesAsync = async (workOrderType, workOrderId)  => {
    const result = await getTimeAsync(instance, workOrderType, workOrderId)
    timeEntries.value = sortTimeEntries(result)
    populateTimeExtensions()
  }

  const populateTimeExtensions = () => {
    timeEntries.value = timeEntries.value.map(timeEntry => {
      ensureTimeEntryExtensions(timeEntry)
      return timeEntry
    })
  }

  const floorMinutes = (date, minutes) => {
    if (!minutes) {
      return date
    }
    const m = date.minute()
    const f = m - (m % minutes)
    return date.minute(f)
  }

  const ceilingMinutes = (date, minutes) => {
    if (!minutes) {
      return date
    }
    const m = date.minute()
    const f = m + (minutes - (m % minutes))
    return date.minute(f)
  }


  const newTimeEntryAsync = async (workOrderType, workOrderId, startTimeObject, bottomMinutes = 0) => {
    if (!workOrderType) {
      throw new Error('missing work order type')
    }
    if (!workOrderId) {
      throw new Error('missing workOrderId')
    }
    if (!Number.isInteger(bottomMinutes) || bottomMinutes < 0) {
      throw new Error('missing addMinutes')
    }
    try {
      if (!dayjs.isDayjs(startTimeObject)) {
        startTimeObject = dayjs.utc()
      }
      await insertNewTimeEntryAsync(instance, workOrderType, workOrderId, floorMinutes(startTimeObject, bottomMinutes))
      await loadTimeEntriesAsync(workOrderType, workOrderId)
      setSuccess("Delo začeto.", true /* skip scroll */)
    } catch (err) {
      setError(err)
    }
  }

  const closeTimeEntryAsync = async (te, workOrderType, workOrderId, topMinutes = 0)  => {
    if (!te) {
      throw new Error("trying to close null time entry")
    }
    if (!!te.endDateTime) {
      throw new Error("timeEntry alread closed")
    }
    if (!workOrderType) {
      throw new Error('missing work order type')
    }
    if (!workOrderId) {
      throw new Error('missing workOrderId')
    }
    if (!Number.isInteger(topMinutes) || topMinutes < 0) {
      throw new Error('missing addMinutes')
    }
    const now = dayjs().utc()
      .year(te.startDateTimeObject.year())
      .month(te.startDateTimeObject.month())
      .date(te.startDateTimeObject.date())
    console.log(now)
    te.endDateTime = ceilingMinutes(now, topMinutes).format()
    te.isDirty = true
    try {
      const result = await saveWorkOrderTimesAsync(instance, te, workOrderType, workOrderId)
      if (result) {
        await loadTimeEntriesAsync(workOrderType, workOrderId)
        setSuccess("Delo zaključeno.", true /* skip scroll */)
        te.isDirty = false
        return te
      } else {
        setError("Ni spremembe")
      }
    } catch (err) {
      setError(err)
    }
    return null
  }

  const openTimeRecord = computed(() => timeEntries.value.find(t => !t.endDateTimeObject))

  const removeTimeEntry = te => te.isForDelete = true

  return {
    closeTimeEntryAsync,
    newTimeEntryAsync,
    loadTimeEntriesAsync,
    openTimeRecord,
    populateTimeExtensions,
    removeTimeEntry,
    timeEntries
  }
}

export { WorkOrderTimes, useTimeEntries }

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useEnums } from '../composition-api/useEnums'

const DeviceList = {
  name: 'DeviceList',
  template: `
  <div class="table-responsive">
    <table class="table table-stripped table-sm table-hover" v-if="devices?.length">
        <thead>
        <tr>
          <th scope="col" class="bg-light" style="width: 25%">Oznaka - nalepka</th>
          <th scope="col" v-if="showService">Potrebne storitve</th>
          <th scope="col">Šifra</th>
          <th scope="col" v-if="showFacility">Objekt</th>
          <th scope="col">Dobavitelj</th>
          <th scope="col" v-if="showSelect">V delu</th>
        </tr>
        </thead>
        <tbody>
        <tr role="button" v-for="w in devices">
          <td @click="selectDevice(w.id, true)" class="bg-light">{{ w.label }}</td>
          <td v-if="showService">
            <div v-if="showSelect">
              <div class="form-check" v-show="w.isLeakageTestOverdue || (showAllServices && w.hasLeakageTest && w.lastLeakageTest !== '0001-01-01') || w.isLeakageTest">
                <input class="form-check-input" type="checkbox" id="isLeakageTestOverduec" v-model="w.isLeakageTest" @change="if (w.isLeakageTest) { w.isRelated = true; } ">
                <label class="form-check-label" for="isLeakageTestOverduec"><span class="badge rounded-pill bg-success">Preverjanje uhajanja</span></label>
              </div>
              <div class="form-check" v-show="w.isServiceOverdue || (showAllServices && w.hasRegularService && w.lastService !== '0001-01-01') || w.isService">
                <input class="form-check-input" type="checkbox" id="isServiceOverduec" v-model="w.isService" @change="if (w.isService) { w.isRelated = true; } ">
                <label class="form-check-label" for="isServiceOverduec"><span class="badge rounded-pill bg-primary">Servis</span></label>
              </div>
              <div class="form-check" v-show="w.isCondenserCleaningOverdue || (showAllServices && (w.hasCondenserCleaningLong || w.hasCondenserCleaningShort)  && w.lastCondenserCleaning !== '0001-01-01') || w.isCondenserCleaning">
                <input class="form-check-input" type="checkbox" id="isCondenserCleaningOverduec" v-model="w.isCondenserCleaning" @change="if (w.isCondenserCleaning) { w.isRelated = true; } ">
                <label class="form-check-label" for="isCondenserCleaningOverduec"><span class="badge rounded-pill bg-light text-dark">Čiščenje kondenzatorja</span></label>
              </div>
              <div class="form-check" v-show="w.isCO2SensorOverdue || (showAllServices && w.hasCO2Sensor && w.lastCO2Sensor !== '0001-01-01') || w.isCO2Sensor">
                <input class="form-check-input" type="checkbox" id="isCO2Sensorc" v-model="w.isCO2Sensor" @change="if (w.isCO2Sensor) { w.isRelated = true; } ">
                <label class="form-check-label" for="isCO2Sensorc"><span class="badge rounded-pill bg-info">Senzor CO2</span></label>
              </div>
              <div class="form-check" v-show="w.isThermometerCalibrationOverdue || (showAllServices && w.hasThermometerCalibration && w.lastThermometerCalibration !== '0001-01-01') || w.isThermometerCalibration">
                <input class="form-check-input" type="checkbox" id="isThermometerCalibrationc" v-model="w.isThermometerCalibration" @change="if (w.isThermometerCalibration) { w.isRelated = true; } ">
                <label class="form-check-label" for="isThermometerCalibrationc"><span class="badge rounded-pill bg-secondary">Umerjanje termometra</span></label>
                <button class="btn btn-sm btn-outline-secondary ms-2" v-show="w.isRelated && serviceOrderId"
                  @click="const rt = $router.resolve({ name: 'CalibrationReport', params: { workOrderId: serviceOrderId, deviceId: w.id } }); $window.open(rt.href, '_blank');">
                  Uredi
                </button>
              </div>
              <span class="badge rounded-pill bg-danger" v-show="w.isOverdue"><marquee>Periodika</marquee></span>
            </div>
            <span v-else>x
              <span class="badge rounded-pill bg-success me-2" v-show="w.isLeakageTestOverdue">Preverjanje uhajanja</span>
              <span class="badge rounded-pill bg-primary me-2" v-show="w.isServiceOverdue">Servis</span>
              <span class="badge rounded-pill bg-primary me-2" v-show="w.isCondenserCleaningOverdue">Čiščenje kondenzatorja</span>
              <span class="badge rounded-pill bg-primary me-2" v-show="w.isCO2SensorOverdue">Senzor CO2</span>
              <span class="badge rounded-pill bg-primary me-2" v-show="w.isThermometerCalibrationOverdue">Umerjanje termometra</span>
            </span>
          </td>
          <td @click="selectDevice(w.id)">{{ w.deviceNumber }}</td>
          <td @click="selectDevice(w.id)" v-if="showFacility">{{ w.facilityName }}</td>
          <td @click="selectDevice(w.id)">{{ w.supplier }}<span class="badge rounded-pill bg-danger" v-show="w.isWarranty"><marquee>Garancija</marquee></span></td>
          <td  v-if="showSelect"><input type="checkbox" v-model="w.isRelated" :disabled="w.isLeakageTest || w.isServiceTest || w.isCondenserCleaning || w.isCO2Sensor || w.isThermometerCalibration"></td>
        </tr>
        </tbody>
    </table>
  </div>
  `,
  props: {
    serviceOrderId: { type: String, required: false }, // for opening calibration report
    devices: { type: Array, required: true },
    showSelect: { type: Boolean, default: false },
    showService: { type: Boolean, default: false },
    showFacility: { type: Boolean, default: true },
    showAllServices: { type: Boolean, default: false },
  },
  setup() {
    const router = VueRouter.useRouter()
    const { loadEnumsAsync } = useEnums()
    const selectDevice = (deviceId, openInNewWindow = false) => {
      let routeData = router.resolve({ name: 'DeviceEdit', params: { deviceId } })
      if (openInNewWindow) {
        window.open(routeData.href, "_blank")
      } else {
        router.push(routeData)
      }
    }

    onMounted(async () => {
      await loadEnumsAsync()
    })

    return {
      selectDevice
    }
  }
}

export { DeviceList }

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
const SelectEnum = {
  template: `
  <div>
    <label class="col-form-label col-sm-4">{{ label }}</label>
    <div class="col-sm-8">
      <select
        :class="{ 'form-select': true, 'is-invalid': required && valueNotInOptions }"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :disabled="disabled"
        :required="required">
          <option value="" v-if="required"></option>
          <option value="all" v-if="showAll">Vse vrste</option>
          <option v-for="[key, value] in types" :value="key" v-show="value">{{ value }}</option>
      </select>
    </div>
  </div>
`,
  props: {
    required: { type: Boolean, default: false },
    label: { type: String, default: "Izberi enum" },
    disabled: Boolean,
    modelValue: String,
    types: Map,
    showAll: { type: Boolean, default: true }
  },
  emits: ['update:modelValue'],
  setup(props) {
    const valueNotInOptions = computed(() => {
      return !props.types.has(props.modelValue)
    })

    return { valueNotInOptions }
  }
}

export default SelectEnum

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listEntitiesAsync } from "../api"

const Facilities = {
  name: 'Facilities',
  template: `
  <div class="row">
    <div class="col-sm-4">
      <label class="form-label" for="is-active">Prikaži aktivne</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-active" v-model="displayActive"  />
      </div>
    </div>
    <div class="col">
      <button class="btn btn-primary" type="button" @click="$router.push({ name: 'FacilityEdit', params: { facilityId: 'new' } })">Nov objekt</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Objekt</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" v-for="w in (facilities.filter(w => w.isActive === displayActive))" @click="selectFacility(w.id)">
              <td>{{ w.name }}</td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>
`,
  props: {

  },
  setup() {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()

    const displayActive = ref(true)

    const { instance } = useMsal()

    const { worker } = useIsAuthorized()

    const facilities = ref([])

    const selectFacility = facilityId => router.push({ name: 'FacilityEdit', params: { facilityId } })

    onMounted(async () => facilities.value = await listEntitiesAsync(instance, "Facilities", "?variant=Full"))

    return {
      worker,
      facilities,
      displayActive,
      selectFacility
    }
  }
}

export default Facilities

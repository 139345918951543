import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useWorkOrder } from '../composition-api/useWorkOrder'
import { useMsal } from '../composition-api/useMsal'
import { DATETIME_FORMAT, EMPTY_GUID } from '../utils/constants'
import { saveLeakageCheckFormAsync, getLeakageCheckFormAsync, getEntityAsync } from '../api'

const LeakageCheckForm = {
  template: `
  <div class="row" v-if="workOrder">
    <div class="col-sm-3"><h1>{{ workOrder.orderNumber }}</h1></div>
    <div class="col-sm-6 d-none d-lg-block"><h3>Preskus tesnosti</h3></div>
    <div class="col-sm-3"><h2><OrderBadge :status="workOrder.statusText" /></h2></div>
  </div>

  <div class="row mb-3 mt-3" v-if="workOrder">
    <div class="col-md-8">

      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Datum preskusa</label>
        <div class="col-sm-8">
          <input class="form-control" type="datetime-local" v-model="leakageCheckForm.leakageCheckDateForInput" :disabled="!isNew">
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Podpisano</label>
        <div class="col-sm-8">
          <input class="form-control" type="date" v-model="leakageCheckForm.dateSignedForInput" />
        </div>
      </div>
      <div class="row  mb-3">
        <label class="col-form-label col-sm-4">Naprava</label>
        <div class="col-sm-8">
          <div class="form-check" v-for="(d, i) in devices.filter(d => d.isLeakageTest && d.isRelated)">
            <input class="form-check-input" type="radio" name="flexRadioDefault" :id="'dev' + i" v-model="leakageCheckForm.deviceID" :value="d.id">
            <label class="form-check-label" :for="'dev' + i">
              {{ d.deviceNumber }} {{ d.label }}
            </label>
          </div>
          <div class="alert alert-warning" role="alert" v-show="devices.filter(d => d.hasLeakageTest && d.isRelated)?.length < 1">
            Na napravah iz servisnega naloga ni nikjer označen preskus testnosti.
          </div>
        </div>
      </div>
      <div class="row  mb-3">
        <label class="col-form-label col-sm-4">Količina dopolnjenega hladiva</label>
        <div class="col-sm-2">
          <input class="form-control" type="number" v-model="leakageCheckForm.toppedUpCoolantQuantity" :disabled="!isNew"  />
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Izvedeni dodatni posegi za zagotovitev tesnosti sistema</label>
        <div class="col-sm-8">
          <textarea class="form-control" v-model.lazy="leakageCheckForm.additionalInterventions" :disabled="!isNew"></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Pripombe/predlogi za nadaljne posege v opremo</label>
        <div class="col-sm-8">
          <textarea class="form-control" v-model.lazy="leakageCheckForm.comments" :disabled="!isNew"></textarea>
        </div>
      </div>

    </div>
    <div class="col-md-4">

      <div class="card mb-3" v-if="device">
        <div class="card-body">
        <!-- possible duplicate -->
          <h5 class="card-title">Podatki o vzdrževanju opreme</h5>
          <p class="card-text">Šifra: {{ device.deviceNumber }}</p>
          <p class="card-text">Nalepka: {{ device.label }}</p>
          <p class="card-text">Vrsta hladiva: {{ device.gasDisplay }}</p>
          <p class="card-text">Proizvajalec kompresorjev: {{ device.compressorManufacturer }}</p>
          <p class="card-text">Število kompresorjev: {{ device.compressorsCount }}</p>
          <p class="card-text" v-show="device.lastLeakageTestObject">Preverjanje uhajanja: {{ device.lastLeakageTestObject?.format($DATE_FORMAT_DISPLAY) }}</p>
          <p class="card-text">Objekt:  {{ device.facility?.name }} </p>
        </div>
      </div>
      <div class="row mb-3">
          <label class="col-form-label col-sm-8" for="is-soap">Milnica</label>
          <div class="col-sm-4">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="is-soap" v-model="leakageCheckForm.usedSoap" :disabled="!isNew" />
            </div>
          </div>
      </div>
      <div class="row mb-3">
          <label class="col-form-label col-sm-8" for="is-electrical">Električni detektor</label>
          <div class="col-sm-4">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="is-electrical" v-model="leakageCheckForm.usedElectricalDetector" :disabled="!isNew" />
            </div>
          </div>
      </div>
      <div class="row mb-3">
          <label class="col-form-label col-sm-8" for="is-nitrogen">Dušik</label>
          <div class="col-sm-4">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="is-nitrogen" v-model="leakageCheckForm.usedNitrogen" :disabled="!isNew" />
            </div>
          </div>
      </div>

    </div>
  </div>

  <RequiredWarning v-show="!isValid && isNew" />

  <div class="row mb-5">
      <div class="col-2">
        <button class="btn btn-danger btn-lg me-3" type="button" v-show="false">Izbriši</button>
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: workOrder.id }  })">Prekliči</button>
        <button class="btn btn-success btn-lg me-3" type="button" :disabled="!isValid || !isNew" @click="saveAsync" v-show="isNew">Shrani</button>
    </div>
  </div>
`,
  setup(props) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()

    const leakageCheckForm = ref({})
    const isNew = computed(() => route.params.leakageCheckFormId === "new")
    const { devices, workOrder } = useWorkOrder("Service")

    const saveAndOpenPpfAsync = async () => {
      /*
      TODO
      const saved = await saveLeakageCheckFormAsync(instance, leakageCheckForm.value)
      if (saved) {
        window.location.href = `${AZURE_URI}/Generated/Leakage/${workOrder.value.id}?leakageFormId=${leakageCheckForm.value.id}`
      }*/
    }

    const saveAsync = async () => {
      const saved = await saveLeakageCheckFormAsync(instance, leakageCheckForm.value)
      if (saved && isNew.value) {
        router.push({ name: `ServiceOrder`, params: { workOrderId: workOrder.value.id, keepMessages: true } })
      }
    }

    const device = ref(null)

    watch(() => leakageCheckForm.value?.deviceID, async (curr, next) => {
      if (!leakageCheckForm.value?.deviceID || leakageCheckForm.value.deviceID === EMPTY_GUID) {
        device.value = null
        return
      }
      device.value = await getEntityAsync(instance, "Device", leakageCheckForm.value.deviceID)
    })

    const isValid = computed(() => leakageCheckForm.value.deviceID && leakageCheckForm.value.deviceID !== EMPTY_GUID)

    watch(() => route.params.leakageCheckFormId, async newId => {
      if (newId) {
        leakageCheckForm.value = await getLeakageCheckFormAsync(instance, newId, isNew.value, route.params.workOrderId)
      }
    })

    onMounted(async () => {
      leakageCheckForm.value = await getLeakageCheckFormAsync(instance, route.params.leakageCheckFormId, isNew.value, route.params.workOrderId)
    })

    return {
      isNew,
      isValid,
      saveAsync,
      saveAndOpenPpfAsync,
      device,
      devices,
      workOrder,
      leakageCheckForm
    }
  }
}

export default LeakageCheckForm

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { WorkersWithWorkOrdersCard, assignOrderAsync } from "../components/WorkersWithWorkOrdersCard"
import { setError, setSuccess, startLoading, stopLoading } from "../components/Notification"

export default ModalAssignWorkOrder = {
  name: 'ModalAssignWorkOrder',
  components: { WorkersWithWorkOrdersCard },
  template: `
  <Modal :title="'Dodeli nalog'" @close="$emit('close')" >
     <div class="row">
      <div class="col-lg-3">
        <h6>{{ orderForAssignment?.orderNumber }} <small class="text-muted">{{ orderForAssignment?.subject }}</small></h6>
        <h4>{{ orderForAssignment?.facilityName }}</h4>
      </div>
      <div class="col-lg-9">
        <h6>Servisni nalogi v bližini <small class="text-muted">{{ subTitle }}</small></h6>
        <ul>
          <li v-for="so in orders" class="mb-1">
            <input type="checkbox" class="form-check-input me-1" v-model="so.selected" :disabled="so.id === orderForAssignment.id" />
            <OrderBadge :status="so.status" :isSpecial="so.isSpecial" />
            <strong class="me-1 mb-1">{{ so.facilityName }}</strong>
            <small class="text-muted me-1">{{ so.subject }}</small>
            <router-link  :to="{ name: 'ServiceOrder', params: { workOrderId: so.id } }" target="_blank" class="me-1">{{ so.orderNumber }}</router-link>
          </li>
        </ul>

      </div>
     </div>
     <hr/>
    <WorkersWithWorkOrdersCard title=""
    v-if="!!orderForAssignment"
    :showLoggedTime="false"
    :orderForAssignment="orderForAssignment"
    @selected="assignOrders" />
  </Modal>
  `,
  emits: ['close', 'assigned'],
  props: {
    orderForAssignment: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const { filters, orders, getOrdersAsync, resetPaging, paging } = useWorkOrders("Service")
    const subTitle = ref("")

    watch(
      () => props.orderForAssignment,
      async () =>  {
        startLoading('ModalAssignWorkOrder')
        try {
          if (props.orderForAssignment === null) {
            orders.value = []
          } else {
            paging.value.itemsPerPage = 30
            filters.value.status = "Triage,Active"
            filters.value.nearFacilityId = props.orderForAssignment.facilityId
            filters.value.isSpecial = props.orderForAssignment.isSpecial
            subTitle.value = filters.value.status + "; 5km; "
            subTitle.value += props.orderForAssignment.isSpecial ? "s +" : "brez +"
            await getOrdersAsync()
            const currentOrder = orders.value.find(o => o.id === props.orderForAssignment.id)
            if (currentOrder) {
              currentOrder.selected = true
            }
          }
        } catch (e) {
          setError(e)
        }
        stopLoading('ModalAssignWorkOrder')
      }
    )

    const assignOrders = async (workerId) => {
      let success = 0
      const selectedOrders = orders.value.filter(o => o.selected)
      await Promise.all(selectedOrders.map(async o => {
        const result = await assignOrderAsync(instance, workerId, o.id)
        if(result) {
          success++
        }
      }))
      if (success > 0) {
        orders.value = []
        emit('close')
        emit('assigned')
        setSuccess('Nalogi dodeljeni. ' + success + ' nalogov.')
      } else {
        console.log('No orders assigned.')
      }
    }

    return {
      assignOrders,
      subTitle,
      orders
    }
  }
}

/* global VueRouter */
import { createApp } from "vue"

import { msalInstance } from "./authConfig.js"
import { msalPlugin } from "./plugins/msalPlugin"
import {
  TIME_FORMAT_DISPLAY,
  DATE_FORMAT_DISPLAY,
  DATETIME_FORMAT,
  DATETIME_FORMAT_DISPLAY,
  EMPTY_GUID,
  SA_WORKER_ID,
} from "./utils/constants"
import { CustomNavigationClient } from "./router/NavigationClient"
import router from "./router/router"

/* Global imports */
import OrderBadge from "./components/OrderBadge"
import SelectOrderType from "./components/SelectOrderType"
import SelectStatus from "./components/SelectStatus"
import { Paging } from "./components/Paging"
import { Attachments, Upload } from "./components/Attachments"
import App from "./App.js"
import Modal from "./components/Modal"
import { SelectEntity, DisplayEntity } from "./components/SelectEntity"
import { WorkOrderTimes } from "./components/WorkOrderTimes"
import { WorkOrderMaterials } from "./components/WorkOrderMaterials"
import { WorkOrderTransports } from "./components/WorkOrderTransports"
import SelectCustomerEntity from "./components/SelectCustomerEntity"
import IntegerRadioGroup from "./components/IntegerRadioGroup"
import ChangeHistory from "./components/ChangeHistory"
import DateTimeInput from "./components/DatetimeInput"
import DateTimeFilterSet from "./components/DatetimeFilterSet"
import SelectEnum from "./components/SelectEnum"
import MaterialWarehouses from "./components/MaterialWarehouses"
import { hasRole, hasRoleStartsWith, hasRoleEndsWith } from "./utils/roles"

const hasStatus = (workOrder, statuses) => {
  if (!Array.isArray(statuses)) {
    console.error("statuses should be an array hasStatus")
    return false
  }
  return statuses.some((s) => workOrder?.statusText?.startsWith(s))
}

const RequiredWarning = {
  name: "RequiredWarning",
  template: `
  <div class="alert alert-warning" role="alert">
    Izpolnite obvezne podatke!
  </div>
`,
}

window.addEventListener("load", () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/js/registerServiceWorker.js")
  }
})

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router)
msalInstance.setNavigationClient(navigationClient)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === msal.EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload // AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

const app = createApp(App)

app.component("OrderBadge", OrderBadge)
app.component("SelectOrderType", SelectOrderType)
app.component("SelectStatus", SelectStatus)
app.component("Paging", Paging)
app.component("Attachments", Attachments)
app.component("Upload", Upload)
app.component("Autocomplete", window["vue3-autocomplete"])
app.component("Modal", Modal)
app.component("SelectEntity", SelectEntity)
app.component("DisplayEntity", DisplayEntity)
app.component("WorkOrderTimes", WorkOrderTimes)
app.component("WorkOrderTransports", WorkOrderTransports)
app.component("SelectCustomerEntity", SelectCustomerEntity)
app.component("RequiredWarning", RequiredWarning)
app.component("WorkOrderMaterials", WorkOrderMaterials)
app.component("ChangeHistory", ChangeHistory)
app.component("DateTimeInput", DateTimeInput)
app.component("DateTimeFilterSet", DateTimeFilterSet)
app.component("SelectEnum", SelectEnum)
app.component("MaterialWarehouses", MaterialWarehouses)
app.component("IntegerRadioGroup", IntegerRadioGroup)

app.config.globalProperties.$filters = {
  encode128(value) {
    const _encoder = new Code128Generator()
    return _encoder.encode(value)
  },
}

app.config.globalProperties.$hasRole = hasRole
app.config.globalProperties.$hasRoleStartsWith = hasRoleStartsWith
app.config.globalProperties.$hasRoleEndsWith = hasRoleEndsWith
app.config.globalProperties.$hasStatus = hasStatus

app.config.globalProperties.$window = window
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$log = console.log
app.config.globalProperties.$DATETIME_FORMAT = DATETIME_FORMAT
app.config.globalProperties.$EMPTY_GUID = EMPTY_GUID
app.config.globalProperties.$SA_WORKER_ID = SA_WORKER_ID
app.config.globalProperties.$AZURE_URI = AZURE_URI
app.config.globalProperties.$DATETIME_FORMAT_DISPLAY = DATETIME_FORMAT_DISPLAY
app.config.globalProperties.$DATE_FORMAT_DISPLAY = DATE_FORMAT_DISPLAY
app.config.globalProperties.$TIME_FORMAT_DISPLAY = TIME_FORMAT_DISPLAY

app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("marquee")

app.use(router)
app.use(msalPlugin, msalInstance)

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app")
})

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from "./useMsal"

export function useIsAuthenticated() {
    const { accounts } = useMsal()
    const isAuthenticated = ref(accounts.value.length > 0)

    watch(accounts, () => {
      isAuthenticated.value = accounts.value.length > 0
    })

    return isAuthenticated
}

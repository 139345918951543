import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess, setWarning } from "../components/Notification"
import { getWorkerTimesAsync } from "../api"
import { DATETIME_FOR_LOCAL_INPUT, EMPTY_GUID  } from "../utils/constants"
import { useEnums } from '../composition-api/useEnums'
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import ServiceOrdersCard from "../components/ServiceOrdersCard"
import WarehouseTransfersCard from "../components/WarehouseTransfersCard"

const WorkerEdit = {
  name: 'WorkerEdit',
  components: { ServiceOrdersCard, WarehouseTransfersCard },
  template: `
  <div  v-if="!!editingWorker">
    <div class="row">
      <div class="col-md-8"><h1>Delavec {{ editingWorker.fullName }}</h1></div>
      <div class="col-md-4">{{ editingWorker.userRoleText }}</div>
    </div>

    <div class="row mb-3 mt-3">
      <div class="col-md-8">

        <div class="row mb-3" v-if="!editingWorker.isNew">
          <label class="form-check-label col-sm-4" for="is-absent">Odsoten</label>
          <div class="col-sm-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="is-absent" v-model="editingWorker.isAbsent" disabled="true" />

              <button class="btn btn-sm btn-success" type="button" v-show="!editingWorker.isAbsent && !isNew" @click="changeWorkerAbsenceAsync" :disabled="!canChange || !worker.isAdmin.value">Spremeni v odsoten</button>
              <button class="btn btn-sm btn-outline-success" type="button" v-show="editingWorker.isAbsent && !isNew" @click="changeWorkerAbsenceAsync" :disabled="!canChange || !worker.isAdmin.value">Spremeni v prisoten</button>

            </div>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Ime</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="editingWorker.firstName" :disabled="!canChange || !worker.isAdmin.value" required />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Priimek</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="editingWorker.lastName" :disabled="!canChange || !worker.isAdmin.value" required />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Telefon</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="editingWorker.phone" :disabled="!canChange || !worker.isAdmin.value" />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Email</label>
          <div class="col-sm-8">
            <input class="form-control" type="email" v-model="editingWorker.email" :disabled="!canChange || !worker.isAdmin.value" />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Št. certifikata</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="editingWorker.certificationNumber" :disabled="!canChange || !worker.isAdmin.value" />
          </div>
        </div>

        <div class="row mb-3" v-if="!!editingWorker?.userRoleText">
          <label class="col-form-label col-sm-4">Vloga</label>
          <div class="col-sm-8">
            <SelectEntity
              :required="true"
              apiEntity="Roles"
              v-model="editingWorker.userRoleText"
              :canChange="(canChange && worker.isAdmin.value) || worker.id.value === $SA_WORKER_ID" />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Partner</label>
          <div class="col-sm-8">
            <SelectEntity
              :required="true"
              apiEntity="Partners"
              v-model="editingWorker.partnerID"
              :canChange="canChange && worker.isAdmin.value" />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Podpisnik za preskus tesnosti</label>
          <div class="col-sm-8">
            <SelectEntity
              apiEntity="Workers"
              v-model="editingWorker.leakageFormSignerID"
              :canChange="canChange && worker.isAdmin.value" />
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Azure ID uporabnika</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" ref="azure"
              v-model="editingWorker.sub"
              :disabled="!canChange || !worker.isAdmin.value"
              :placeholder="$EMPTY_GUID"
              pattern="[0-9a-fA-F\\-]{36}" />
          </div>
        </div>

        <div class="row mb-3" v-if="!editingWorker.isNew">
          <label class="form-check-label col-sm-4" for="is-active">Aktiven (ni aktiven pomeni zbrisan)</label>
          <div class="col-sm-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="is-active" v-model="editingWorker.isActive" :disabled="!canChange || !worker.isAdmin.value" />
            </div>
          </div>
        </div>

        <div class="row" v-show="!editingWorker.isNew">
          <div class="col">
            <input type="file" @change="chooseFile" v-show="canChange" />
          </div>
          <div class="col shadow-lg p-3 mb-5 bg-white rounded" v-show="hasImage">
            <img ref="imageEl" />
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <div class="card mb-3" v-if="!editingWorker.isNew && !editWarehouses">
          <div class="card-body">
            <h5 class="card-title">Skladišča <button class="btn btn-sm btn-outline-info" @click="editWarehouses = true">Urejaj</button></h5>
            <ul>
              <li v-for="w in editingWorker.warehouses" class="mt-1">
                <strong v-show="w.isPrimary === true">{{ w.name }}</strong>
                <span v-show="w.isPrimary === false">{{ w.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="card mb-3" v-if="!editingWorker.isNew && editWarehouses">
          <div class="card-body">
            <h5 class="card-title">Primarno skladišče</h5>
            <SelectEntity
              :required="true"
              apiEntity="Warehouses"
              v-model="editingWorker.primaryWarehouseId"
              @selected="changePrimaryWarehouse"
              :canChange="canChange && worker.isAdmin.value" />
            <h5 class="card-title">Sekundarna skladišča</h5>
            <ul>
              <li v-for="w in editingWorker.warehouses.filter(w => w.isPrimary === false)" class="mt-1">
                {{ w.name }}
                <button class="btn btn-sm btn-outline-danger" @click="editingWorker.warehouses.splice(editingWorker.warehouses.indexOf(w), 1)">Odstrani</button>
              </li>
            </ul>

            <p class="card-text">Za dodajanje moraš skladišče najprej izbrati in nato klikniti "Dodaj".</p>
            <SelectEntity
            apiEntity="Warehouses"
            v-model="warehouseToAddId"
            @selected="e => { $log(e); warehouseToAdd = { id: e.id, name: e.text, isPrimary: false }; }"
            :canChange="canChange && worker.isAdmin.value" />
            <button class="btn btn-sm btn-primary"
              type="button"
              :disabled="!warehouseToAdd || editingWorker.warehouses.some(w => w.id === warehouseToAdd.id)"
              @click="editingWorker.warehouses.push({ ... warehouseToAdd }); warehouseToAdd = null; warehouseToAddId = '';">Dodaj</button>
          </div>
        </div>
        <ServiceOrdersCard class="card mb-3" v-if="!editingWorker.isNew" title="Delovni nalogi" subTitle="Prevzeti ali v delu" :ownerID="editingWorker.id" statuses="Active,Accepted,InProgress" :itemsPerPage=500  />
        <WarehouseTransfersCard class="card mb-3" v-if="!editingWorker.isNew" titleText="Primarno skladišče"
          statuses='New,MovementOrder'
          :toWarehouseID="editingWorker.primaryWarehouseId"
          :itemsPerPage=500
          />
      </div>
    </div>

    <RequiredWarning v-show="!isValid && canChange" />

    <div class="row mb-5">
      <div class="col-2 text-start">
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Workers' })">Prekliči</button>
        <button class="btn btn-success btn-lg me-3" type="button"  @click="saveWorkerAsync" :disabled="(!canChange || !worker.isAdmin.value || !isValid) && !$SA_WORKER_ID">Shrani</button>
      </div>
    </div>

    <div v-if="!editingWorker.isNew">
      <div class="mt-2 mb-3 p-3 bg-light rounded">
        <div class="row mb-3">
          <DateTimeInput class="col-md-3"
            label-text="Od"
            v-model="from"  />
          <DateTimeInput class="col-md-3"
            label-text="Do"
            v-model="to"  />
          <div class="form-check col-md-4">
            <input class="form-check-input" type="checkbox" v-model="includeFromToWork" id="fromToWork">
            <label class="form-check-label" for="fromToWork">Tudi prevoz na/iz dela</label>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" type="button" @click="loadReportTimesTransportsAsync">Prikaži</button>
          </div>
        </div>
      </div>

      <p v-if="totalDurationMin">
        <span v-show="totalDurationMin.shown > 1">Vse prikazane: <span class="badge bg-primary" >{{ totalDurationHrs.shown }} ur</span>. </span>
        <span v-show="totalDurationMin.service > 1">Na delu: <span class="badge bg-success">{{ totalDurationHrs.service }} ur</span>. </span>
        <span v-show="totalDurationMin.transport > 1">Na poti: <span class="badge bg-secondary">{{ totalDurationHrs.transport }} ur</span>. </span>
        <span v-show="totalDurationMin.meeting > 1">Sestanek: <span class="badge bg-info text-dark">{{ totalDurationHrs.meeting }} ur</span>. </span>
        <span v-show="totalDurationMin.shoppingWarehouseOffice > 1">Nabava in skladišče: <span class="badge bg-warning text-dark">{{ totalDurationHrs.shoppingWarehouseOffice }} ur</span>. </span>
      </p>

      <div class="row">
        <div class="col">
          <div class="table-responsive">
            <table class="table table-stripped table-sm table-hover">
              <thead>
                <tr>
                  <th scope="col">Začetek</th>
                  <th scope="col">Konec</th>
                  <th scope="col">Trajanje</th>
                  <th scope="col">Tip / vrsta</th>
                  <th scope="col">Stranka, objekt</th>
                  <th scope="col">Od (ali km)</th>
                  <th scope="col">Do (do ali relacija)</th>
                  <th scope="col">Vozilo</th>
                  <th scope="col">Komentar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tr, index) in editingWorkerTimes"
                  :class="{
                    'day-row-separator': $dayjs.utc(tr.startDateTime).format('D.M.') !==  $dayjs.utc(editingWorkerTimes[index + 1]?.startDateTime).format('D.M.'),
                    'bg-danger-light': index > 0 && $dayjs.utc(tr.startDateTime).add(2, 'minutes').isBefore($dayjs.utc(editingWorkerTimes[index - 1]?.endDateTime))
                  }">
                  <td>{{ $dayjs.utc(tr.startDateTime).local().format('D.M. HH:mm') }}</td>
                  <td>{{ $dayjs.utc(tr.endDateTime).local().format('HH:mm') }}</td>
                  <td>{{ tr.durationMin > 0 ? (tr.durationMin + " min") : "" }}</td>
                  <td>
                    <span v-show="tr.type === 'Transport'">
                      <a @click="selectOrder(tr)" role="button" style="text-decoration: underline">Prevoz</a>
                    </span>
                    <span v-show="tr.type === 'Pause'">Pavza</span>

                    <span v-show="tr.type === 'Work' && tr.orderType !== 'Internal'">
                        <button class="btn btn-sm btn-success" @click="selectOrder(tr)">{{ types.get(tr.orderType) }} nalog</button>
                    </span>
                    <span v-show="tr.type === 'Work' && tr.orderType === 'Internal'">
                      <a @click="selectOrder(tr)" role="button"  style="text-decoration: underline">{{ internalOrderTypes.get(tr.internalType) }}</a>
                    </span>
                    {{ tr.internalType }}
                  </td>
                  <td>{{ tr.serviceCustomer }}<br v-show="tr.serviceFacility?.length > 0">{{ tr.serviceFacility }}</td>
                  <td>
                    <span v-show="tr.startLat">
                      <a class="me-2" :href="'http://www.google.com/maps/place/' + tr.startLat + ',' + tr.startLng" target="_blank">Začetek</a>
                    </span>
                    <span v-show="tr.personalKilometers > 0">
                      {{ tr.personalKilometers }} km
                    </span>
                  </td>
                  <td>
                    <span v-if="tr.endLat">
                      <a class="me-2" :href="'http://www.google.com/maps/place/' + tr.endLat + ',' + tr.endLng" target="_blank">Konec</a>
                    </span>
                    <span v-else>
                      {{ tr.relation }}
                    </span>
                  </td>
                  <td>
                    {{ tr.vehicle }}
                    <br v-show="tr.isPassenger === true">
                    {{ tr.isPassenger === true ? "Sovoznik" : "" }}
                  </td>
                  <td>
                    {{ tr.comments }}
                    {{ tr.internalComments }}
                    {{ tr.internalSubject }}
                    <span class="badge bg-warning text-dark" v-show="tr.internalType === 'WarehouseOrOffice' && tr.durationMin > 10">&gt;10min</span>
                  </td>
                </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
  `,
  props: {
    width: {
      type: Number,
      default: 640
    },
    height: {
      type: Number,
      default: 400
    }
  },
  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { isAdmin, worker } = useIsAuthorized()
    const { instance } = useMsal()
    const { internalOrderTypes, types, loadEnumsAsync } = useEnums()

    const imageEl = ref(null)
    const hasImage = ref(false)
    const hasNewImage = ref(false)

    const editWarehouses = ref(false)

    const editingWorker = ref()
    const editingWorkerTimes = ref([])
    const warehouseToAdd = ref()
    const warehouseToAddId = ref("")

    const from = ref(dayjs.utc().startOf("month").subtract(1, 'month').format(DATETIME_FOR_LOCAL_INPUT))
    const to = ref(dayjs.utc().endOf("month").subtract(1, 'month').format(DATETIME_FOR_LOCAL_INPUT))
    const includeFromToWork = ref(false)

    const selectOrder = (reportRow) => {
      let routeData = null
      if (reportRow.productionOrderID) {
        routeData = router.resolve({ name: 'ProductionOrder', params: { workOrderId: reportRow.productionOrderID } })
      } else if (reportRow.serviceOrderID) {
        routeData = router.resolve({ name: 'ServiceOrder', params: { workOrderId: reportRow.serviceOrderID } })
      } else if (reportRow.internalOrderID) {
        routeData = router.resolve({ name: 'InternalOrder', params: { workOrderId: reportRow.internalOrderID } })
      } else {
        return
      }
      window.open(routeData.href, "_blank")
    }

    const getWorkerAsync = async id => {
      if (!id) return null
      startLoading('getWorkerAsync')
      try {
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Worker/${id}`, "GET")
        if (raw?.status !== "ok") {
          throw raw
        }
        if (!raw.result) {
          throw raw
        }
        editingWorker.value = raw.result
        editingWorker.value.isNew = (id === 'new')
        if (editingWorker.value.isNew) {
          stopLoading('getWorkerAsync')
          return
        }

        const base64 = await fetchAuthenticated(instance, `${AZURE_URI}/WorkerSignature/${id}`, "GET")
        if (typeof base64 === "string" && base64.startsWith("data:image")) {
          imageEl.value.src = base64
          hasImage.value = true
        } else {
          imageEl.value.src = null
          hasImage.value = false
        }
      } catch (e) {
        setError(e)
      }
      stopLoading('getWorkerAsync')
    }

    const saveWorkerAsync = async (patch = false) => {
      startLoading('saveWorkerAsync')
      try {
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Workers`, patch === true ? "PATCH" : (isNew.value ? "POST" : "PUT"), editingWorker.value)
        if (raw?.status !== "ok") {
          throw raw
        }

        if (editingWorker.value.isNew) {
          console.log("new worker saved", raw.result)
          stopLoading('saveWorkerAsync')
          setSuccess("Shranjeno.")
          router.push({ name: `WorkerEdit`, params: { workerId: editingWorker.value.id, keepMessages: true } })
          return
        }
        console.log("worker saved", raw.result)
        if (hasNewImage.value) {
          console.log("saving image")
          const raw1 = await fetchAuthenticated(instance, `${AZURE_URI}/WorkerSignature/${route.params.workerId}`, "PUT", imageEl.value.src)
          if (raw1?.status !== "ok") {
            setWarning("Slika ni bila shranjena.")
          }
        }
        setSuccess("Shranjeno. " + raw.message)
        await getWorkerAsync(route.params.workerId)
      } catch (e) {
        setError(e)
      }
      editWarehouses.value = false
      stopLoading('saveWorkerAsync')
    }

    const changeWorkerAbsenceAsync = async () => {
      startLoading('saveWorkerAsync')
      editingWorker.value.isAbsent = !editingWorker.value.isAbsent
      await saveWorkerAsync(true) // PATCH
    }

    const chooseFile = e => {
      const file = e.target.files[0]
      if (file?.type !== "image/jpeg") {
        console.log(file?.type)
        setError("Datoteka mora biti tipa jpg.")
      } else {
        const reader = new FileReader()
        reader.onload = e => {
          // editingWorker.value.signatureImageBase64 = e.target.result
          imageEl.value.src = e.target.result
          hasImage.value = e.target.result?.length > 5
          hasNewImage.value = hasImage.value
        }
        reader.readAsDataURL(file)
      }
    }

    const totalDurationMin = ref({ shown: 0, service: 0, transport: 0, meeting: 0, shoppingWarehouseOffice: 0 })

    const totalDurationHrs = computed(() => {
      return {
        shown: Math.ceil(totalDurationMin.value.shown / 60),
        service: Math.ceil(totalDurationMin.value.service / 60),
        transport: Math.ceil(totalDurationMin.value.transport / 60),
        meeting: Math.ceil(totalDurationMin.value.meeting / 60),
        shoppingWarehouseOffice: Math.ceil(totalDurationMin.value.shoppingWarehouseOffice / 60)
      }
    })

    const canChange = computed(() => isAdmin.value)

    const isNew = computed(() => route.params.workerId === "new")

    const isValid = computed(() =>
      (editingWorker.value?.sub?.length === 36)
      && editingWorker.value?.firstName?.length > 0
      && editingWorker.value?.lastName?.length > 0
      && ((editingWorker.value?.primaryWarehouseId?.length === 36 && editingWorker.value?.primaryWarehouseId !== EMPTY_GUID) || editingWorker.value?.isNew || editingWorker.value?.isAbsent || !editingWorker.value?.isActive)
      && editingWorker.value?.partnerID?.length === 36
    )

    const changePrimaryWarehouse = (e) => {
      editingWorker.value.warehouses.forEach(wh => wh.isPrimary = false)
      const existingWarehouse = editingWorker.value.warehouses.find(w => w.id === e.id)
      if (existingWarehouse) {
        existingWarehouse.isPrimary = true
      } else {
        editingWorker.value.warehouses.push({ id: e.id, name: e.text, isPrimary: true })
      }
    }

    const loadWorkerAsync = async () => {
      await getWorkerAsync(route.params.workerId)
      await loadReportTimesTransportsAsync()
    }

    const loadReportTimesTransportsAsync = async () => {
      if (editingWorker.value?.isNew) return

      if (!!from.value && !!to.value) {
        editingWorkerTimes.value = await getWorkerTimesAsync(instance, route.params.workerId, from.value, to.value, includeFromToWork.value)
      } else {
        editingWorkerTimes.value = []
      }
      totalDurationMin.value.shown = editingWorkerTimes.value
        .reduce((acc, o) => acc + o.durationMin, 0)
      totalDurationMin.value.service = editingWorkerTimes.value
        .filter(r => r.type === 'Work' && r.orderType !== 'Internal')
        .reduce((acc, o) => acc + o.durationMin, 0)
      totalDurationMin.value.transport = editingWorkerTimes.value
        .filter(r => r.type === 'Transport')
        .reduce((acc, o) => acc + o.durationMin, 0)
      totalDurationMin.value.meeting = editingWorkerTimes.value
        .filter(r => r.internalType === "Meeting")
        .reduce((acc, o) => acc + o.durationMin, 0)
      totalDurationMin.value.shoppingWarehouseOffice = editingWorkerTimes.value
        .filter(r => r.internalType === "MaterialShopping" || r.internalType === "WarehouseOrOffice")
        .reduce((acc, o) => acc + o.durationMin, 0)
    }

    watch(() => route.params.workerId, () => loadWorkerAsync())

    onMounted(async () => {
      await loadEnumsAsync()
      await loadWorkerAsync()
    })

    return {
      from,
      to,
      imageEl,
      hasImage,
      isNew,
      canChange,
      chooseFile,
      isValid,
      editingWorker,
      editingWorkerTimes,
      saveWorkerAsync,
      internalOrderTypes,
      types,
      selectOrder,
      includeFromToWork,
      totalDurationMin,
      totalDurationHrs,
      worker,
      warehouseToAdd,
      warehouseToAddId,
      loadReportTimesTransportsAsync,
      changeWorkerAbsenceAsync,
      editWarehouses,
      changePrimaryWarehouse
    }
  }
}

export default WorkerEdit

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { AttachFromCamera } from 'scripts/components/Attachments'

const ImageInput = {
  name: "ImageInput",
  components: { AttachFromCamera },
  template: `
  <div class="row g-1">
    <div class="col-sm-8">
      <img :src="modelValue" v-if="modelValue.length > 0" style="width: 100%" />
      <canvas ref="canvasEl" style="display: none" />
    </div>
    <div class="col-sm-4 text-end">
      <button class="btn btn-outline-danger btn-sm mb-1 me-2" @click="$emit('update:modelValue', '')" :disabled="disabled" v-if="modelValue.length > 0">Izbriši datoteko</button>
      <button class="btn btn-warning btn-sm mb-1 me-2" @click="showUpload = true" :disabled="disabled" v-if="modelValue.length <= 0">Naloži datoteko</button>
      <button class="btn btn-secondary btn-sm mb-1 me-2" @click="showAttachFromCamera = true" :disabled="disabled" v-if="modelValue.length <= 0">Naloži fotografijo</button>
    </div>

    <Upload :visible="showUpload"
      :allowedType="allowedType"
      type="Standalone"
      @close="showUpload = false"
      @add="addFile"
      />
    <AttachFromCamera :visible="showAttachFromCamera"
      @close="showAttachFromCamera = false"
      @add="addFile"
      type="Standalone"
     />
  </div>
  `,
  emits: ['update:modelValue'],
  props: {
    allowedType: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    modelValue: { type: String },
  },
  setup (props, { emit }) {
    const canvasEl = ref(null)
    const showUpload = ref(false)
    const showAttachFromCamera = ref(false)

    const readAsDataURL = file => {
      return new Promise((resolve, reject)=>{
        let fileReader = new FileReader()
        fileReader.onload = () =>
           resolve({data:fileReader.result, name:file.name, size: file.size, type: file.type})
        fileReader.readAsDataURL(file)
      })
    }

    const writeImageToCanvasAsync = async (canvas, base64Image) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          const MAX_WIDTH = 800
          const MAX_HEIGHT = 800
          var width = img.width
          var height = img.height
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve()
        }
        img.src = base64Image
      })
    }

    const addFile = async f => {
      console.log(f)
      var imageUrl = await readAsDataURL(f)
      await writeImageToCanvasAsync(canvasEl.value, imageUrl.data)
      const dataurl = canvasEl.value.toDataURL("image/jpg", 0.8)

      emit('update:modelValue', dataurl)
      showUpload.value = false
    }

    return {
      addFile,
      canvasEl,
      showUpload,
      showAttachFromCamera
    }
  }
}

export default ImageInput

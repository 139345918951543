import {
  setError,
  startLoading,
  stopLoading,
  setSuccess,
  clearNotifications,
  setWarning,
} from "./components/Notification"
import { fetchAuthenticated } from "./utils/fetchAuthenticated"
import { DATETIME_FORMAT, EMPTY_GUID, DATE_FORMAT } from "./utils/constants"
import { sortTimeEntries, ensureTimeEntryExtensions, ensureTransportEntryExtensions } from "./utils/timeEntryExtensions"

const listEntitiesAsync = async (instance, apiEntity, filter) => {
  let result = null
  if (!apiEntity || typeof apiEntity !== "string") {
    console.error("apiEntity should be string")
    return result
  }
  startLoading(`listEntitiesAsync ${apiEntity}${filter}`)
  try {
    let url = `${AZURE_URI}/${apiEntity}`
    if (filter?.length && filter.startsWith("?")) {
      url += filter
    }
    const raw = await fetchAuthenticated(instance, url, "GET")
    if (!Array.isArray(raw.result)) {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading(`listEntitiesAsync ${apiEntity}${filter}`)
  return result
}

const listPagedEntitesAsync = async (instance, apiEntity, paging, statuses, filter) => {
  let result = {
    entities: [],
    numberOfRecords: 0,
  }
  if (!apiEntity || typeof apiEntity !== "string") {
    console.error("apiEntity should be string")
    return result
  }
  if (typeof statuses !== "string" || statuses.length < 1) {
    console.warn("no statuses")
    return result
  }
  if (typeof filter !== "string") {
    console.error("filter should be string")
    return result
  }
  if (!paging.pageNum || !paging.itemsPerPage) {
    console.error("paging missing required.")
    return result
  }
  startLoading(`listPagedEntitesAsync ${apiEntity} ${statuses} ${filter}`)
  try {
    let url = `${AZURE_URI}/${apiEntity}`
    if (statuses?.length) {
      url += "/" + statuses
    }
    url += `?pageNum=${paging.pageNum}&itemsPerPage=${paging.itemsPerPage}`
    url += filter
    const raw = await fetchAuthenticated(instance, url, "GET")
    if (!raw.itemsPerPage) {
      throw raw
    }
    result.entities = raw.result
    result.numberOfRecords = raw.numberOfRecords
  } catch (e) {
    setError(e)
  }
  stopLoading(`listPagedEntitesAsync ${apiEntity} ${statuses} ${filter}`)
  return result
}

const getEntityAsync = async (instance, apiEntity, id, extraParams = "") => {
  let result = null
  if (!apiEntity || typeof apiEntity !== "string") {
    console.error("apiEntity should be string")
    return result
  }
  if (id?.length !== 36 && id !== "new") {
    console.error("id should be GUID")
    return result
  }
  startLoading(`getEntityAsync ${apiEntity}`)
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/${apiEntity}/${id}${extraParams}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading(`getEntityAsync ${apiEntity}`)
  return result
}

const getWorkOrderAsync = async (instance, workOrderType, workOrderId) => {
  let result = null
  if (workOrderId?.length !== 36 && workOrderId !== "new") {
    console.warn("workOrderId should be GUID, assuming clear workOrder")
    return result
  }
  if (workOrderType?.length < 5) {
    console.error("workOrderType missing")
    return result
  }
  startLoading("getWorkOrderAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/WorkOrder/${workOrderType}/${workOrderId}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    raw.result.dueDate = raw.result.dueDate ? dayjs(raw.result.dueDate).format("YYYY-MM-DD") : null
    if (raw.result.dateSubmitted) {
      Object.defineProperty(raw.result, "dateSubmittedObject", {
        get: function () {
          const d = dayjs.utc(this.dateSubmitted)
          return !d.isValid() || d.year() < 2000 ? undefined : d
        },
      })
      Object.defineProperty(raw.result, "dateSubmittedForInput", {
        get: function () {
          return this.dateSubmittedObject?.local()?.format(DATETIME_FORMAT)
        },
        set: function (v) {
          console.log("TODO: review this")
          console.log(v)
          this.dateSubmitted = v
        },
      })
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getWorkOrderAsync")
  return result
}

const patchWorkOrderAsync = async (instance, req, workOrderType, workOrderId) => {
  startLoading("pathcWorkOrderAsync")
  try {
    clearNotifications()
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WorkOrder/${workOrderType}/${workOrderId}`,
      "PATCH",
      req
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    stopLoading("pathcWorkOrderAsync")
    return true
  } catch (e) {
    setError(e)
  }
  stopLoading("pathcWorkOrderAsync")
  return false
}

const getTimeAsync = async (instance, workOrderType, workOrderId) => {
  let result = []
  if (workOrderId?.length !== 36) {
    console.error("workOrderId should be GUID")
    return result
  }
  if (workOrderType?.length < 5) {
    console.error("workOrderType missing")
    return result
  }
  startLoading("getTimeAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/WorkOrderTime/${workOrderType}/${workOrderId}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getTimeAsync")
  return result
}

const getTransportAsync = async (instance, workOrderType, workOrderId) => {
  let result = null
  if (workOrderId?.length !== 36) {
    console.error("workOrderId should be GUID")
    return result
  }
  if (workOrderType?.length < 5) {
    console.error("workOrderType missing")
    return result
  }
  startLoading("getTransportAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WorkOrderTransport/${workOrderType}/${workOrderId}`,
      "GET"
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getTransportAsync")
  return result
}

const getCustomerAsync = async (instance, id, populateDetails = false) => {
  let result = null
  if (!id || id === EMPTY_GUID) {
    console.warn("empty")
    return result
  }
  startLoading("getCustomerAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/Customers/${id}?populateDetails=${populateDetails}`,
      "GET"
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getCustomerAsync")
  return result
}

const getFacilityAsync = async (instance, id, populateDetails = false) => {
  let result = null
  if (!id || id === EMPTY_GUID) {
    return result
  }
  startLoading("getFacilityAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/Facility/${id}?populateDetails=${populateDetails}`,
      "GET"
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    if (raw.result) {
      Object.defineProperty(raw.result, "dateLastRegularServiceObject", {
        get: function () {
          const d = dayjs(this.dateLastRegularService)
          return !d.isValid() || d.year() < 2000 ? undefined : d
        },
      })
      Object.defineProperty(raw.result, "dateLastThermometerCalibrationObject", {
        get: function () {
          const d = dayjs(this.dateLastThermometerCalibration)
          return !d.isValid() || d.year() < 2000 ? undefined : d
        },
      })
      Object.defineProperty(raw.result, "dateLastLeakageCheckObject", {
        get: function () {
          const d = dayjs(this.dateLastLeakageCheck)
          return !d.isValid() || d.year() < 2000 ? undefined : d
        },
      })
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getFacilityAsync")
  return result
}

const deleteFacilityContactAsync = async (instance, fc) => {
  if (!fc) {
    return
  }
  startLoading("deleteFacilityContactAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/FacilitiesContacts/${fc.id}`, "DELETE", fc)
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("deleteFacilityContactAsync")
  return result
}

const getMaterialsAsync = async (instance, materialRelation, relationId, sort) => {
  let result = []
  if (relationId?.length !== 36) {
    console.error("relationId should be GUID")
    return result
  }
  if (materialRelation?.length < 5) {
    console.error("materialRelation missing")
    return result
  }
  let extraParams = ""
  if (sort?.by) {
    extraParams = `&sortBy=${sort.by}&sortDir=${sort.dir}`
  }
  startLoading("getMaterialsAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/Materials/${materialRelation}/${relationId}?1=1${extraParams}`,
      "GET"
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getMaterialsAsync")
  return result
}

const searchDevicesAsync = async (instance, q) => {
  let result = null
  startLoading("searchDevicesAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/DevicesByBarcode?q=${encodeURIComponent(q)}`, "GET")
    if (!Array.isArray(raw.result)) {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("searchDevicesAsync")
  return result
}

const searchMaterialsAsync = async (instance, searchby, q) => {
  let result = null
  startLoading("searchMaterialsAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/Materials?variant=Full&${searchby}=${encodeURIComponent(q)}`,
      "GET"
    )
    if (!Array.isArray(raw.result)) {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("searchMaterialsAsync")
  return result
}

const saveMaterialAsync = async (instance, material, workOrderType, workOrderId) => {
  return await saveMaterialsAsync(instance, new Array(material), workOrderType, workOrderId)
}

const saveMaterialsAsync = async (instance, materials, materialRelation, relationId) => {
  let result = false
  if (relationId?.length !== 36) {
    console.error("relationId should be GUID")
    return result
  }
  if (materialRelation?.length < 5) {
    console.error("materialRelation missing")
    return result
  }
  if (!Array.isArray(materials)) {
    console.error("materials not array")
    return result
  }
  materials = materials.filter((m) => m.materialID && m.quantity && !isNaN(m.quantity))
  if (materials.length === 0) {
    setWarning("Količina je povsod 0, zato ne shranjujem.")
    return true
  }
  startLoading("saveMaterialsAsync")
  try {
    let url = `${AZURE_URI}/Materials/${materialRelation}/${relationId}`
    const raw = await fetchAuthenticated(instance, url, "POST", materials)
    if (raw?.status !== "ok" && raw?.status !== "warn") {
      throw raw
    }
    result = raw?.affectedRows > 0
  } catch (e) {
    setError(e)
  }
  stopLoading("saveMaterialsAsync")
  return result
}

const orderMaterialAsync = async (instance, material, materialRelation, relationId) => {
  let result = false
  if (relationId?.length !== 36) {
    console.error("relationId should be GUID")
    return result
  }
  if (materialRelation?.length < 5) {
    console.error("materialRelation missing")
    return result
  }
  if (!material || !material.materialID || !material.quantity) {
    console.log(material)
    console.error("bad material")
    return result
  }
  startLoading("orderMaterialAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/MaterialOrder/${materialRelation}/${relationId}`,
      "POST",
      material
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw?.result === true
  } catch (e) {
    setError(e)
  }
  stopLoading("orderMaterialAsync")
  return result
}

const getProjectAsync = async (instance, id) => {
  let result = null
  startLoading("getProjectAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Project/${id}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getProjectAsync")
  return result
}

const saveProjectAsync = async (instance, project, isNew) => {
  let result = null
  startLoading("saveProjectAsync")
  try {
    clearNotifications()
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Project`, isNew ? "POST" : "PUT", project)
    if (raw?.status !== "ok") {
      throw raw
    }
    result = true
    setSuccess({ message: "Shranjeno" })
  } catch (e) {
    setError(e)
  }
  stopLoading("saveProjectAsync")
  return result
}

const getLeakageCheckFormAsync = async (instance, id, isNew, workOrderId) => {
  let result = null
  startLoading("getLeakageCheckFormAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/LeakageCheckForm/${id}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    if (isNew) {
      raw.result.serviceOrderID = workOrderId
    } else if (raw.result.serviceOrderID !== workOrderId) {
      throw "serviceOrderID != workOrder.id"
    }

    Object.defineProperty(raw.result, "leakageCheckDateObject", {
      get: function () {
        const d = dayjs(this.leakageCheckDate)
        return !d.isValid() || d.year() < 2000 ? undefined : d
      },
    })
    Object.defineProperty(raw.result, "leakageCheckDateForInput", {
      get: function () {
        return this.leakageCheckDateObject?.format(DATETIME_FORMAT)
      },
      set: function (v) {
        console.log("TODO: review this")
        console.log(v)
        this.leakageCheckDate = v
      },
    })

    Object.defineProperty(raw.result, "dateSignedObject", {
      get: function () {
        const d = dayjs(this.dateSigned)
        return !d.isValid() || d.year() < 2000 ? undefined : d
      },
    })
    Object.defineProperty(raw.result, "dateSignedForInput", {
      get: function () {
        return this.dateSignedObject?.format(DATE_FORMAT)
      },
      set: function (v) {
        console.log("TODO: verify format on input")
        this.dateSigned = v
      },
    })
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getLeakageCheckFormAsync")
  return result
}

export const getCalibrationReportAsync = async (instance, deviceId, workOrderId) => {
  let result = null
  startLoading("getCalibrationReportAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/CalibrationReport/${workOrderId}/${deviceId}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getCalibrationReportAsync")
  return result
}

const changePauseAsync = async (instance, stop = false) => {
  let result = null
  startLoading("changePauseAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/WorkPause`, stop ? "PATCH" : "POST")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("changePauseAsync")
  return result
}

const saveLeakageCheckFormAsync = async (instance, lcf) => {
  let result = false
  startLoading("saveLeakageCheckFormAsync")
  try {
    clearNotifications()
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/LeakageCheckForm`, "POST", lcf)
    if (raw?.status !== "ok") {
      throw raw
    }
    setSuccess({ message: "Shranjeno" })
    result = true
  } catch (e) {
    setError(e)
  }
  stopLoading("saveLeakageCheckFormAsync")
  return result
}

export const saveCalibrationReportAsync = async (instance, lcf) => {
  let result = false
  startLoading("saveCalibrationReportAsync")
  try {
    clearNotifications()
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/CalibrationReport`,
      lcf.id === EMPTY_GUID ? "POST" : "PUT",
      lcf
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    setSuccess({ message: "Shranjeno" })
    result = true
  } catch (e) {
    setError(e)
  }
  stopLoading("saveCalibrationReportAsync")
  return result
}

const listRecentServiceOrdersAsync = async (instance, facilityId) => {
  let result = { orders: [], total: 0 }
  if (facilityId?.length !== 36) {
    console.error("facilityId should be GUID")
    return result
  }
  startLoading("listRecentServiceOrdersAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WorkOrders/Service/Completed,Finalized?facilityId=${facilityId}&itemsPerPage=15`,
      "GET"
    )
    if (!Array.isArray(raw.result)) {
      throw raw
    }
    result.orders = raw.result
    result.total = raw.numberOfRecords
  } catch (e) {
    setError(e)
  }
  stopLoading("listRecentServiceOrdersAsync")
  return result
}

const listServiceOrderDevicesAsync = async (instance, id) => {
  if (!id || id === "new") return []
  startLoading("listServiceOrderDevicesAsync")
  let result = []
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/ServiceOrder/Devices/${id}?includeAttributes=false`,
      "GET"
    )
    if (!Array.isArray(raw.result)) {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("listServiceOrderDevicesAsync")
  return result
}

const saveWorkOrderTimesAsync = async (instance, timeEntry, workOrderType, workOrderId) => {
  let result = false
  if (workOrderId?.length !== 36) {
    console.error("workOrderId should be GUID")
    return result
  }
  if (workOrderType?.length < 5) {
    console.error("workOrderType missing")
    return result
  }
  startLoading("saveWorkOrderTimesAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WorkOrderTime/${workOrderType}/${workOrderId}`,
      "POST",
      new Array(timeEntry)
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw?.affectedRows > 0
  } catch (e) {
    setError(e)
  }
  stopLoading("saveWorkOrderTimesAsync")
  return result
}

const saveWorkOrderTransportAsync = async (instance, transportEntry, workOrderType, workOrderId) => {
  let result = false
  if (workOrderId?.length !== 36) {
    console.error("workOrderId should be GUID")
    return result
  }
  if (workOrderType?.length < 5) {
    console.error("workOrderType missing")
    return result
  }
  startLoading("saveWorkOrderTransportAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WorkOrderTransport/${workOrderType}/${workOrderId}`,
      "POST",
      new Array(transportEntry)
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw?.affectedRows > 0
  } catch (e) {
    setError(e)
  }
  stopLoading("saveWorkOrderTransportAsync")
  return result
}

const insertNewTransportEntryAsync = async (
  instance,
  workOrderType,
  workOrderId,
  warehouseId,
  location,
  startTimeObject
) => {
  let te = null
  if (location && (!location.lat || !location.lng)) {
    console.error(location)
    setError("Slaba lokacija.")
    return
  }
  if (!workOrderId) {
    setError("Interna napaka.")
    return
  }
  if (warehouseId?.length !== 36) {
    setError("Interna napaka.")
    console.error("warehouseId should be GUID")
    return
  }
  startLoading("insertNewTransportEntryAsync")
  try {
    const raw1 = await fetchAuthenticated(instance, `${AZURE_URI}/WorkOrderTransport/new`, "GET")
    if (raw1?.status !== "ok") {
      throw raw1
    }
    te = raw1.result
    te.vehicleId = warehouseId
    te.startDateTime = dayjs.isDayjs(startTimeObject) ? startTimeObject.toISOString() : dayjs().toISOString()
    if (location) {
      te.startLat = location.lat
      te.startLng = location.lng
      te.hasStartLocation = true
    } else {
      te.hasStartLocation = false
    }
    te.hasEndLocation = false
    te.hasStartEndLocation = false
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WorkOrderTransport/${workOrderType}/${workOrderId}`,
      "POST",
      new Array(te)
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    ensureTransportEntryExtensions(te)
    te.isNew = false
  } catch (e) {
    setError(e)
    te = null
  }
  stopLoading("insertNewTransportEntryAsync")
  return te
}

const insertNewTimeEntryAsync = async (instance, workOrderType, workOrderId, startTimeObject) => {
  let te = null
  if (!workOrderId) {
    setError("Interna napaka.")
    return
  }
  if (!dayjs.isDayjs(startTimeObject)) {
    setError("Interna napaka.")
    console.error("startTimeObject should be dayjs object")
    return
  }
  startLoading("insertNewTimeEntryAsync")
  try {
    const raw1 = await fetchAuthenticated(instance, `${AZURE_URI}/WorkOrderTime/new`, "GET")
    if (raw1?.status !== "ok") {
      throw raw1
    }
    te = raw1.result
    te.startDateTime = startTimeObject.toISOString()
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WorkOrderTime/${workOrderType}/${workOrderId}`,
      "POST",
      new Array(te)
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    ensureTimeEntryExtensions(te)
  } catch (e) {
    setError(e)
  }
  stopLoading("insertNewTimeEntryAsync")
  te.isNew = false
  return te
}

const getWorkerTimesAsync = async (instance, workerId, from, to, fromToWork) => {
  let result = []
  if (!workerId) {
    return result
  }
  startLoading("getWorkerTimesAsync")
  try {
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/Report/WorkerTimeTransport/${workerId}?from=${from}&to=${to}&fromToWork=${fromToWork}`,
      "GET"
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading("getWorkerTimesAsync")
  return result
}

const changeWarehouseAsync = async (instance, warehouseId, previousWarehouseId = null) => {
  let result = null
  startLoading("changeWarehouseAsync")
  try {
    clearNotifications()
    const raw = await fetchAuthenticated(
      instance,
      previousWarehouseId === null ? `${AZURE_URI}/SelectedWarehouse` : `${AZURE_URI}/SelectedWarehouseChanged`,
      "POST",
      {
        warehouseId,
        previousWarehouseId,
      }
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    result = true
    setSuccess({ message: previousWarehouseId === null ? "Skladišče določeno" : "Skladišče spremenjeno" })
  } catch (e) {
    setError(e)
  }
  stopLoading("changeWarehouseAsync")
  return result
}

const saveWarehouseTransferAsync = async (instance, warehouseTransfer, isNew, extraParams = "") => {
  let result = null
  startLoading("saveWarehouseTransferAsync")
  try {
    clearNotifications()
    const raw = await fetchAuthenticated(
      instance,
      `${AZURE_URI}/WarehouseTransfer${extraParams}`,
      isNew ? "POST" : "PUT",
      warehouseTransfer
    )
    if (raw?.status !== "ok") {
      throw raw
    }
    if (raw.id) {
      warehouseTransfer.id = raw.id
    }
    result = true
    setSuccess({ message: warehouseTransfer.status == 2 ? "Zaključeno" : "Shranjeno" })
  } catch (e) {
    setError(e)
  }
  stopLoading("saveWarehouseTransferAsync")
  return result
}

const addDateProperty = (obj, name) => {
  Object.defineProperty(obj, name + "Object", {
    get: function () {
      const d = dayjs.utc(this[name]) // warning: year 1 is covnerted to 2001
      return !d.isValid() || d.year() < 2002 ? undefined : d
    },
  })
}

const toNullWhen0001 = (obj, name) => {
  if (obj[name].startsWith("0001")) {
    obj[name] = ""
  }
}

const getDeviceAsync = async (instance, id) => {
  let result = null
  if (!id) return result
  startLoading("getDeviceAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Device/${id}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
    result.warrantyValidUntil = result.warrantyValidUntil?.substring(0, 10)
    toNullWhen0001(result, "lastLeakageTest")
    toNullWhen0001(result, "lastService")
    toNullWhen0001(result, "lastCondenserCleaning")
    toNullWhen0001(result, "lastCO2Sensor")
    toNullWhen0001(result, "lastThermometerCalibration")
    addDateProperty(result, "lastLeakageTest")
    addDateProperty(result, "lastService")
    addDateProperty(result, "lastCondenserCleaning")
    addDateProperty(result, "lastCO2Sensor")
    addDateProperty(result, "lastThermometerCalibration")
  } catch (e) {
    setError(e)
  }
  stopLoading("getDeviceAsync")
  return result
}

const saveDeviceAsync = async (instance, device, isNew) => {
  let result = null
  startLoading("saveDeviceAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Devices`, isNew ? "POST" : "PUT", device)
    if (raw?.status !== "ok") {
      throw raw
    }
    result = true
    setSuccess("Shranjeno.")
  } catch (e) {
    setError(e)
  }
  stopLoading("saveDeviceAsync")
  return result
}

const saveSimpleEntityAsync = async (instance, name, entity, isNew) => {
  let result = null
  startLoading("saveSimpleEntityAsync")
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/${name}`, isNew ? "POST" : "PUT", entity)
    if (raw?.status !== "ok") {
      throw raw
    }
    result = true
    setSuccess("Shranjeno.")
  } catch (e) {
    setError(e)
  }
  stopLoading("saveSimpleEntityAsync")
  return result
}

const saveFacilityContactAsync = async (instance, facilityContact, facility) => {
  startLoading("saveFacilityContactAsync")
  if (!facilityContact) {
    setError("Kontakt je obvezen")
    return
  }
  if (!facility || !facility.id) {
    console.warn(facility)
    setError("Napaka")
    return
  }

  try {
    facilityContact.facilityID = facility.id

    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/FacilityContact`, "POST", facilityContact)
    if (raw?.status !== "ok") {
      throw raw
    }
    setSuccess({ message: "Nov podpisnik shranjen" })
  } catch (e) {
    setError(e)
  }
  stopLoading("saveFacilityContactAsync")
}

export {
  saveFacilityContactAsync,
  listEntitiesAsync,
  listPagedEntitesAsync,
  getWorkOrderAsync,
  patchWorkOrderAsync,
  getTimeAsync,
  getTransportAsync,
  getFacilityAsync,
  getMaterialsAsync,
  searchDevicesAsync,
  searchMaterialsAsync,
  saveMaterialAsync,
  saveProjectAsync,
  getProjectAsync,
  getLeakageCheckFormAsync,
  getEntityAsync,
  saveLeakageCheckFormAsync,
  listRecentServiceOrdersAsync,
  saveWorkOrderTransportAsync,
  insertNewTransportEntryAsync,
  changePauseAsync,
  saveWorkOrderTimesAsync,
  insertNewTimeEntryAsync,
  getWorkerTimesAsync,
  deleteFacilityContactAsync,
  saveMaterialsAsync,
  saveWarehouseTransferAsync,
  getDeviceAsync,
  saveDeviceAsync,
  getCustomerAsync,
  listServiceOrderDevicesAsync,
  saveSimpleEntityAsync,
  orderMaterialAsync,
  changeWarehouseAsync,
}

// source: https://github.com/jekorx/vue-canvas-sign
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { useWorkOrder } from '../composition-api/useWorkOrder'
import { setError, startLoading, stopLoading, setSuccess, clearNotifications } from "../components/Notification"
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { getFacilityAsync, saveFacilityContactAsync, deleteFacilityContactAsync } from "../api"
import { FacilityContactEdit } from '../components/FacilityContactEdit'

const SignOrder = {
  components: { FacilityContactEdit },
  template: `
    <div class="row" v-if="!!workOrder">
      <div class="col-sm-3"><h1>{{ workOrder.orderNumber }}</h1></div>

      <div class="col-sm-6 d-none d-lg-block" v-if="workOrder.canComplete"><h3>Podpis stranke</h3></div>
      <div class="col-sm-6 d-none d-lg-block" v-if="workOrder.status === 6"><h3>Ponovno pošiljanje</h3></div>

      <div class="col-sm-3"><h2><OrderBadge :status="workOrder.statusText" /></h2></div>
    </div>

    <div class="row mb-3" v-if="workOrder?.canComplete || workOrder?.status === 6">
      <div class="col-md-6" v-show="facility?.contacts?.length > 0">
        <h5>Pošlji podpisan nalog po emailu na:</h5>
        <div class="row mb-3" v-for="contact in facility?.contacts">
          <div class="col-sm-9">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :id="contact.id" v-model="contact.checked">
              <label class="form-check-label" :for="contact.id">
              {{ contact.name }} [{{ contact.email }}]
              </label>
            </div>
          </div>
          <div class="col-sm-3 text-end">
            <button class="btn btn-sm btn-danger" v-show="!contact.checked" @click="localDeleteFacilityContactAsync(contact)">Zbriši</button>
          </div>
        </div>
      </div>
      <FacilityContactEdit class="col-md-6 bg-light rounded"
        v-model="facilityContact"
        @save="localSaveFacilityContactAsync(facilityContact)" />
    </div>

    <div class="row" v-show="workOrder?.canComplete">
      <div class="shadow-lg p-3 mb-5 bg-white rounded">
        <canvas
          ref="canvasEl"
          style="width: 100%"
          :width="width"
          :height="height"
          @touchstart="touchstart"
          @mousedown="mousedown"
          @touchmove="touchmove"
          @mousemove="mousemove"
          @touchend="touchend"
          @mouseup="mouseup"
        ></canvas>
      </div>
    </div>

    <RequiredWarning v-show="!isValid" />

    <div class="row mb-5">
      <div class="col-2" v-show="workOrder?.canComplete">
        <button class="btn btn-outline-danger btn-lg me-3" type="button" @click="clear">Izbriši podpis</button>
      </div>
        <div class="col-10 text-end">
          <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: workOrder.id }  })">Prekliči</button>
          <button class="btn btn-primary btn-lg me-3" type="button" v-show="workOrder?.canComplete" :disabled="!isValid" @click="signAsync">Podpiši in zaključi nalog</button>
          <button class="btn btn-primary btn-lg me-3" type="button" v-show="workOrder?.status === 6" :disabled="!isValid" @click="sendAgainAsync">Pošlji nalog</button>
      </div>
    </div>
`,
  props: {
    color: {
      type: String,
      default: '#000'
    },
    backgroundColor: {
      type: String,
      default: '#FFF'
    },
    width: {
      type: Number,
      default: 640
    },
    height: {
      type: Number,
      default: 400
    }
  },
  setup(props) {
    const { worker } = useIsAuthorized()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { isNew, workOrder } = useWorkOrder("Service")

    const facilityContact = ref({})

    const signatureName = computed(() => facility.value?.contacts?.find(c => !!c.checked)?.name)

    const isValid = computed(() => signatureName.value?.length > 3)

    const localSaveFacilityContactAsync  = async (contact) => {
      await saveFacilityContactAsync(instance, contact, facility.value)
      facilityContact.value = {}
      await loadFacilityAsync();
    }

    const localDeleteFacilityContactAsync = async (contact) => {
      if (await deleteFacilityContactAsync(instance, contact)) {
        await loadFacilityAsync()
        setSuccess({ message: "Kontakt zbrisan" })
      }
    }

    const signAsync = async () => {
      startLoading('signAsync')
      try {
        const formData = new FormData()
        formData.append("serviceOrderId", workOrder.value.id)
        formData.append("signatureName", signatureName.value)
        const sendToFacilityContacts = facility.value?.contacts?.filter(c => !!c.checked).map(c => c.id).join(",")
        formData.append("sendToFacilityContacts", sendToFacilityContacts)

        const blob = await new Promise(resolve => canvasEl.value.toBlob(resolve, 'image/jpeg', 0.95))
        formData.append("file", blob, 'filename.jpg')

        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/ServiceOrderSign?warehouseId=${worker.activeWarehouse.value.id}`, "POST", formData)
        if (raw?.status !== "ok") {
          throw raw
        }
        setSuccess({ message: "Podpisano, status spremenjen v \"Zaključeno\"" })
        router.push({ name: `Service`, params: { keepMessages: true } })
      } catch (e) {
        setError(e)
      }
      stopLoading('signAsync')
    }

    const sendAgainAsync = async () => {
      startLoading('sendAgainAsync')
      try {
        const formData = new FormData()
        formData.append("serviceOrderId", workOrder.value.id)
        formData.append("signatureName", "")
        const sendToFacilityContacts = facility.value?.contacts?.filter(c => !!c.checked).map(c => c.id).join(",")
        formData.append("sendToFacilityContacts", sendToFacilityContacts)
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/ServiceOrderSendAgain?warehouseId=${worker.activeWarehouse.value.id}`, "POST", formData)
        if (raw?.status !== "ok") {
          throw raw
        }
        setSuccess({ message: "Poslano znova" })
        router.push({ name: `Service`, params: { keepMessages: true } })
      } catch (e) {
        setError(e)
      }
      stopLoading('sendAgainAsync')
    }

    const loadFacilityAsync = async () => {
      facility.value = await getFacilityAsync(instance, workOrder.value.facilityID, true)
    }

    // drawing
    const canvasEl = ref(null)
    const cxt = ref()
    const isDraw = ref(false)

    /*
    const save = callback => {
      if (!canvas.value) {
        callback()
        return
      }
      let imgBase64
      if (props.imageType === 'image/jpeg') {
        imgBase64 = canvas.value.toDataURL('image/jpeg', props.imageQual)
      } else {
        imgBase64 = canvas.value.toDataURL(props.imageType)
      }
      callback(imgBase64)
    }*/

    const clear = () => {
      const { backgroundColor } = props
      const { width, height } = canvasEl.value || {}
      if (cxt.value) {
        cxt.value.clearRect(0, 0, width || 0, height || 0)
        cxt.value.fillStyle = backgroundColor
        cxt.value.fillRect(0, 0, width || 0, height || 0)
      }
    }

    const init = () => {
      const context = cxt.value
      if (context) {
        const { backgroundColor, color } = props
        const { width, height } = canvasEl.value || {}
        context.fillStyle = backgroundColor
        context.fillRect(0, 0, width || 0, height || 0)
        context.strokeStyle = color
        context.lineWidth = 2
        context.lineCap = 'round'
        context.lineJoin = 'round'
        context.shadowBlur = 1
        context.shadowColor = "#BBB"
      }
    }

    const touchstart = (e) => {
      const { clientX, clientY, target } = e.changedTouches[0]
      if (e.target.tagName === 'CANVAS') {
        const { left, top, width, height } = canvasEl.value.getBoundingClientRect()
        const scaleX = canvasEl.value.width / width
        const scaleY = canvasEl.value.height / height
        cxt.value?.beginPath()
        cxt.value?.moveTo((clientX - left) * scaleX, (clientY - top) * scaleY)
      }
    }

    const mousedown = (e) => {
      if (e.target.tagName === 'CANVAS') {
        const { left, top, width, height } = canvasEl.value?.getBoundingClientRect() || { left: 0, top: 0 }
        const scaleX = canvasEl.value.width / width
        const scaleY = canvasEl.value.height / height

        isDraw.value = true
        cxt.value?.beginPath()
        cxt.value?.moveTo((e.clientX - left) * scaleX, (e.clientY - top) * scaleY)
      }
    }

    const _draw = () => {

    }

    const touchmove = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (!cxt.value) return
      const { clientX, clientY, target } = e.changedTouches[0]
      if (e.target.tagName === 'CANVAS') {
        const { left, top, width, height } = canvasEl.value.getBoundingClientRect()
        const scaleX = canvasEl.value.width / width
        const scaleY = canvasEl.value.height / height
        cxt.value.lineTo((clientX - left) * scaleX, (clientY - top) * scaleY)
        cxt.value.stroke()
      }
    }

    const mousemove = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (isDraw.value && canvasEl.value && cxt.value) {
        if (e.target.tagName === 'CANVAS') {
          const { left, top, width, height } = canvasEl.value.getBoundingClientRect()
          const scaleX = canvasEl.value.width / width
          const scaleY = canvasEl.value.height / height
          cxt.value.lineTo((e.clientX - left) * scaleX, (e.clientY - top) * scaleY)
          cxt.value.stroke()
          if (e.clientX - left <= 0 ||
              e.clientX - left >= (width || 0)  ||
              e.clientY - top <= 0 ||
              e.clientY - top >= (height || 0)) {
            isDraw.value = false
          }
        }
      }
    }

    const touchend = () => cxt.value?.closePath()

    const mouseup = () => {
      isDraw.value = false
      cxt.value?.closePath()
    }

    const facility = ref(null)

    /* TODO: candidate for de-duplication */
    /* difference in populateDetails !! */
    watch(workOrder, async () => {
      if (workOrder.value.facilityID !== facility?.id) {
        facility.value = await getFacilityAsync(instance, workOrder.value.facilityID, true)
      }
    }, { deep: true })

    onMounted(() => {
      const context = canvasEl.value?.getContext('2d')
      if (context) {
        cxt.value = context
        init()
      }
    })

    return {
      isNew,
      canvasEl,
      isValid,
      workOrder,
      facility,
      facilityContact,
      loadFacilityAsync,
      localSaveFacilityContactAsync,
      localDeleteFacilityContactAsync,
      sendAgainAsync,

      signAsync,
      signatureName,

      clear,
      touchstart,
      mousedown,
      touchmove,
      mousemove,
      touchend,
      mouseup
    }

  }
}

export default SignOrder

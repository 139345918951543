import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onUnmounted } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

import FacilitiesCard from "../components/FacilitiesCard"
import InternalOrdersCard from "../components/InternalOrdersCard"
import WarehouseTransfersCard from "../components/WarehouseTransfersCard"
import WorkersCard from "../components/WorkersCard"
import Notifications from "../components/Notifications"
import { WorkersWithWorkOrdersCard, assignOrderAsync } from "../components/WorkersWithWorkOrdersCard"
import ProductionOrdersCard from "../components/ProductionOrdersCard"

export default HomeLeaderProduction = {
  name: 'HomeLeaderProduction',
  components: { FacilitiesCard, InternalOrdersCard, WarehouseTransfersCard, WorkersCard, Notifications, WorkersWithWorkOrdersCard, ProductionOrdersCard },
  template: `
  <div>
    <div class="row">
      <div class="col-md-4">
        <Notifications />
        <InternalOrdersCard class="card mb-3" title="Interni nalogi" subTitle="Accepted, zame" :ownerID="worker.id.value" statuses="Accepted" />
      </div>
      <div class="col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bližnjice</h5>
            <ul>
              <li>
                <button class="btn btn-link" type="button" @click="$router.push({ name: 'InternalOrder', params: { workOrderId: 'new' } })">Nov interni nalog</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <WorkersCard class="card mb-3"  />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ProductionOrdersCard class="card mb-3" />
      </div>
      <div class="col-md-6">
        <WorkersWithWorkOrdersCard class="card mb-3" />
      </div>
    </div>

  </div>
  `,
  setup()  {
    const { worker } = useIsAuthorized()
    const workersCardEl = ref(null)

    return {
      worker,
      workersCardEl
    }
  }
}

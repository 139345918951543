/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'

const _state = ref({
  success: {},
  errors: [],
  warnings: [],
  loading: true
})

const _processes = new Set()

const useNotification = () => {
  return {
    state: {
      success: computed(() => _state.value.success),
      errors: computed(() => _state.value.errors),
      warnings: computed(() => _state.value.warnings),
      loading: computed(() => _state.value.loading)
    },
  }
}

const Notification = {
  name: 'Notification',
  template: `
  <div class="full-screen-overlay bg-primary opacity-25">
      <div class="spinner-border" style="width: 10rem; height: 10rem;" role="status"><span class="visually-hidden">Loading... </span></div>
  </div>
  <div class="alert alert-success mt-2" role="alert" v-if="state.success.value.message">
    {{ state.success.value.message }}
  </div>
  <div class="alert alert-warning mt-2" role="alert" v-if="state.warnings.value.length">
    <div class="mb-2" v-for="w in state.warnings.value">
      <h3 v-if="w.message">{{ w.message }}</h3>
    </div>
  </div>
  <div class="alert alert-danger mt-2" role="alert" v-if="state.errors.value.length">
    <div class="mb-2" v-for="e in state.errors.value">
      <h3 v-if="e.message">{{ e.message }}</h3>
      <p v-else>{{ e }}</p>
      <h6 v-if="e.url">{{ e.url }}</h6>
      <pre v-if="e.stack" class="mt-3">
        {{ e.stack }}
      </pre>
      <pre v-if="e.stackTrace" class="mt-3">
        {{ e.stackTrace }}
      </pre>
      <ul v-if="e.invalidProperties">
        <li v-for="p in e.invalidProperties">{{ p.errorMessage }}</li>
      </ul>
    </div>
  </div>
  `,
  props: {
    noRouter: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const { state } = useNotification()

    if (!props.noRouter) {
      // clear notification on route change if seen
      const router = VueRouter.useRouter()
      if (router) {
        router.afterEach((to, from) => {
          if (to.params.keepMessages !== "true") {
            clearNotifications()
          }
        })
      }
    }

    onMounted(() => {
      if (_state.value.loading) {
        document.body.classList.add('show-loader')
      } else {
        document.body.classList.remove('show-loader')
      }
    })

    return {
      state
    }
  },
}

watch(() => _state.value.loading, loading => {
  if (loading) {
    document.body.classList.add('show-loader')
  } else {
    document.body.classList.remove('show-loader')
  }
})

const clearNotifications = () => {
  _state.value.success = {}
  _state.value.warnings.length = 0
  _state.value.errors.length = 0
}

const setSuccess = (s, skipScroll = false) => {
  if (!skipScroll) {
    window.scrollTo(0,0)
  }
  if (typeof s === 'string') {
    _state.value.success = { message: s }
  } else if (s.message) {
    _state.value.success = s
  } else {
    setError(new Error("incorrect use of setSuccess"))
    return
  }
}

const setWarning = s => {
  if (typeof s === 'string') {
    _state.value.warnings.push({ message: s })
  } else if (s.message) {
    _state.value.warnings.push(s)
  } else {
    _state.value.error = new Error("incorrect use of setWarning")
  }
  _state.value.success = {}
  window.scrollTo(0,0)
}

const setError = (e) => {
  console.error(e)
  window.scrollTo(0,0)
  _state.value.errors.push(e)
  _state.value.success = {}
  _state.value.warnings.length = 0
}

const startLoading = subsystem => {
  _state.value.errors.length = 0
  if (!subsystem?.length) {
    throw TypeError('subsystem must be a string')
  }
  _processes.add(subsystem)
  _state.value.loading = _processes.size > 0
  // console.log(`Start:${subsystem} ${_processes.size}`)
}

const stopLoading = subsystem => {
  if (!subsystem?.length) {
    throw TypeError('subsystem must be a string')
  }
  _processes.delete(subsystem)
  _state.value.loading = _processes.size > 0
  // console.log(`Stop:${subsystem} ${_processes.size}`)
}

export { Notification, useNotification, startLoading, stopLoading, setError, setWarning, setSuccess, clearNotifications }

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { listRecentServiceOrdersAsync } from '../api'

const FacilityHistory = {
  name: 'FacilityHistory',
  components: {},
  template: `
  <Modal :visible="visible" @close="$emit('close')" title="Zgodovina objekta">

    <div class="alert alert-warning" role="alert" v-show="facilityId?.length !== 36">
      Objekt ni izbran
    </div>

    <div class="alert alert-info" role="alert" v-show="orders?.length > 0">
      Klik na naloge spodaj se bo odprl v novem oknu.
    </div>

    <div class="row mb-3" v-show="facilityId?.length === 36">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-stripped table-sm table-hover bg-light">
            <thead>
              <tr>
                <th scope="col">Številka</th>
                <th scope="col">Zadeva</th>
                <th scope="col">Končano</th>
                <th scope="col">Delavec</th>
              </tr>
            </thead>
            <tbody>
              <tr role="button" v-for="order in orders"  @click="selectOrder(order)">
                <td>{{ order.orderNumber }}<OrderBadge cssClass="d-block d-lg-none" :status="order.status" :dateCompleted="order.dateCompleted" /></td>
                <td>{{ order.subject }}</td>
                <td>{{ order.dateCompleted ? $dayjs.utc(order.dateCompleted).local().format($DATE_FORMAT_DISPLAY) : '' }}</td>
                <td>{{ order.owner }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>Skupaj: {{ total }}</p>
      </div>
    </div>

    <template v-slot:buttons>

    </template>
  </Modal>`,
  emits: ["close"],
  props: {
    facilityId: String,
    visible: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const router = VueRouter.useRouter()
    const visible = toRef(props, "visible")
    const orders = ref([])
    const total = ref(0)

    const selectOrder = order => {
      emit("close")
      const routeData = router.resolve({ name: 'ServiceOrder', params: { workOrderId: order.id } })
      window.open(routeData.href, "_blank")
    }

    watch(visible, async (curr, next) => {
      if (curr) {
        const result = await listRecentServiceOrdersAsync(instance, props.facilityId)
        orders.value = result.orders
        total.value = result.total
      } else {
        orders.value.length = 0
        total.value = 0
      }
    })

    return { orders, selectOrder, total }
  },
}

export default FacilityHistory

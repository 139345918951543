import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useProjects } from "../composition-api/useProjects"

export default ProjectsCard = {
  name: 'ProjectsCard',
  template: `<div>
  <div class="card-body">
    <h5 class="card-title">{{ title }} <small class="text-muted" v-show="subTitle">{{ subTitle }}</small></h5>
    <ul>
      <li v-for="p in projects" class="mt-1">
        <router-link :to="{ name: 'Project', params: { projectId: p.id } }">{{ p.fullName }}</router-link>
      </li>
    </ul>
    <p v-show="projects.length === 0">Ni projektov.</p>
    <p v-show="paging.itemsPerPage === projects.length">
      <router-link :to="{ name: 'Projects' }">Več zadetkov</router-link>
    </p>
  </div>
</div>`,
  props: {
    title: {
      type: String,
      default: "Projekti"
    },
    subTitle: {
      type: String,
      default: "Aktivni"
    },
    statuses: {
      type: String,
      default: "Active"
    },
    itemsPerPage: {
      type: Number,
      default: 30
    }
  },
  setup(props) {
    const { instance } = useMsal()

    const { paging, resetPaging,  resetFilters, setPageNum, filters, selectProject, projects } = useProjects()

    watch(
      () => ({ ...filters.value }),
      () => resetPaging() // will also fire getProjectsAsync()
    )

    onMounted(async () => {
      resetFilters()
      paging.value.itemsPerPage = props.itemsPerPage
      filters.value.status = props.statuses
    })

    return {
      paging,
      filters,
      setPageNum,
      resetPaging,
      resetFilters,
      selectProject,
      projects
    }
  }
}


import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useWorkOrder } from "../composition-api/useWorkOrder"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useMsal } from "../composition-api/useMsal"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { getFacilityAsync } from "../api"
import { EMPTY_GUID } from "scripts/utils/constants"
import { AttachFromCamera } from "../components/Attachments"
import FacilityHistory from "../components/FacilityHistory"
import { DeviceList } from "../components/DeviceList"
import SelectDevice from "../components/SelectDevice"
import ServiceOrdersCard from "../components/ServiceOrdersCard"

const ServiceOrder = {
  name: "ServiceOrder",
  components: { AttachFromCamera, FacilityHistory, DeviceList, SelectDevice, ServiceOrdersCard },
  template: `
<div v-if="!!workOrder">
  <div class="row">
    <div class="col-sm-3"><h1>{{ workOrder.orderNumber }}</h1></div>
    <div class="col-sm-3 d-none d-lg-block"><h3>Servisni nalog</h3></div>
    <div class="col-sm-3" v-show="!!workOrder.duplicatedFromServiceOrder">
      Podvojen iz
      <button class="btn btn-link"
        @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: workOrder.duplicatedFromServiceOrderID }  })">
        {{ workOrder.duplicatedFromServiceOrder }}
      </button>
    </div>
    <div class="col-sm-3" :class="{ 'offset-md-3': !workOrder.duplicatedFromServiceOrder }">
      <h2>
        <OrderBadge :status="workOrder.statusText + (workOrder.isSpecial ? '+' : '')" />
      </h2>
    </div>
  </div>

  <div class="row mb-3 mt-3">
    <div class="col-md-8">

      <SelectCustomerEntity
        required
        :customerID="workOrder.customerID"
        :facilityID="workOrder.facilityID"
        :canChange="workOrder.canChange"
        @change="e => { workOrder.customerID = e.customerID;  workOrder.facilityID = e.facilityID }"
      />

      <div class="row mb-3" v-show="hasProject">
        <label class="col-form-label col-sm-4">Projekt</label>
        <div class="col-sm-8">
          <SelectEntity apiEntity="Projects/all" v-model="workOrder.projectID" :canChange="workOrder.canChange || isAdmin" />
        </div>
      </div>

      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Prijava</label>
        <div class="col-sm-8">
          <input class="form-control" type="datetime-local" v-model="workOrder.dateSubmittedForInput" :disabled="!workOrder.canChange">
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Delavec</label>
        <div :class="{ 'col-sm-8': workOrder.status !== 14 || !isAdmin, 'col-sm-6': workOrder.status === 14 && isAdmin }">
          <SelectEntity apiEntity="Workers" v-model="workOrder.ownerID" :canChange="workOrder.canChange || workOrder.status === 14" /> <!-- change together with save button -->
        </div>
        <div class="col-sm-2" v-show="workOrder.status === 14 && isAdmin">
          <button class="btn btn-success btn-sm" type="button" @click="saveAsync">Shrani delavca</button>
        </div>
      </div>
      <div class="row mb-3">
          <label class="col-form-label col-sm-4">Zadeva *</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" minlength="4" v-model.lazy="workOrder.subject" :disabled="!workOrder.canChange" />
          </div>
      </div>
      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Opis napake</label>
        <div class="col-sm-8">
          <textarea class="form-control" v-model="workOrder.issueDescription" :disabled="!workOrder.canChange" rows="5"></textarea>
        </div>
      </div>
      <div class="row mb-3" v-show="worker.showMessageToWorker">
        <label class="col-form-label col-sm-4">Sporočilo serviserju</label>
        <div class="col-sm-8">
          <textarea class="form-control" v-model="workOrder.message" :disabled="!workOrder.canChange"></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-form-label col-sm-4">
          Opis del<br>
          <button class="btn btn-outline-danger btn-sm"
          v-show="!!autoSavedWorkDescription && autoSavedWorkDescription !== workOrder.workDescription"
          @click="workOrder.workDescription = autoSavedWorkDescription">Povrni prej shranjeni opis del</button>
        </label>
        <div class="col-sm-8">
          <textarea class="form-control" v-model="workOrder.workDescription" :disabled="!workOrder.canChange" rows="5"></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-form-label col-3 col-sm-4">Prioriteta *</div>
        <div class="col-9 col-sm-8">
          <IntegerRadioGroup v-model="workOrder.priority" :canChange="workOrder.canChange" :numberOfValues=3 namePrefix="priority" :valueNames="['1','2', '3 (največja)']" />
        </div>
      </div>
      <div class="row mb-3" v-show="isAdmin">
        <label class="col-form-label col-sm-4">Skladišče za komisioniranje</label>
        <div class="col-sm-8">
          <SelectEntity
            apiEntity="Warehouses"
            v-model="workOrder.commissionWarehouseId"
            :limit="1000"
            :canChange="workOrder.canChangeCommissionWarehouse"
            @selected="unsavedChangesToCommissionWarehouse = true" />
        </div>
      </div>

      <div class="row mb-3" v-show="$hasRoleStartsWith(worker, ['Leader', 'Admin']) && $hasStatus(workOrder, ['Completed', 'Finalized'])">
        <div class="col-form-label col-3 col-sm-4">Težavnost izvedbe</div>
        <div class="col-9 col-sm-8">
          <IntegerRadioGroup v-model="workOrder.difficultyLevel" :numberOfValues=5 namePrefix="difficultyLevel" />
        </div>
      </div>

      <div class="row mb-3" v-show="$hasRoleStartsWith(worker, ['Leader', 'Admin']) && $hasStatus(workOrder, ['Completed', 'Finalized'])">
        <div class="col-form-label col-3 col-sm-4">Kvaliteta izvedbe</div>
        <div class="col-9 col-sm-8">
          <IntegerRadioGroup v-model="workOrder.qualityLevel" :numberOfValues=5 namePrefix="qualityLevel" />
        </div>
      </div>

      <div class="row mb-3" v-show="$hasRoleStartsWith(worker, ['Leader', 'Admin']) && $hasStatus(workOrder, ['Completed', 'Finalized'])">
        <div class="col-form-label col-3 col-sm-4">Pripravljenost na delo izven delovnega časa</div>
        <div class="col-9 col-sm-8">
          <IntegerRadioGroup v-model="workOrder.extendedHoursLevel" :numberOfValues=2 namePrefix="extendedHoursLevel" :valueNames="['NE','DA']" />
        </div>
      </div>

    </div>

    <div class="col-md-4">
      <div class="card mb-3">
        <div class="card-body">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="is-special"
              v-model="workOrder.isSpecial"
              :disabled="!(workOrder.isNew || isAdmin)">
            <label class="form-check-label" for="is-special">Ima posebnosti</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="has-warranty"
              v-model="workOrder.hasWarranty"
              :disabled="!workOrder.canChange && !isAdmin">
            <label class="form-check-label" for="has-warranty">Garancija</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="has-project"
              @input="e => handleHasProject(e.target.checked)"
              :checked="hasProject"
              :disabled="!workOrder.canChange && !isAdmin">
            <label class="form-check-label" for="has-project">Del projekta</label>
          </div>
          <p class="card-text" v-show="workOrder.hadUncompletedCommission">
            <span class="badge bg-warning text-dark">Delni komision!</span>
          </p>
          <p class="card-text" v-show="facility">
            <button class="btn btn-sm btn-primary" @click="showFacilityHistory = true">Pokaži pretekle obiske na objektu</button>
          </p>
        </div>
      </div>

      <ServiceOrdersCard
        v-if="!workOrder.isNew && !!workOrder.facilityID && workOrder.facilityID !== $EMPTY_GUID && $hasRoleStartsWith(worker, ['Leader', 'Admin'])"
        class="card mb-3"
        title="Servisni nalogi"
        subTitle="Active,Accepted,InProgress,Triage + trenutni objekt"
        statuses="Active,Accepted,InProgress,Triage"
        :itemsPerPage=20
        :showLinkToMap="false"
        :facilityID="workOrder.facilityID"
        :fireAssignEvent="true"
        @assign="assignOwnerAsync"
        :excludeId="workOrder.id"
        ref="serviceOrdersCardEl" />

      <div class="card mb-3" v-if="!isNew && (!!devices?.find(d => d.isLeakageTest) || workOrder.hasLeakageCheckForms)">
        <div class="card-body">
          <h5 class="card-title d-none d-lg-block">Uhajanje</h5>

          <p class="card-text mt-3" v-show="workOrder?.leakageCheckForms?.length > 0">
            {{ workOrder.leakageCheckForms.length }} preskusi tesnosti od {{ devices?.filter(d => d.isLeakageTest).length }} naprav:
          </p>
          <p class="card-text mt-3" v-show="workOrder?.leakageCheckForms?.length === 0">
            Št. naprav: {{ devices?.filter(d => d.isLeakageTest).length }}.
          </p>
          <ul>
            <li v-for="f in workOrder.leakageCheckForms">
              <a :href="$AZURE_URI + '/Generated/Leakage/' + workOrder.id + '?leakageFormId=' + f.id">{{ f.device?.label }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="card mb-3" v-if="workOrder.hasCalibrationReports">
        <div class="card-body">
          <h5 class="card-title d-none d-lg-block">Kontrolni listi termometrov</h5>
          <ul>
            <li v-for="cr in workOrder.calibrationReports" role="button" class="text-decoration-underline">
              <a v-if="workOrder.ownerID !== $EMPTY_GUID" :href="$AZURE_URI + '/Generated/CalibrationReport/' + workOrder.id + '?deviceId=' + cr.device.id" target="_blank">{{ cr.device.label }} - {{ cr.device.deviceNumber }}</a>
              <span v-else>{{ cr.device.label }} - {{ cr.device.deviceNumber }} (izberi delavca za podpis)</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="card mb-3" v-show="workOrder.warehouseTransfers?.length">
        <div class="card-body">
          <h5 class="card-title d-none d-lg-block">Medskladiščnice</h5>
          <ul>
            <li v-for="wt in workOrder.warehouseTransfers">
              <router-link :to="{ name: 'WarehouseTransferEdit', params: { id: wt.id, type: wt.typeText } }" target="_blank">{{ wt.number }}</router-link>
              &nbsp;
              <OrderBadge :status="wt.statusText" :dateCompleted="wt.dateCompleted" />
              {{ wt.to?.name }}
            </li>
          </ul>
          <p class="card-text" v-show="$hasRoleStartsWith(worker, ['Leader', 'Admin']) && (workOrder.status === 1 || workOrder.status === 2 || workOrder.status === 3 || workOrder.status === 14 || workOrder.status === 16)">
            <button class="btn btn-info" @click="clearCommissionsAsync">Resetiraj komisione</button>
          </p>
        </div>
      </div>

    </div>
  </div>

  <div class="row mb-3">

    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="workOrder.status > 2">
      <WorkOrderTransports
        :canChange="workOrder.canChange"
        work-order-type="Service"
        :workOrderId="workOrder.id"
        :defaultStartTime="defaults.timeEndTime"
        @finished="e => { $log('finishedTransport', e); defaults.transportEndTime = e; }" />
    </div>

    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="workOrder.status > 2">
      <WorkOrderTimes
        :canChange="workOrder.canChange"
        work-order-type="Service"
        :workOrderId="workOrder.id"
        :defaultStartTime="defaults.transportEndTime"
        @finished="e => { $log('finishedTime', e); defaults.timeEndTime = e; }"
        :addMinutes="10" />
    </div>

    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew && !unsavedChangesToCommissionWarehouse && workOrder.commissionWarehouseId !== $EMPTY_GUID">
      <WorkOrderMaterials ref="workOrderMaterialsEl"
        :canChange="workOrder.canChangeMaterials"
        :showCommission="isAdmin && (workOrder.status === 1 || workOrder.status === 14 || workOrder.status === 16)"
        :showMissingCommission="isAdmin"
        :disableCommission="workOrder.commissionWarehouseId === $EMPTY_GUID"
        work-order-type="ServiceOrder"
        :workOrderId="workOrder.id"
        :showOrderMaterial="workOrder.status === 14 && isAdmin"
        :showStock="isAdmin && (workOrder.status === 1 || workOrder.status === 14 || workOrder.status === 16)"
        :serialsRequired="!(workOrder.status < 4 || workOrder.status === 14 || workOrder.status === 16)"
        @commissioned="workOrder.canChangeCommissionWarehouse = false"
        :canDeleteMaterial="workOrder.canDeleteMaterials"
        />
      <div class="row">
        <div class="col">
          <h6>Dodatni material</h6>
          <textarea class="form-control" placeholder="dodatno nabavljeni material (ni v šifrantu)" v-model="workOrder.additionalMaterial" :disabled="!workOrder.canChange"></textarea>
        </div>
      </div>
    </div>
    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-else-if="!workOrder.isNew">
      <h3>Pred dodajanjem materiala prosim shranite privzeto skladišče za komisioniranje.</h3>
    </div>

    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew && isValid">
      <div class="row">
        <h4 class="col-2">Naprave</h4>
        <div class="col-1 col-md-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="showAllDevices" id="showAllDevices">
            <label class="form-check-label" for="showAllDevices">
              <span class="d-none d-md-inline">Pokaži </span>vse<span class="d-none d-md-inline"> naprave na objektu</span>
            </label>
          </div>
        </div>
        <div class="col-2">
          <button class="btn btn-link" type="button" @click="$router.push({ name: 'DeviceEdit', params: { deviceId: 'new', customerId: workOrder.customerID, facilityId: workOrder.facilityID } })">Nova naprava</button>
        </div>
        <div class="col-5 text-end">
          <button class="btn btn-success btn-sm me-2 mb-2"
            @click="saveAndOpenNewLeakCheckFormAsync"
            :disabled="!isValid"
            v-show="workOrder.canChange || isAdmin">
            <span class="d-none d-lg-inline">Shrani in odpri obrazec za</span> preskus tesnosti
          </button>
          <button type="button" class="btn btn-secondary btn-sm me-2 mb-2" @click="showBarcode = true" v-show="workOrder.canChange"><span class="d-none d-lg-inline">Bralnik </span>črtne kode</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <DeviceList
            :serviceOrderId="workOrder.id"
            :devices="filteredDevices"
            :showService="true"
            :showFacility="false"
            :showSelect="true"
            :showAllServices="showAllDevices" />
        </div>
      </div>
    </div>
    <SelectDevice :visible="showBarcode" :facilityId="workOrder.facilityID" @add="addDeviceToServiceOrder" @close="showBarcode = false" />

    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew">
      <div class="row">
        <h4 class="col-2">Priloge</h4>
        <div class="col-10 text-end">
          <button class="btn btn-warning btn-sm ms-1 me-2 mb-2" @click="showUpload = true" v-show="workOrder.canChange">Naloži datoteko</button>
          <button class="btn btn-secondary btn-sm ms-1 me-2 mb-2" @click="showAttachFromCamera = true" v-show="workOrder.canChange">Naloži fotografijo</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <Attachments :attachments="attachments" />
        </div>
      </div>
    </div>

  </div>

  <RequiredWarning v-show="!isValid && workOrder.canChange" />

  <div class="alert alert-warning" role="alert" v-show="!areLeakageCheckFormsOk">Izpolni obrazce za preskus tesnosti!</div>

  <div class="row mb-5">
    <div class="col-2 text-start">
      <button class="btn btn-danger btn-lg me-3" type="button" v-show="!isNew && (workOrder.canDelete || isAdmin)" @click="deleteOrderAsync">Izbriši</button>
    </div>
    <div class="col-10 text-end">
      <button class="btn btn-outline-secondary me-3 mb-2" type="button" @click="$router.push({ name: 'Service' })">Prekliči</button>
      <button class="btn btn-outline-info btn-lg me-3 mb-2" type="button"
        v-show="(workOrder.status === 1 || workOrder.status === 2 || workOrder.status === 16) && worker.isAdmin"
        :disabled="workOrder.commissionWarehouseId === $EMPTY_GUID"
        @click="() => { unsavedChangesToCommissionWarehouse = false; verifyStockOrderAsync(); }">
        Shrani in naroči material
      </button>
      <button class="btn btn-outline-primary btn-lg me-3 mb-2" type="button" v-show="workOrder.status === 6" @click="$router.push({ name: 'SignOrder', params: { workOrderId: workOrder.id } })">Ponovno pošlji email</button>
      <button class="btn btn-outline-success btn-lg me-3 mb-2" type="button" v-show="workOrder.canComplete && areLeakageCheckFormsOk" @click="markCompletedServiceAsync" :disabled="hasOpenWarehouseTransfers">Shrani in podpis stranke</button>
      <button class="btn btn-outline-success me-3 mb-2" type="button" v-show="(workOrder.status === 2 || workOrder.status === 14) && isAdmin" @click="markTriageAsync">Shrani in v Triage</button>
      <button class="btn btn-outline-success me-3 mb-2" type="button" v-show="(workOrder.status === 1 || workOrder.status === 16 || workOrder.status === 15 || workOrder.status === 14) && isAdmin" @click="markActiveAsync">Shrani in v Active</button>

      <button class="btn btn-outline-success me-3 mb-2" type="button" v-show="(workOrder.status < 3 || workOrder.status === 14) && isAdmin" @click="markOfferAsync">Shrani in v ponudbo</button>

      <button class="btn btn-outline-danger btn-lg me-3" type="button" :disabled="!isValid || !isAdmin" v-show="workOrder.status === 1 || workOrder.status === 14 || workOrder.status === 16 || workOrder.status === 6 || workOrder.status === 7 || workOrder.status === 12" @click="saveAsNewAsync">Podvoji DN</button>

      <button class="btn btn-success btn-lg me-3" type="button" :disabled="!isValid || (!workOrder.canChange && !isAdmin)" @click="saveAsync">Shrani</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="alert alert-warning" role="alert" v-show="workOrder.commissionWarehouseId === $EMPTY_GUID">Skladišče za komisioniranje ni določeno.</div>
    </div>
  </div>

  <ChangeHistory v-model="workOrder" v-show="!isNew" />

  <FacilityHistory :facilityId="facility?.id" @close="showFacilityHistory = false" :visible="showFacilityHistory"  />

  <Upload :visible="!!showUpload"
    @close="showUpload = false"
    @add="e => { addAttachment(e) }"
    type="ServiceOrder"
    :id="workOrder.id" />
  <AttachFromCamera :visible="!!showAttachFromCamera"
    @close="showAttachFromCamera = false"
    @add="e => { addAttachment(e) }"
    type="ServiceOrder"
    :id="workOrder.id" />
</div>
  `,
  props: {},
  setup(props, { emit }) {
    const {
      isNew,
      workOrder,
      loadWorkOrderAsync,
      saveWorkOrderAsync,
      saveAsNewWorkOrderAsync,
      deleteOrderAsync,
      attachments,
      devices,
      addAttachment,
      verifyStockOrderAsync,
      changeOwnerAsync,
      clearCommissionsAsync,
      changeWorkOrderStatusAsync,
    } = useWorkOrder("Service")

    const serviceOrdersCardEl = ref(null)
    const workOrderMaterialsEl = ref(null)

    const assignOwnerAsync = async (wo) => {
      if (workOrder.value.ownerID === EMPTY_GUID || !workOrder.value.ownerID) {
        console.log("nobody to assign to")
        return
      }
      if (await changeOwnerAsync(wo.id, workOrder.value.ownerID)) {
        wo.ownerID = workOrder.value.ownerID
        serviceOrdersCardEl.value.loadDataAsync()
      }
    }

    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { isAdmin, worker } = useIsAuthorized()
    const { instance } = useMsal()
    const unsavedChangesToCommissionWarehouse = ref(false)
    const getLocationAsync = inject("getLocationAsync")

    const defaults = ref({ transportEndTime: null, timeEndTime: null })

    const hasOpenWarehouseTransfers = computed(() => {
      return workOrder.value.warehouseTransfers?.filter((wt) => wt.status !== 7 && wt.status !== 9).length > 0 //InventoryUpdated = 7, Canceled = 9
    })

    const showAllDevices = ref(false)
    const showFacilityHistory = ref(false)
    const showUpload = ref(false)
    const showAttachFromCamera = ref(false)
    const showBarcode = ref(false)
    const facility = ref(null)
    const hasProject = ref(false)

    const areLeakageCheckFormsOk = computed(() => {
      if (workOrder.value.isNew || devices.value.filter((d) => d.isLeakageTest).length === 0) {
        return true
      }
      return workOrder.value.leakageCheckForms.length >= devices.value?.filter((d) => d.isLeakageTest).length
    })

    const filteredDevices = computed(() => {
      if (showAllDevices.value) {
        return devices.value
      } else {
        const result = devices.value.filter((d) => d.isOverdue === true || d.isRelated === true)
        return result
      }
    })

    const markTriageAsync = async () => {
      const location = await getLocationAsync()
      if (await changeWorkOrderStatusAsync("Triage", location)) {
        workOrder.value.status = 1
        unsavedChangesToCommissionWarehouse.value = false
      }
    }

    const markActiveAsync = async () => {
      const location = await getLocationAsync()
      if (await changeWorkOrderStatusAsync("Active", location)) {
        workOrder.value.status = 2
        unsavedChangesToCommissionWarehouse.value = false
        workOrderMaterialsEl.value.reload()
      }
    }

    const markOfferAsync = async () => {
      const location = await getLocationAsync()
      if (await changeWorkOrderStatusAsync("Offer", location)) {
        workOrder.value.status = 15
        unsavedChangesToCommissionWarehouse.value = false
        workOrderMaterialsEl.value.reload()
      }
    }

    const markCompletedServiceAsync = async () => {
      const location = await getLocationAsync()
      await saveWorkOrderAsync(location)
      // if ( other other on same facility > 0).. handled by FacilityServiceOrders
      router.push({ name: "FacilityServiceOrders", params: { workOrderId: workOrder.value.id, keepMessages: true } })
    }

    const saveAndOpenNewLeakCheckFormAsync = async () => {
      const location = await getLocationAsync()
      await saveWorkOrderAsync(location)
      router.push({
        name: "ServiceOrderLeakageCheckForm",
        params: { workOrderId: workOrder.value.id, leakageCheckFormId: "new", keepMessages: true },
      })
    }

    const saveAsNewAsync = async () => {
      const location = await getLocationAsync()
      await saveAsNewWorkOrderAsync(location)
      unsavedChangesToCommissionWarehouse.value = false
    }

    const saveAsync = async () => {
      const location = await getLocationAsync()
      await saveWorkOrderAsync(location)
      unsavedChangesToCommissionWarehouse.value = false
    }

    const addDeviceToServiceOrder = (device) => {
      const result = devices.value.find((d) => d.id === device.id)
      if (result) {
        result.isRelated = true
      }
    }

    const isValid = computed(() => {
      return (
        workOrder.value.subject?.length > 3 && !!workOrder.value.facilityID && workOrder.value.facilityID !== EMPTY_GUID
      )
    })

    const handleHasProject = (newState) => {
      if (newState === false) {
        workOrder.value.projectID = EMPTY_GUID
      }
      hasProject.value = newState
    }

    const autoSavedWorkDescription = ref(null)

    const checkIfDateIsToday = (d) => {
      if (!d || typeof d.getMonth !== "function") {
        console.error("assert failed", d)
      }
      const today = new Date()
      return (
        d.getFullYear() === today.getFullYear() && d.getMonth() === today.getMonth() && d.getDate() === today.getDate()
      )
    }

    onMounted(() => {
      const autoSave = localStorage.getItem("autoSave")
      if (autoSave) {
        const parsed = JSON.parse(autoSave)
        if (parsed.workOrderId === route.params.workOrderId && checkIfDateIsToday(new Date(parsed.savedOn))) {
          autoSavedWorkDescription.value = parsed.workDescription
        }
      }
    })

    watch(
      workOrder,
      async () => {
        console.log("workOrder changed")
        if (!workOrder.value) {
          hasProject.value = null
          facility.value = null
          return
        }

        hasProject.value = !!workOrder.value.projectID && workOrder.value.projectID !== EMPTY_GUID

        if (workOrder.value.facilityID !== facility.value?.id) {
          facility.value = await getFacilityAsync(instance, workOrder.value.facilityID, true)
        }

        if (workOrder.value.id) {
          const autoSave = {
            workOrderId: workOrder.value.id,
            workDescription: workOrder.value.workDescription,
            savedOn: new Date().toISOString(),
          }
          localStorage.setItem("autoSave", JSON.stringify(autoSave))
        }
      },
      { deep: true }
    )

    return {
      worker,
      location,
      isNew,
      isValid,
      saveAndOpenNewLeakCheckFormAsync,
      workOrder,
      attachments,
      saveAsync,
      addAttachment,
      markTriageAsync,
      markActiveAsync,
      markCompletedServiceAsync,
      deleteOrderAsync,
      facility,
      isAdmin,
      showUpload,
      showAttachFromCamera,
      showFacilityHistory,
      location,
      hasProject,
      handleHasProject,
      defaults,
      devices,
      filteredDevices,
      showAllDevices,
      showBarcode,
      addDeviceToServiceOrder,
      areLeakageCheckFormsOk,
      verifyStockOrderAsync,
      saveAsNewAsync,
      loadWorkOrderAsync,
      autoSavedWorkDescription,
      markOfferAsync,
      hasOpenWarehouseTransfers,
      unsavedChangesToCommissionWarehouse,
      assignOwnerAsync,
      serviceOrdersCardEl,
      workOrderMaterialsEl,
      clearCommissionsAsync,
    }
  },
}

export default ServiceOrder

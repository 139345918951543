import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
const Modal = {
  name: 'BoostrapModal',
  template: `
  <div v-if="visible" class="modal fade show"  tabindex="-1" role="dialog" style="display:block">
   <div class="modal-dialog modal-xl">
     <div class="modal-content">
       <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
        <button type="button" @click="$emit('close')" class="btn-close"></button>
       </div>
       <div class="modal-body">
        <slot></slot>
       </div>
       <div class="modal-footer">
         <button type="button"  @click="$emit('close')" :class="'btn btn-' + variant">Zapri</button>
         <slot name="buttons"></slot>
       </div>
     </div>
   </div>
 </div>
  `,
  emits: ["close"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "outline-secondary"
    },
    title: String
  },
  setup (props) {
    const route = VueRouter.useRoute()

    const visible = toRef(props, 'visible')

    const backDrop = document.createElement("div")
    backDrop.classList.add("modal-backdrop")
    backDrop.classList.add("fade")
    backDrop.classList.add("show")

    const cleanUp = () => {
      const backDropEl = document.querySelector(".modal-backdrop")
      if (backDropEl) {
        document.body.classList.remove('modal-open')
        document.body.removeChild(backDrop)
      }
    }

    watch(() => route.path,
      () => cleanUp()
    )

    watch(visible, (n, o) => {
      if (n) {
        document.body.classList.add('modal-open')
        document.body.appendChild(backDrop)
      } else {
        cleanUp()
      }
    })

  },
}

export default Modal

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useWorkOrder } from '../composition-api/useWorkOrder'
import { setError, startLoading, stopLoading } from "../components/Notification"
import { useEnums } from '../composition-api/useEnums'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { AttachFromCamera } from "../components/Attachments"

const InternalOrder = {
  name: 'InternalOrder',
  components: { AttachFromCamera },
  template: `
<div v-if="!!workOrder">
  <div class="row">
    <div class="col-sm-3"><h1>{{ workOrder.orderNumber }}</h1></div>
    <div class="col-sm-6 d-none d-lg-block"><h3>Interni nalog</h3></div>
    <div class="col-sm-3"><h2><OrderBadge :status="workOrder.statusText" /></h2></div>
  </div>

  <div class="row mb-3 mt-3">
    <div class="col-md-8">

      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Tip</label>
        <div class="col-sm-8">
          <select class="form-select" v-model="workOrder.typeText" :disabled="!workOrder.isNew">
            <option value=""></option>
            <option v-for="[key, value] in internalOrderTypes" :value="key">{{ value }}</option>
          </select>
        </div>
      </div>

      <div class="row mb-3" v-if="workOrder.typeText?.includes('ToDo')">
        <label class="col-form-label col-sm-4">Zadeva</label>
        <div class="col-sm-8">
          <input class="form-control" type="text" v-model="workOrder.subject" :disabled="!workOrder.canChange" />
        </div>
      </div>

      <div class="row mb-3" v-if="workOrder.typeText?.includes('ToDo')">
        <label class="col-form-label col-sm-4">Projekt</label>
        <div class="col-sm-8">
          <SelectEntity apiEntity="Projects/Active" v-model="workOrder.projectID" :canChange="workOrder.canChange" />
        </div>
      </div>

      <div class="row mb-3" v-if="(workOrder.typeText?.includes('ToDo') || workOrder.typeText?.includes('OrderMaterial')) && !workOrder.isNew">
        <label class="col-form-label col-sm-4">Delavec</label>
        <div class="col-sm-8">
          <SelectEntity apiEntity="Workers" v-model="workOrder.ownerID" />
        </div>
      </div>

      <div class="row mb-3">
        <label class="col-form-label col-sm-4">Opombe</label>
        <div class="col-sm-8">
          <textarea class="form-control" rows="6" v-model="workOrder.comments" :disabled="!workOrder.canChange"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew && !workOrder.typeText?.includes('ToDo') && !workOrder.typeText?.includes('OrderMaterial')">
      <WorkOrderTransports
        :canChange="workOrder.canChange"
        work-order-type="Internal"
        :workOrderId="workOrder.id" />
    </div>
    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew && (!workOrder.typeText?.includes('Transport') && !workOrder.typeText?.includes('ToDo') && !workOrder.typeText?.includes('OrderMaterial'))">
      <WorkOrderTimes
        :canChange="workOrder.canChange"
        work-order-type="Internal"
        :workOrderId="workOrder.id"
        />
    </div>
    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew && workOrder.typeText?.includes('OrderMaterial')">
      <WorkOrderMaterials
        :canChange="workOrder.canChange"
        work-order-type="InternalOrder"
        :workOrderId="workOrder.id" />
    </div>
    <div class="h-100 p-4 bg-light border rounded-3 mb-3" v-if="!workOrder.isNew && workOrder.typeText?.includes('OrderMaterial')">
      <div class="row">
        <h4 class="col-2">Priloge</h4>
        <div class="col-10 text-end">
          <button class="btn btn-warning btn-sm ms-1 me-2" @click="showUpload = true" v-show="workOrder.canChange">Naloži datoteko</button>
          <button class="btn btn-secondary btn-sm ms-1 me-2 mb-2" @click="showAttachFromCamera = true" v-show="workOrder.canChange">Naloži fotografijo</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <Attachments :attachments="attachments" />
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-2">
      <button class="btn btn-danger btn-lg me-3" type="button" v-show="!workOrder.isNew && (workOrder.canDelete || isAdmin) && workOrder?.statusText !== 'Deleted'" @click="deleteOrderAsync">Izbriši</button>
    </div>
    <div class="col-10 text-end">
      <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Service' })">Prekliči</button>
      <button class="btn btn-outline-success btn-lg me-3" type="button" v-show="!workOrder.isNew && workOrder.canComplete" @click="markCompletedLocalAsync">Označi kot opravljeno</button>
      <button class="btn btn-success btn-lg me-3" type="button" :disabled="!workOrder.canChange" @click="saveInternalOrderAsync">Shrani</button>
    </div>
  </div>

  <ChangeHistory v-model="workOrder" v-show="!workOrder.isNew" />

  <Upload :visible="showUpload"
    @close="showUpload = false"
    @add="e => { addAttachment(e) }"
    type="InternalOrder"
    :id="workOrder.id" />
  <AttachFromCamera :visible="!!showAttachFromCamera"
    @close="showAttachFromCamera = false"
    @add="e => { addAttachment(e) }"
    type="ServiceOrder"
    :id="workOrder.id" />
</div>
`,
  setup (props, { emit }) {
    const {
      workOrder,
      attachments,
      addAttachment,
      saveWorkOrderAsync,
      deleteOrderAsync,
      markCompletedAsync
    } = useWorkOrder("Internal")

    const router = VueRouter.useRouter()
    const { isAdmin } = useIsAuthorized()
    const showUpload = ref(false)
    const showAttachFromCamera = ref(false)
    const { internalOrderTypes, loadEnumsAsync } = useEnums()

    const saveInternalOrderAsync = async () => {
      await saveWorkOrderAsync()
      if (workOrder.value.statusText === 'Completed') {
        router.push({  name: 'Service', params: { keepMessages: true } })
      }
    }

    onMounted(async () => {
      startLoading('InternalOrders')
      try {
        await loadEnumsAsync()
      } catch (e) {
        setError(e)
      }
      stopLoading('InternalOrders')
    })

    const markCompletedLocalAsync = async () => {
      let result = await markCompletedAsync(true /* skipSuccessRoute */)
      if (result) {
        router.push({ name: "Home", params: { keepMessages: true } })
      }
    }

    return {
      isAdmin,
      showUpload,
      showAttachFromCamera,
      workOrder,
      attachments,
      addAttachment,
      saveInternalOrderAsync,
      internalOrderTypes,
      deleteOrderAsync,
      markCompletedLocalAsync,
    }
  }
}

export default InternalOrder

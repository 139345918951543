/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading } from "../components/Notification"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useEnums } from "../composition-api/useEnums"
import { SearchMaterial } from "scripts/components/SearchMaterial"

const Service = {
  name: "Service",
  components: { SearchMaterial },
  template: `
    <div class="row mb-3" v-show="isAuthenticated">
      <div class="col">
        <button class="btn btn-primary me-3" type="button" @click="$router.push({ name: 'ServiceOrder', params: { workOrderId: 'new', facilityID: filters.facility, customerID: filters.customer } })">Nov servisni nalog</button>
        <button class="btn btn-info me-3" type="button" @click="$router.push({ name: 'InternalOrder', params: { workOrderId: 'new' } })">Nov interni nalog</button>
        <button class="btn btn-link" type="button" @click="$router.push({ name: 'ServiceOrdersMap' })">Zemljevid 🚩 aktivnih servisnih nalogov</button>
        <button class="btn btn-link" type="button" @click="downloadAttachmentsAsync('pdf')" :disabled="!orders?.length" v-show="worker.isAdmin.value">Prenesi PDF priloge</button>
        <button class="btn btn-link" type="button" @click="downloadAttachmentsAsync('leak')" :disabled="!orders?.length" v-show="worker.isAdmin.value">Prenesi preskuse</button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-8">
        <div class="row mb-2 filter-customer-entity">
          <div class="col-md-6">
          <SelectCustomerEntity
            col-class-left="col-sm-3"
            col-class-right="col-sm-9"
            :customerID="filters.customer"
            :facilityID="filters.facility"
            :canChange="true"
            @change="e => { filters.customer = e.customerID;  filters.facility = e.facilityID }" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Delavec</label>
              <div class="col-sm-9">
                <SelectEntity apiEntity="Workers" v-model="filters.ownerID" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Odprl</label>
              <div class="col-sm-9">
                <SelectEntity apiEntity="Users" v-model="filters.userCreated" /> <!--TODO-->
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Št. naloga</label>
              <div class="col-sm-9">
                <input class="form-control" type="text" v-model.lazy="filters.orderNumber" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label class="col-form-label col-sm-3">Material</label>
              <div class="col-sm-9">
                <div class="input-group">
                  <SearchMaterial @selected="e => filters.materialID = e.id" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-3">
                Prioriteta:
              </div>
              <div class="col">
                <IntegerRadioGroup v-model="filters.priority" :numberOfValues=3 />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-3">
                Garancija:
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="warranty" id="warranty1" value="true" v-model="filters.isWarranty">
                  <label class="form-check-label" for="warranty1">Z</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="warranty" id="warranty2" value="false" v-model="filters.isWarranty">
                  <label class="form-check-label" for="warranty2">Brez</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="warranty" id="warranty3" value="undefined" v-model="filters.isWarranty">
                  <label class="form-check-label" for="warranty3">Vseeno</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DateTimeFilterSet v-model="filters" />

        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-4">
                Posebnosti:
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="isSpecial" id="isSpecial1" value="true" v-model="filters.isSpecial">
                  <label class="form-check-label" for="isSpecial1">Z</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="isSpecial" id="isSpecial2" value="false" v-model="filters.isSpecial">
                  <label class="form-check-label" for="isSpecial2">Brez</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="isSpecial" id="isSpecial3" value="undefined" v-model="filters.isSpecial">
                  <label class="form-check-label" for="isSpecial3">Vseeno</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                Delni kom.:
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="hadUncompletedCommission" id="hadUncompletedCommission1" value="true" v-model="filters.hadUncompletedCommission">
                  <label class="form-check-label" for="hadUncompletedCommission1">Z</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="hadUncompletedCommission" id="hadUncompletedCommission2" value="false" v-model="filters.hadUncompletedCommission">
                  <label class="form-check-label" for="hadUncompletedCommission2">Brez</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="hadUncompletedCommission" id="hadUncompletedCommission3" value="undefined" v-model="filters.hadUncompletedCommission">
                  <label class="form-check-label" for="hadUncompletedCommission3">Vseeno</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6"><!-- TODO: duplicate -->
            <div class="row">
              <div class="col-sm-3">
                Periodika:
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="period1" v-model="filters.isLeakageTest">
                  <label class="form-check-label" for="period1">Tesnost</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="period2" v-model="filters.isService">
                  <label class="form-check-label" for="period2">Servis</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="period3" v-model="filters.isCondenserCleaning">
                  <label class="form-check-label" for="period3">Kondenzator</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-12">
            <div class="row">
              <label class="col-form-label col-sm-3">Zadeva, opis,..</label>
              <div class="col-sm-9">
                <input class="form-control" v-model.lazy="filters.searchTerm" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <SelectStatus :statuses="statuses" v-model="filters.status" @change="changeSelectStatus" :multiple="true" />
        <div class="row mt-2"><div class="col"><a class="btn btn-sm btn-outline-secondary" @click="clearStatuses">Pobriši statuse</a></div>
          <div class="col"><a class="btn btn-sm btn-secondary" @click="resetServiceFilters">Pobriši iskalne pogoje</a></div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-stripped table-sm table-hover">
            <thead>
              <tr>
                <th scope="col" class="bg-light">Št.&nbsp;naloga</th>
                <th class="d-none d-lg-table-cell bg-light">Status</th>
                <th scope="col">Št.kom.</th>
                <th scope="col">Zadeva</th>
                <th class="d-none d-lg-table-cell" scope="col">Stranka</th>
                <th scope="col">Objekt</th>
                <th scope="col">Delavec</th>
              </tr>
            </thead>
            <tbody>
              <tr role="button" v-for="order in orders">
                <td @click="selectOrder(order, true)" class="bg-light">{{ order.orderNumber }}<OrderBadge cssClass="d-block d-lg-none" :status="order.status" :dateCompleted="order.dateCompleted" :isSpecial="order.isSpecial" /></td>
                <td @click="selectOrder(order, true)" class="bg-light d-none d-lg-table-cell"><OrderBadge :status="order.status" :dateCompleted="order.dateCompleted" :isSpecial="order.isSpecial" /> </td>
                <td @click="selectOrder(order)">{{ order.commissions }} <span v-show="order.hadUncompletedCommission" class="badge bg-warning text-dark">Delni komision!</span></td>
                <td @click="selectOrder(order)">
                  {{ order.subject }} <!-- TODO duplicate -->
                  <span class="badge bg-info me-1" v-show="order.isLeakageTest">Tesnost</span>
                  <span class="badge bg-success me-1" v-show="order.isService">Servis</span>
                  <span class="badge bg-secondary" v-show="order.isCondenserCleaning">Kondenzator</span>
                </td>
                <td @click="selectOrder(order)" class="d-none d-lg-table-cell">{{ order.customerName }}</td>
                <td @click="selectOrder(order)">{{ order.facilityName }}</td>
                <td @click="selectOrder(order)">{{ order.owner }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Paging v-if="orders?.length" :paging="paging" @change="setPageNum" />
  `,
  props: {},
  setup(props, { emit }) {
    const isAuthenticated = useIsAuthenticated()
    const { worker } = useIsAuthorized()
    const router = VueRouter.useRouter()
    const route = VueRouter.useRoute()

    const getDefaultFiltersFromWorker = () => {
      if (worker.userRoleText.value === "Worker" || worker.userRoleText.value === "Subcontractor") {
        return { ownerID: worker.id.value, status: "Active,Accepted,InProgress" }
      } else {
        return { ownerID: worker.id.value, status: "Active,Accepted,InProgress,Triage" }
      }
    }

    const { paging, resetPaging, setPageNum, filters, orders, downloadAttachmentsAsync, DEFAULT_FILTERS, selectOrder } =
      useWorkOrders("Service", getDefaultFiltersFromWorker())
    const { statuses, loadEnumsAsync } = useEnums()

    /* TODO de-duplicate */
    const changeSelectStatus = (e) => {
      let s = filters.value.status.split(",")
      const existingIndex = s.findIndex((el) => el === e)
      if (existingIndex > -1) {
        s.splice(existingIndex, 1)
      } else {
        s.push(e)
      }
      filters.value.status = s.toString()
    }

    const clearStatuses = () => {
      filters.value.status = ""
    }

    // TODO: move to useWorkOrders
    const resetServiceFilters = () => {
      const { ownerID, status } = getDefaultFiltersFromWorker()
      let f = DEFAULT_FILTERS()
      f.ownerID = ownerID
      f.status = status
      filters.value = f
    }

    watch(
      () => ({ ...filters.value }),
      () => resetPaging() // will also fire getOrdersAsync()
    )

    onMounted(async () => {
      startLoading("Orders")
      try {
        await loadEnumsAsync()
        resetServiceFilters()
        if (route.params?.facilityID) {
          filters.value.facility = route.params.facilityID
        }
        if (route.params?.customerID) {
          filters.value.customer = route.params.customerID
        }
        if (route.params?.ownerID) {
          filters.value.ownerID = route.params.ownerID
        }
        if (route.params?.statuses) {
          filters.value.status = route.params.statuses
        }
      } catch (e) {
        setError(e)
      }
      stopLoading("Orders")
    })

    return {
      worker,
      paging,
      setPageNum,
      isAuthenticated,
      orders,
      statuses,
      filters,
      resetServiceFilters,
      changeSelectStatus,
      clearStatuses,
      downloadAttachmentsAsync,
      selectOrder,
    }
  },
}

export default Service

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onUnmounted } from "vue"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import FacilitiesCard from "../components/FacilitiesCard"
import InternalOrdersCard from "../components/InternalOrdersCard"
import WarehouseTransfersCard from "../components/WarehouseTransfersCard"
import WorkersCard from "../components/WorkersCard"
import Notifications from "../components/Notifications"
import ProjectsCard from "../components/ProjectsCard"
import ServiceOrdersCard from "../components/ServiceOrdersCard"

export default HomeLeaderProjects = {
  name: "HomeLeaderProjects",
  components: {
    FacilitiesCard,
    InternalOrdersCard,
    WarehouseTransfersCard,
    WorkersCard,
    Notifications,
    ProjectsCard,
    ServiceOrdersCard,
  },
  template: `
  <div>
    <div class="row">
      <div class="col-md-4">
        <Notifications />
        <InternalOrdersCard class="card mb-3"
          title="Interni nalogi"
          subTitle="Accepted, zame"
          :ownerID="worker.id.value"
          statuses="Accepted" />
      </div>
      <div class="col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Bližnjice</h5>
            <ul>
              <li>
                <button class="btn btn-link" type="button"
                  @click="$router.push({ name: 'InternalOrder', params: { workOrderId: 'new' } })">
                  Nov interni nalog
                </button>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <WorkersCard class="card mb-3"  />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <ProjectsCard class="card mb-3" />
      </div>
      <div class="col-lg-6">
        <ServiceOrdersCard class="card mb-3" title="Servisni nalogi"
          subTitle="Triage,Accepted,InProgress + zame"
          statuses="Triage,Accepted,InProgress"
          :ownerID="worker.id.value" />
      </div>
    </div>


  </div>
  `,
  setup() {
    const { worker } = useIsAuthorized()
    const workersCardEl = ref(null)

    return {
      worker,
      workersCardEl,
    }
  },
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listEntitiesAsync } from "../api"
import { EMPTY_GUID } from "../utils/constants"
import { useEnums } from '../composition-api/useEnums'
import { DeviceList } from '../components/DeviceList'

const Devices = {
  name: 'Devices',
  components: { DeviceList },
  template: `

  <div class="row mb-3">
    <div class="col">
      <button class="btn btn-primary me-3" type="button" @click="$router.push({ name: 'DeviceEdit', params: { deviceId: 'new', customerId: filters.customer, facilityId: filters.facility } })">Nova naprava</button>
      <button class="btn btn-outline-secondary" type="button" @click="exportDevices">Izvozi</button>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-4">
      <label class="form-check-label" for="is-active">Prikaži aktivne</label>
    </div>
    <div class="col-sm-8">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-active" v-model="filters.displayActive"  />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
        <SelectCustomerEntity
        :customerID="filters.customer"
        :facilityID="filters.facility"
        :canChange="true"
        @change="e => { filters.customer = e.customerID;  filters.facility = e.facilityID }"
        />
    </div>
  </div>
  <SelectEnum class="row mb-3" v-model="filters.typeText" :types="deviceTypes" label="Tip naprave" />

  <div class="row mt-5">
    <div class="col">
      <DeviceList :devices="filtered" />
    </div>
  </div>
`,
  props: {

  },
  setup() {
    const { deviceTypes, loadEnumsAsync } = useEnums()

    const DEFAULT_DEVICE_FILTERS = () => {
      return {
        displayActive: true,
        facility: EMPTY_GUID,
        customer: EMPTY_GUID,
        typeText: "all"
      }
    }

    const resetFilters = async () => filters.value = DEFAULT_DEVICE_FILTERS()
    const filters = ref(DEFAULT_DEVICE_FILTERS())

    const { instance } = useMsal()

    const { worker } = useIsAuthorized()

    const devices = ref([])

    onMounted(async () => {
      await loadEnumsAsync()
      devices.value = await listEntitiesAsync(instance, "Devices", "?variant=Full")
    })

    const exportDevices = () => {
      const exportType = exportFromJSON.types["xls"];
      const data = devices.value.map(d => {
        return {
          tip: d.typeText,
          objekt: d.facilityName,
          stNaprave: d.deviceNumber,
          dobavitelj: d.supplier,
          nalepka: d.label,
          vGaranciji: d.isWarranty ? "Da" : "Ne",
          aktivna: d.isActive ? "Da" : "Ne",
          zadnjiCO2: d.lastCO2Sensor != '0001-01-01' ? d.lastCO2Sensor : '',
          zadnjeCiscenjeKondenzatorja: d.lastCondenserCleaning != '0001-01-01' ? d.lastCondenserCleaning : '',
          zadnjiTestPuscanja: d.lastLeakageTest != '0001-01-01' ? d.lastLeakageTest : '',
          zadnjiServis: d.lastService != '0001-01-01' ? d.lastService : '',
          zadnjaKalibracijaTermometra: d.lastThermometerCalibration != '0001-01-01' ? d.lastThermometerCalibration : '',
        }
      })
      exportFromJSON({ data, fileName: "devices.xls", exportType });
    }

    const filtered = computed(() => {
      let result = (devices.value.filter(w => w.isActive === filters.value.displayActive))
      if (filters.value.typeText !== "all") {
        result = result.filter(w => w.typeText === filters.value.typeText)
      }

      if (filters.value.facility !== EMPTY_GUID) {
        return result.filter(f => f.facilityID === filters.value.facility)
      }
      if (filters.value.customer !== EMPTY_GUID) {
        return result.filter(f => f.customerID === filters.value.customer)
      }
      return result
    })


    return {
      exportDevices,
      deviceTypes,
      worker,
      devices,
      filters,
      filtered,
      resetFilters
    }
  }
}

export default Devices

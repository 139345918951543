import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from "./useMsal"
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { setError, startLoading, stopLoading } from "../components/Notification"
import { getMutex } from '../utils/simpleMutex'
import { changeWarehouseAsync } from '../api'

const _isDirty = ref(0)
const _worker = ref(null)
const _subject = ref('')
const _isAuthorizationDone = ref(false)
const _activeWarehouse = ref(null)

const isAuthorized = computed(() => _worker.value?.userRoleText?.length > 3)
const isAdmin = computed(() => _worker.value?.userRoleText === "Administrator" || _worker.value?.userRoleText.startsWith('Lead'))

const CurrentWorker = {
  _currentWorker: null,
  _currentSub: null,
  _mutex: getMutex(),
  _fatalError: false,
  async getCurrentWorker(instance, reload) {
    const [lock, release] = this._mutex.getLock()
    await lock
    if (this._currentWorker === null || reload) {
      startLoading('getCurrentWorker')
      try {
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Worker/me`, "GET")
        this._currentSub = raw.sub
        if (raw?.status !== "ok") {
          setError(raw)
        } else if (raw?.result?.id?.length == 36) {
          this._currentWorker = raw.result
          const expiryDateRaw = window.localStorage.getItem('warehouseExpiry')
          if (expiryDateRaw) {
            const currentDate = new Date()
            const expiryDate = new Date(expiryDateRaw)
            if (expiryDate.getTime() < currentDate.getTime()) {
              console.log('Clearing selected warehouse')
              window.localStorage.removeItem('warehouseID')
              window.localStorage.removeItem('warehouseExpiry')
            }
          }
          _activeWarehouse.value = this._currentWorker.warehouses.find(w => w.id === window.localStorage.getItem('warehouseID'))
          // if (!_activeWarehouse.value) {
          //   console.warn(window.localStorage.getItem('warehouseID'))
          // }
        }
        _isAuthorizationDone.value = true
      } catch (e) {
        this._fatalError = true
        setError(e)
      }
      stopLoading('getCurrentWorker')
    }
    release()
    return { currentWorker: this._currentWorker, sub: this._currentSub }
  }
}

export function useIsAuthorized() {
  const { instance, accounts } = useMsal()

  const loadCurrentWorkerAsync = async (reload = false) => {
    try {
      const { currentWorker, sub } = await CurrentWorker.getCurrentWorker(instance, reload)
      _subject.value = sub
      if (currentWorker?.id?.length == 36) {
        _worker.value = currentWorker

        // _activeWarehouse.value

        _isDirty.value += 1
      } else {
        _worker.value = null // isAuthorized.value = false
      }
    } catch (e) {
      setError(e)
    }
  }

  const changeActiveWarehouseAsync = async warehouseId => {
    if (typeof warehouseId !== 'string' || warehouseId?.length !== 36) {
      console.error("warehouseId should be GUID")
      return
    }
    if (_worker.value?.warehouses.length <= 0) {
      console.error("no warehouses")
      return
    }
    const warehouse = _worker.value.warehouses.find(w => w.id === warehouseId)
    if (!warehouse) {
      console.error("no warehouse by ID: " + warehouseId)
      return
    }
    console.log(_activeWarehouse.value?.id ? "changing active warehouse" : "selecting warehouse", warehouseId)
    await changeWarehouseAsync(instance, warehouseId, _activeWarehouse.value?.id)
    _activeWarehouse.value = warehouse
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(4, 0, 0, 0)
    window.localStorage.setItem('warehouseID', warehouseId)
    window.localStorage.setItem('warehouseExpiry', tomorrow)
  }

  watch(accounts, async () => {
    if (accounts.value.length) {
      await loadCurrentWorkerAsync()
    } else {
      _isAuthorizationDone.value = true
    }
  }, { immediate: true })

  return {
    isAuthorizationDone: computed(() => _isAuthorizationDone.value),
    isAuthorized,
    isAdmin,
    subject: computed(() => _subject.value),
    worker: {
      id: computed(() => _worker.value?.id),
      fullName: computed(() => _worker.value?.fullName),
      userRoleText: computed(() => _worker.value?.userRoleText), // TODO: display should be translated
      workPauseStartDateTime: computed(() => _worker.value?.workPauseStartDateTime),
      activeWarehouse: computed(() => _activeWarehouse.value),
      warehouses: computed(() => _worker.value?.warehouses),
      showMessageToWorker: computed(() => isAdmin.value || ['Leader', 'Worker'].some(role => _worker.value?.userRoleText?.startsWith(role))),
      dirtySignal: computed(() => _isDirty.value),
      canSignLeakageForm: computed(() => _worker.value?.canSignLeakageForm),
      isAdmin
    },
    changeActiveWarehouseAsync,
    loadCurrentWorkerAsync
  } // { propertyId: computed(() => _worker.propertyId) }
}

/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'


import { useMsal } from '../composition-api/useMsal'
import { loginRequest } from "../authConfig"
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

const SignInOutButton = {
  name: 'SignInOutButton',
  template: `
  <span class="badge ms-1 d-none d-lg-inline"
    v-show="!!worker.id.value"
    :class="{ 'bg-danger': worker.isAdmin.value === true,
    'bg-light': worker.isAdmin.value === false }">
    {{ worker.userRoleText.value?.substring(0, 4) }}</span>

  <button v-if="!isAuthenticated" class="btn btn-primary btn-sm ms-3" type="button" @click="loginRedirect">Prijava</button>
  <button v-else class="btn btn-outline-warning btn-sm ms-3" type="button" @click="logoutRedirect">Odjava</button>
  `,
  setup (props, { emit }) {
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()
    const isAuthenticated = useIsAuthenticated()

    const loginRedirect = () => instance.loginRedirect(loginRequest)

    const logoutRedirect = () => instance.logoutRedirect()

    return { loginRedirect, logoutRedirect, isAuthenticated, worker }
  },
}

export default SignInOutButton

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess } from "../components/Notification"
import { getEntityAsync } from "../api"

export default WarehouseEdit = {
  name: "WarehouseEdit",
  template: `
  <div class="row mt-3" v-if="entity">
    <label class="col-form-label col-sm-4">Naziv skladišča</label>
    <div class="col-sm-8">
      <input class="form-control" v-model="entity.name" :disabled="true" />
    </div>
  </div>

  <div class="row mt-3" v-if="entity">
    <label class="col-form-label col-sm-4">Reg. številka</label>
    <div class="col-sm-8">
      <input class="form-control" v-model="entity.registrationNumber" :disabled="true" />
    </div>
  </div>

  <div class="row mb-5 mt-5" v-if="entity">
    <div class="col-10 text-end">
      <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Warehouses' })">Prekliči</button>
    </div>
  </div>

  <div class="row mt-3">
    <label class="col-form-label col-sm-4">Iskanje po materialu v skladišču</label>
    <div class="col-sm-8">
      <input class="form-control" v-model="searchTerm"  />
    </div>
  </div>


  <div class="row mt-5">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm">
          <thead>
            <tr>
              <th scope="col">Naziv</th>
              <th scope="col">Kataloška št.</th>
              <th scope="col">Ident</th>
              <th scope="col">Prosto</th>
              <th scope="col">Rezervirano</th>
              <th scope="col">Prosto CE</th>
              <th scope="col">Rezervirano CE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="m in warehouseMaterialsFiltered">
              <td>{{ m.name }}
                <span class="badge badge-info" v-show="m.requiresSerialNumbers">Serijske</span>
                <span class="badge bg-warning text-dark" v-show="m.isLowValue">Drobni material</span>
              </td>
              <td>{{ m.catalogNumber }}</td>
              <td>{{ m.opPisProductID }}</td>
              <td v-show="m.isStockUpToDate">{{ m.freeStock }} {{ m.measurementUnit }}</td>
              <td v-show="m.isStockUpToDate">{{ m.reserved }} {{ m.measurementUnit }}</td>
              <td v-show="m.isStockUpToDate" class="text-muted">{{ m.freeStockCe }} {{ m.measurementUnit }}</td>
              <td v-show="m.isStockUpToDate" class="text-muted">{{ m.reservedCe }} {{ m.measurementUnit }}</td>
              <td v-show="!m.isStockUpToDate" colspan="4" class="text-muted">Zaloga ni ažurirana</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  `,
  setup (props, { emit }) {
    const route = VueRouter.useRoute()
    const { instance } = useMsal()

    const searchTerm = ref("")

    const entity = ref(null)
    const warehouseMaterials = ref([])

    const warehouseMaterialsFiltered = computed(() => {
      if (!searchTerm.value) {
        return warehouseMaterials.value
      }
      return warehouseMaterials.value.filter(m =>
        m.name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        m.opPisProductID.toLowerCase().includes(searchTerm.value.toLowerCase())
      )
    })

    const loadWarehouseAsync = async () => {
      if (!route.params.id) {
        entity.value = null
        return
      }
      entity.value = await getEntityAsync(instance, "Warehouses", route.params.id)
      if (!entity.value) {
        setError("Ne obstaja.")
      } else {
        warehouseMaterials.value = await getEntityAsync(instance, "WarehouseMaterials", route.params.id)
      }
    }

    watch(() => route.params.id, () => loadWarehouseAsync())

    onMounted(async () => await loadWarehouseAsync())

    return { entity, warehouseMaterialsFiltered, searchTerm }
  }

}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useWarehouseTransfers } from '../composition-api/useWarehouseTransfers'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { EMPTY_GUID } from "../utils/constants"

export default WarehouseTransfersCard = {
  name: 'WarehouseTransfersCard',
  template: `<div>
  <div class="card-body">
    <h5 class="card-title">Nalogi za prenos. <small class="text-muted">{{ subTitle }}</small></h5>
    <ul>
      <li v-for="wt in warehouseTransfers" class="mt-1">
        <router-link :to="{ name: 'WarehouseTransferEdit', params: { type: wt.typeText, id: wt.id } }" target="_blank">{{ wt.number }}</router-link> <OrderBadge :status="wt.statusText" :dateCompleted="wt.dateCompleted" /> [{{ wt.noOfMaterials }}]  {{ wt.comment }}
      </li>
    </ul>
    <p v-show="warehouseTransfers.length === 0">Ni nalogov za prenos med skladišči.</p>
    <p v-show="paging.itemsPerPage === warehouseTransfers.length">
      <router-link :to='{ name: "WarehouseTransfers"}'>Več zadetkov</router-link>
    </p>
  </div>
</div>`,
  props: {
    subTitle: {
      type: String,
    },
    toWarehouseID : {
      type: String,
      default: EMPTY_GUID
    },
    statuses : {
      type: String,
      default: 'all'
    },
    itemsPerPage: {
      type: Number,
      default: 25
    }
  },
  setup(props) {
    const { resetPaging, filters, entities: warehouseTransfers, paging } = useWarehouseTransfers()

    watch(
      () => ({ ...filters.value }),
      () => resetPaging()  // will also fire getOrdersAsync()
    )

    onMounted(async () => {
      filters.value.status = props.statuses
      filters.value.toWarehouseID = props.toWarehouseID
      paging.value.itemsPerPage = props.itemsPerPage
      resetPaging()
    })

    return {
      paging,
      warehouseTransfers
    }

  }
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { EMPTY_GUID, DATETIME_FORMAT, MAX_MINUTES_TIME_ENTRY } from '../utils/constants'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { setError, startLoading, stopLoading, setSuccess } from "./Notification"
import { sortTimeEntries, ensureTransportEntryExtensions } from '../utils/timeEntryExtensions'
import { WorkOrderTransportsActions } from './WorkOrderTransportsActions'
import { insertNewTransportEntryAsync, getTransportAsync, saveWorkOrderTransportAsync  } from '../api'

const WorkOrderTransports = {
  components: { WorkOrderTransportsActions },
  template: `
  <div class="row">
    <h4 class="col-2">Prevoz</h4>
    <div class="col-10 text-end">
      <button class="btn btn-success btn-sm ms-1 me-2"
        @click="newTransportEntryAsync(workOrderType, workOrderId, worker.activeWarehouse.value.id, true, defaultStartTimeObject)"
        v-show="!openTransportRecord && canChange">Začni pot z lokacijo
        <strong v-if="defaultStartTimeObject">{{ defaultStartTimeObject.local().format('HH:mm') }}</strong></button>
      <button class="btn btn-outline-success btn-sm"
        @click="newTransportEntryAsync(workOrderType, workOrderId, worker.activeWarehouse.value.id, false, defaultStartTimeObject)"
        v-show="!openTransportRecord && canChange">Začni pot
        <strong v-if="defaultStartTimeObject && worker.isAdmin.value">{{ defaultStartTimeObject.local().format('HH:mm') }}</strong>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col d-block d-md-block d-xl-none">
      <div class="card mb-3"
        v-for="transportEntry in transportEntries.filter(te => te.isForDelete !== true)"
        @click="transportEntryForActions = transportEntry" role="button"
        :key="transportEntry.id"
        :class="{ 'border-info': transportEntry.isNew, 'border-light': transportEntry.isDirty, 'bg-warning': !transportEntry.isComplete }">
        <div class="card-body">
          <p class="card-text">
            <DisplayEntity apiEntity="Workers" v-model="transportEntry.workerId" />
            <span v-show="transportEntry.isPassenger"> Sopotnik</span>
          </p>
          <p class="card-text">
            {{ transportEntry.startDateTimeObject?.local().format($DATE_FORMAT_DISPLAY) }}
            <small>{{ transportEntry.startDateTimeObject?.local().format('HH:mm') }}</small>
            <span v-show="!!transportEntry.endDateTimeObject">⟶
              {{ transportEntry.endDateTimeObject?.local().format('D.M.') }}
              <small>{{ transportEntry.endDateTimeObject?.local().format('HH:mm') }}</small>
            </span>&nbsp;
             <strong class="mb-3"> {{ transportEntry.difference }}</strong>
          </p>
          <p class="card-text">
            <span class="me-2" v-show="transportEntry.hasStartLocation">
              <a class="me-2" :href="'http://www.google.com/maps/place/' + transportEntry.startLat + ',' + transportEntry.startLng" target="_blank">Začetek</a>
              <a v-show="transportEntry.hasEndLocation" :href="'http://www.google.com/maps/place/' + transportEntry.endLat + ',' + transportEntry.endLng" target="_blank">Konec</a>
            </span>
            <span>{{ transportEntry.relation }}</span>
          </p>
          <p class="card-text"><DisplayEntity apiEntity="Vehicles" v-model="transportEntry.vehicleId" /></p>
          <p class="card-text"> {{ transportEntry.comments }}</p>
        </div>
      </div>
    </div>
    <div class="col d-none d-xl-block">
      <div class="table-responsive">
        <table class="table table-sm" v-show="transportEntries?.length">
          <thead>
            <tr>
              <th>Delavec</th>
              <th>Odhod</th>
              <th>Prihod</th>
              <th>Trajanje</th>
              <th>Relacija</th>
              <th>Vozilo</th>
              <th>Os. km</th>
              <th>Opomba</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="transportEntry in transportEntries.filter(te => te.isForDelete !== true)"
                :key="transportEntry.id"
                :class="{
                    'table-active': transportEntry.isNew,
                    'table-info': transportEntry.isDirty,
                    'table-warning': !transportEntry.isComplete }">
                <td @click="transportEntryForActions = transportEntry" role="button">
                  <DisplayEntity apiEntity="Workers" v-model="transportEntry.workerId" />
                  <em v-show="transportEntry.isPassenger"> Sopotnik</em>
                </td>
                <td @click="transportEntryForActions = transportEntry" role="button">{{ transportEntry.startDateTimeObject?.local().format($DATETIME_FORMAT_DISPLAY) }}</td>
                <td @click="transportEntryForActions = transportEntry" role="button">{{ transportEntry.endDateTimeObject?.local().format($TIME_FORMAT_DISPLAY) }}</td>
                <td @click="transportEntryForActions = transportEntry" role="button">{{ transportEntry.difference }}</td>
                <td>
                  <span class="me-2" v-show="transportEntry.hasStartLocation">
                    <a class="me-2" :href="'http://www.google.com/maps/place/' + transportEntry.startLat + ',' + transportEntry.startLng" target="_blank">Začetek</a>
                    <a v-show="transportEntry.hasEndLocation" :href="'http://www.google.com/maps/place/' + transportEntry.endLat + ',' + transportEntry.endLng" target="_blank">Konec</a>
                  </span>
                  <span>{{ transportEntry.relation }}</span>
                </td>
                <td @click="transportEntryForActions = transportEntry" role="button"><DisplayEntity apiEntity="Vehicles" v-model="transportEntry.vehicleId" /></td>
                <td @click="transportEntryForActions = transportEntry" role="button"><span v-show="!transportEntry.hasEndLocation && transportEntry.distanceKm">{{ transportEntry.distanceKm }}km</span></td>
                <td @click="transportEntryForActions = transportEntry" role="button">{{ transportEntry.comments }}</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <WorkOrderTransportsActions
    v-model="transportEntryForActions"
    @close="e => { if (e === true) { loadTransportEntriesAsync(workOrderType, workOrderId) }; transportEntryForActions = null }"
    @finished="e => $emit('finished', e)"
    :canChange="canChange"
    :workOrderType="workOrderType"
    :workOrderId="workOrderId" />
`,
  emits: ["finished"],
  props: {
    canChange: { type: Boolean },
    workOrderType: { type: String, required: true },
    workOrderId: { type: String },
    defaultStartTime: { type: String }
  },
  setup(props, { emit }) {
    const { worker } = useIsAuthorized()
    const { loadTransportEntriesAsync, newTransportEntryAsync, transportEntries } = useTransportEntries()

    const defaultStartTimeObject = computed(() => {
      if (props.defaultStartTime)  {
        return dayjs.utc(props.defaultStartTime)
      }
      return null
    })

    const transportEntryForActions = ref(null)

    const openTransportRecord = computed(() => transportEntries.value.find(t => !t.endDateTime))

    watch(() => props.workOrderId, async newId => {
      if (newId) {
        await loadTransportEntriesAsync(props.workOrderType, props.workOrderId)
      }
    }, { immediate: true })

    return {
      worker,
      transportEntries,
      loadTransportEntriesAsync,
      newTransportEntryAsync,
      transportEntryForActions,
      openTransportRecord,
      defaultStartTimeObject
    }
  }
}

const useTransportEntries = () => {
  const { instance } = useMsal()

  const transportEntries = ref([])

  const getLocationAsync = inject('getLocationAsync')

  const loadTransportEntriesAsync = async (workOrderType, workOrderId)  => {
    if (!workOrderType) {
      throw new Error('missing work order type')
    }
    if (!workOrderId) {
      throw new Error('missing workOrderId')
    }
    const result = await getTransportAsync(instance, workOrderType, workOrderId)
    transportEntries.value =  sortTimeEntries(result)
    populateTransportExtensions()
  }

  const newTransportEntryAsync = async (workOrderType, workOrderId, warehouseId, withLocation, startTimeObject) => {
    let location = null

    try {
      if (withLocation) {
        location = await getLocationAsync()
      }
      const te = await insertNewTransportEntryAsync(instance, workOrderType, workOrderId, warehouseId, location, startTimeObject)
      if (te) {
        await loadTransportEntriesAsync(workOrderType, workOrderId)
        setSuccess("Prevoz začet.", true /* skip scroll */)
        return te.startDateTime
      }
    } catch (err) {
      setError(err)
    }
    return false
  }

  const populateTransportExtensions = () => {
    transportEntries.value = transportEntries.value.map(transportEntry => {
      ensureTransportEntryExtensions(transportEntry)
      return transportEntry
    })
  }

  const openTransportRecord = computed(() => transportEntries.value.filter(t => !t.endDateTime).length)

  return {
    loadTransportEntriesAsync,
    newTransportEntryAsync,
    openTransportRecord,
    populateTransportExtensions,
    transportEntries,
  }
}

export { WorkOrderTransports, useTransportEntries }

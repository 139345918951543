import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { EMPTY_GUID, DATETIME_FORMAT, MAX_MINUTES_TIME_ENTRY } from "../utils/constants"
import { useMsal } from "../composition-api/useMsal"
import { setError, startLoading, stopLoading, setSuccess, setWarning } from "./Notification"
import { useSerialNumbers } from "../composition-api/useSerialNumbers"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { MaterialBatchList } from "./MaterialBatchList"
import { saveMaterialAsync, orderMaterialAsync } from "../api"

const WorkOrderMaterialActions = {
  components: { MaterialBatchList },
  template: `
  <Modal :title="'Akcije za material'" :visible="!!localEntry" @close="$emit('close')">

    <div class="alert alert-warning" role="alert" v-show="localEntry?.requiresSerialNumbers">
      Material zahteva serijske številke ({{ numberOfSerialNumbers }} / {{ localEntry?.quantity }})
      {{ localEntry?.isBatchSerial ? " (Šarža)" : "" }}
    </div>

    <div class="alert alert-danger" role="alert" v-show="localEntry?.requiresSerialNumbers && !serialNumbersValid">
      Neveljavne serijske
    </div>

    <div class="row">
      <div class="col" v-if="localEntry">
        <h3>{{ localEntry.materialName }}</h3>
        <div class="row p-2">
          <div class="col-lg-6">

            <div class="row mb-1">
              <p class="col-sm-6">Ident: <strong>{{ localEntry.opPisProductID }}</strong></p>
              <p class="col-sm-6" v-show="localEntry.catalogNumber">Kataloška: <strong>{{ localEntry.catalogNumber }}</strong></p>
            </div>

            <div class="row mb-3" v-if="showNotPartOfOffer">
              <label class="col-form-label col">Ni del ponudbe</label>
              <div class="col">
                <input type="checkbox" v-model="localEntry.notPartOfOffer" :disabled="!canChange" />
              </div>
            </div>

            <div class="row mb-3" v-if="showWarehouse">
              <div class="col">
                <SelectEntity apiEntity="Warehouses"
                  v-model="localEntry.warehouseID"
                  :limit="1000"
                  :canChange="canChange && worker.isAdmin.value" />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <div class="input-group">
                <input class="form-control"
                  placeholder="Količina"
                  id="quantity"
                  type="text"
                  pattern="[0-9.]+"
                  v-model="localEntry.quantity"
                  :disabled="!canChange" />
                  <span class="input-group-text" v-show="!localEntry?.isBatchSerial">{{ localEntry.measurementUnit }}</span>
                  <span class="input-group-text" v-show="localEntry?.isBatchSerial">Embalaž</span>
                </div>
              </div>
            </div>

            <MaterialBatchList
              :material="localEntry"
              v-model="batchSerialNumbers"
            />

          </div>
          <div class="col-lg-6 p-1">
            <MaterialWarehouses :materialID="localEntry.materialID" />

            <pre v-if="$window.CONTEXT === 'stage'">
Debug (vidno samo na stage):
{{ localEntry.serialNumbers }}
            </pre>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:buttons>
      <button class="btn btn-primary me-5 ms-3" type="button" :disabled="!localEntry" @click="orderMaterial()" v-show="showOrderMaterial">Naroči material</button>
      <button class="btn btn-danger me-5" type="button" :disabled="!localEntry || !canDeleteMaterial || !canChange" @click="saveAsync(true)">Izbriši material <span class="d-none d-sm-inline">iz naloga</span></button>
      <button class="btn btn-success me-3" type="button" :disabled="!localEntry || !canChange || (false && localEntry.requiresSerialNumbers && !serialNumbersValid)" @click="saveAsync(false)">Shrani</button>
    </template>
  </Modal>
  `,
  emits: ["update:modelValue", "close"],
  props: {
    modelValue: { type: Object },
    canChange: Boolean,
    workOrderType: { type: String, required: true },
    workOrderId: { type: String },
    showWarehouse: { type: Boolean, default: true },
    showOrderMaterial: { type: Boolean, default: false },
    showNotPartOfOffer: { type: Boolean, default: false },
    canDeleteMaterial: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const localEntry = ref(null)
    const { batchSerialNumbers, numberOfSerialNumbers, serialNumbersValid, initializeWatchers } =
      useSerialNumbers(localEntry)
    initializeWatchers()

    watch(
      () => props.modelValue,
      (curr, next) => {
        if (curr === null) {
          localEntry.value = null
        } else {
          const material = { ...curr }
          localEntry.value = material
        }
      }
    )

    const saveAsync = async (isForDelete = false) => {
      if (isForDelete) {
        localEntry.value.isForDelete = true
      } else {
        localEntry.value.isDirty = true
      }
      let result = await saveMaterialAsync(instance, localEntry.value, props.workOrderType, props.workOrderId)
      if (result) {
        setSuccess(isForDelete ? "Material zbrisan." : "Material shranjen.", true)
      } else {
        setWarning("Ni sprememb!")
      }
      emit("close", true)
      localEntry.value = null
    }

    const orderMaterial = async () => {
      let result = await orderMaterialAsync(instance, localEntry.value, props.workOrderType, props.workOrderId)
      if (result) {
        setSuccess("Naročeno.")
        emit("close", true)
      }
    }

    return {
      localEntry,
      saveAsync,
      numberOfSerialNumbers,
      serialNumbersValid,
      worker,
      orderMaterial,
      batchSerialNumbers,
    }
  },
}

export { WorkOrderMaterialActions }

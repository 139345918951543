const DateTimeFilterSet = {
  template: `
  <div class="row mb-2">
    <DateTimeInput class="col-md-6"
      label-text="Ustvarjen od"
      v-model="modelValue.createdFrom"  />
    <DateTimeInput class="col-md-6"
      label-text="Ustvarjen do"
      v-model="modelValue.createdTo"  />
  </div>
  <div class="row mb-2" v-show="modelValue.status.includes('Completed')">
    <DateTimeInput class="col-md-6"
      label-text="Končan od"
      v-model="modelValue.completedFrom"  />
    <DateTimeInput class="col-md-6"
      label-text="Končan do"
      v-model="modelValue.completedTo"  />
  </div>
  <div class="row mb-2" v-show="modelValue.status.includes('Finalized')">
    <DateTimeInput class="col-md-6"
      label-text="Zaključen od"
      v-model="modelValue.finalizedFrom"  />
    <DateTimeInput class="col-md-6"
      label-text="Zaključen do"
      v-model="modelValue.finalizedTo"  />
  </div>
  `,
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Object },
  }
}

export default DateTimeFilterSet

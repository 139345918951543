import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

export default WorkOrdersList = {
  template: `
  <div class="row mb-5" v-if="worker.isAdmin.value">
    <div class="col">
      <div class="row">
        <label class="col-form-label col-sm-3">Delavec (za dodeljevanje)</label>
        <div class="col-sm-9">
          <SelectEntity apiEntity="Workers" v-model="selectedWorker" />
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-stripped table-sm">
      <thead>
        <tr>
          <th>Številka</th>
          <th>Status</th>
          <th scope="col">Zadeva</th>
          <th scope="col">Objekt</th>
          <th scope="col">Delavec</th>
          <th scope="col">Akcije</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in workOrders">
          <td @click="selectOrder(order, true)" class="bg-light">{{ order.orderNumber }}</td>
          <td><OrderBadge :status="order.status" :dateCompleted="order.dateCompleted" :isSpecial="order.isSpecial" /></td>
          <td>{{ order.subject }} <!-- TODO duplicate -->
                  <span class="badge bg-danger me-1" v-show="order.isSpecial">Posebnosti</span>
                  <span class="badge bg-info me-1" v-show="order.isLeakageTest">Tesnost</span>
                  <span class="badge bg-success me-1" v-show="order.isService">Servis</span>
                  <span class="badge bg-secondary" v-show="order.isCondenserCleaning">Kondenzator</span>
          </td>
          <td>{{ order.facilityName }}</td>
          <td>{{ order.owner }}</td>
          <td>
            <button class="btn btn-sm btn-outline-success"
            v-show="!worker.isAdmin.value && worker.fullName?.value !== order.owner"
            @click="$emit('takeOwnership', order)">Prevzemi</button>

            <button class="btn btn-sm btn-outline-success"
            v-show="worker.isAdmin.value && selectedWorker"
            @click="$emit('assignTo', { order, selectedWorker })">Dodeli</button>
          </td>
        </tr>
    </tbody>
    </table>
  </div>
  `,
  emits: ['takeOwnership', 'assignTo'],
  props: {
    workOrders: { type: Array, default: [] },
  },
  setup(props, { emit }) {
    const { worker } = useIsAuthorized()
    const router = VueRouter.useRouter()
    const selectedWorker = ref(null )
    const selectOrder = (order, openInNewWindow = false) => {
      // TODO: duplicate
      const routeData = router.resolve({ name: 'ServiceOrder', params: { workOrderId: order.id } })
      if (openInNewWindow) {
        window.open(routeData.href, "_blank")
      } else {
        router.push(routeData)
      }
    }

    return { worker, selectOrder, selectedWorker }
  }
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listEntitiesAsync } from '../api'

const Warehouses = {
  name: "Warehouses",
  template: `
  <div class="row">
    <div class="col-sm-4">
      <label class="form-label" for="is-active">Prikaži aktivne</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-active" v-model="displayActive"  />
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Naziv skladišča</th>
              <th scope="col">Reg. številka</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" @click="select(w)" v-for="w in entities.filter(w => w.isActive === displayActive)">
              <td>{{ w.name }}</td>
              <td>{{ w.registrationNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  `,
  setup (props, { emit }) {
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const displayActive = ref(true)
    const entities = ref([])

    const select = entity => router.push({ name: 'WarehouseEdit', params: { id: entity.id } })

    onMounted(async () => {
      entities.value = await listEntitiesAsync(instance, "Warehouses", "?variant=Full")
    })

    return { displayActive, entities, select, worker }
  }

}

export default Warehouses

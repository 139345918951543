import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

const SelectWarehouse = {
  name: 'SelectWarehouse',
  template: `
  <div :class="cssClass">
      <h5>Izberi dnevno skladišče</h5>
      <div class="form-check text-white" v-for="w in worker.warehouses.value">
        <input
          class="form-check-input"
          type="radio"
          name="warehouses"
          :id="w.id"
          :checked="w.id === worker.activeWarehouse.value?.id"
          @change="changeActiveWarehouseAsync(w.id)"
          />
        <label class="form-check-label" :for="w.id"><strong v-if="w.isPrimary">{{ w.name }}</strong><span v-else>{{ w.name }}</span></label>
      </div>
    </div>
  `,
  props: {
    cssClass: String,
  },
  setup() {
    const { worker, changeActiveWarehouseAsync } = useIsAuthorized()

    onMounted(() => {
      if(worker.warehouses.value.length === 1) {
        changeActiveWarehouseAsync(worker.warehouses.value[0].id)
      }
    })

    return { worker, changeActiveWarehouseAsync }
  }

}
export default SelectWarehouse

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { getEntityAsync } from "../api"

const MaterialWarehouses = {
  name: 'MaterialWarehouses',
  template: `
  <div>
    <div class="alert alert-info" role="alert" v-show="noStock">
      Ni zaloge
    </div>
    <div class="table-responsive p-3 bg-light rounded" v-show="warehouses.length > 0">
      <table class="table table-stripped table-sm">
        <thead>
          <tr>
            <th scope="col">Skladišče</th>
            <th scope="col">Prosta</th>
            <th scope="col">Rezervirana</th>
            <th scope="col">020 prosto</th>
            <th scope="col">021 namensko</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="wm in warehouses">
            <td>{{ wm.name }}</td>
            <td v-show="wm.isStockUpToDate">{{ wm.freeStock }}</td>
            <td v-show="wm.isStockUpToDate">{{ wm.reserved }}</td>
            <td v-show="wm.isStockUpToDate">{{ wm.quantityOrderedStock }}</td>
            <td v-show="wm.isStockUpToDate">{{ wm.quantityOrderedReserved }}</td>
            <td v-show="!wm.isStockUpToDate" colspan="4" class="text-muted">Zaloga ni ažurirana</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  `,
  props: {
    materialID: { type: String, required: true },
  },
  setup(props) {
    const { instance } = useMsal()

    const warehouses = ref([])
    const noStock = ref(false)

    watch(() => props.materialID, () => loadWarehousesAsync())

    const loadWarehousesAsync = async () => {
      if (!props.materialID) {
        console.warn("No materialID")
        return
      }
      warehouses.value = await getEntityAsync(instance, "Material", props.materialID, "/Warehouses")
      noStock.value = warehouses.value.length === 0
    }

    onMounted(async () => await loadWarehousesAsync())

    return { warehouses, noStock }
  }
}

export default MaterialWarehouses

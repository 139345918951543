import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "../composition-api/useMsal"
import { setError, startLoading, stopLoading, setSuccess, setWarning } from "./Notification"
import { WorkOrderMaterialActions } from "./WorkOrderMaterialActions"
import BomTemplates from "../components/BomTemplates"
import SelectMaterial from "../components/SelectMaterial"
import MaterialsList from "../components/MaterialsList"
import { getMaterialsAsync, saveMaterialsAsync } from "../api"

export const ProjectMaterials = {
  components: { WorkOrderMaterialActions, BomTemplates, SelectMaterial, MaterialsList },
  template: `
<div class="row">
  <h4 class="col-4">Material za projekte</h4>
  <div class="col text-end">
    <button type="button" class="btn btn-success btn-sm ms-1 me-2 mb-2" @click="asyncAddMaterialsCommission">Zahtevaj komisioniranje</button>
    <button type="button" class="btn btn-info btn-sm me-2 mb-2" v-show="canChange" @click="showBomTemplates = true"><span class="d-none d-md-inline">Dodaj material</span> iz predloge</button>
    <button type="button" class="btn btn-warning btn-sm me-2 mb-2" v-show="canChange" @click="sectionForAddMaterial = { order: 1000, name: 'Ročno dodan' }">Dodaj <span class="d-none d-md-inline">material</span></button>
  </div>
</div>

<div class="row" v-if="materials?.length">
  <div class="col d-block d-md-none"><!-- for delete -->
    <div class="card mb-2 mt-2" v-for="m in materials.filter(m => !m.isForDelete)">
      <div class="card-body" role="button" @click="selectMaterial(m)">
        <h6 class="card-subtitle"><span class="fw-light">{{ m.quantity }}{{ m.measurementUnit }}</span> {{ m.materialName }}</h6>
      </div>
    </div>
  </div>
  <div class="col d-none d-md-block d-lg-block">
    <table class="table table-sm table-hover table-sticky-header" v-show="materials.filter(m => m.isForDelete !== true).length">
      <thead class="thead-light bg-light">
        <tr>
          <th></th>
          <th>Komisioniranje</th>
          <th class="sortable"
            :class="{ asc: sort.by === 'section' && sort.dir === 'asc', desc: sort.by === 'section' && sort.dir === 'desc'  }"
            @click="sort.by = 'section'; sort.dir = sort.dir === 'desc' ? 'asc' : 'desc'"
          >Sekcija</th>
          <th class="sortable"
            :class="{ asc: sort.by === 'materialName' && sort.dir === 'asc', desc: sort.by === 'materialName' && sort.dir === 'desc'  }"
            @click="sort.by = 'materialName'; sort.dir = sort.dir === 'desc' ? 'asc' : 'desc'"
          >Naziv artikla</th>
          <th class="sortable"
            :class="{ asc: sort.by === 'opPisProductID' && sort.dir === 'asc', desc: sort.by === 'opPisProductID' && sort.dir === 'desc'  }"
            @click="sort.by = 'opPisProductID'; sort.dir = sort.dir === 'desc' ? 'asc' : 'desc'"
          >Ident</th>
          <th>Predvidena poraba za projekt</th>
          <th>Do predvidene porabe</th>
          <th>Prosta zaloga skladišča CE</th>
          <th>Komisionirano 055</th>
          <th>Komision v pripravi</th>
          <th>Osebni prevzem 050</th>
          <th>Osebno vračilo 050</th>
          <th>Skupaj izdano</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="m in materials.filter(m => !m.isForDelete)" :class="{
          clickable: canChange
        }">
          <td>
            <span class="badge bg-warning" v-show="m.commissionedQuantity - m.commissionCompletedQuantity > 0">KOM</span>
            <span class="badge bg-danger" v-show="(m.commissionCompletedQuantity + m.deliveredNormalQuantity) > m.quantity">IZD</span>
            <span class="badge bg-primary" v-show="m.hasReplacment">NAD-</span>
            <span class="badge bg-success" v-show="m.isReplacing">NAD+</span>
            <span class="badge bg-info" v-show="m.requiresSerialNumbers && m.serialNumbers">SER</span>
            <span class="badge bg-danger text-dark" v-show="m.requiresSerialNumbers && !m.serialNumbers">SER!</span>
            <span class="badge bg-secondary" v-show="m.orderedQuantity > 0">NAR</span>
          </td>
          <td><input type="number" v-model="m.commissionQuantity" class="form-control form-control-sm" style="width: 100px"></td>
          <td @click="selectMaterial(m)">{{ m.sectionName }}</td>
          <td @click="selectMaterial(m)">{{ m.materialName }}</td>
          <td @click="selectMaterial(m)">{{ m.opPisProductID }}</td>
          <td @click="selectMaterial(m)"><span class="badge bg-secondary">{{ m.quantity }}</span></td>
          <td @click="selectMaterial(m)">{{ -(m.quantity - (m.commissionCompletedQuantity + m.deliveredNormalQuantity)) }}</td>
          <td @click="selectMaterial(m)"><span v-show="m.isStockUpToDate">{{ m.stockCe - m.reservedCe }}</span></td>
          <td @click="selectMaterial(m)">[🚚{{ m.commissionCompletedQuantity - m.totalReplacementQuantity }}] </td>
          <td @click="selectMaterial(m)">{{ (m.commissionedQuantity - m.commissionCompletedQuantity) > 0 ? (m.commissionedQuantity - m.commissionCompletedQuantity) : 0 }}</td>
          <td @click="selectMaterial(m)">{{ m.deliveredNormalQuantityPlus }}</td>
          <td @click="selectMaterial(m)">{{ -m.deliveredNormalQuantityMinus }}</td>
          <td @click="selectMaterial(m)">{{ m.commissionCompletedQuantity + m.deliveredNormalQuantity - m.totalReplacementQuantity  }} {{ m.measurementUnit }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<BomTemplates
  :visible="showBomTemplates"
  :quantity="quantity"
  @close="showBomTemplates = false"
  @add="asyncAddMaterials" />

<WorkOrderMaterialActions
  v-model="materialForActions"
  @close="e => { if (e === true) { loadMaterialsAsync(workOrderType, workOrderId); }; materialForActions = null }"
  :canChange="canChange"
  :showWarehouse="false"
  :workOrderType="workOrderType"
  :workOrderId="workOrderId"
  :showOrderMaterial="true"
  :showNotPartOfOffer="false"
  :canDeleteMaterial="true"
  />

<SelectMaterial
  v-if="canChange"
  :quantity="quantity"
  :useBarcode="true"
  :serialsRequired="false"
  :section="sectionForAddMaterialBarcode"
  :visible="!!sectionForAddMaterialBarcode"
  @close="sectionForAddMaterialBarcode = null"
  @add="asyncAddMaterials" />

<SelectMaterial
  v-if="canChange"
  :quantity="quantity"
  :serialsRequired="false"
  :section="sectionForAddMaterial"
  :visible="!!sectionForAddMaterial"
  @close="sectionForAddMaterial = null"
  @add="asyncAddMaterials" />

`,
  emits: ["changeSelection", "commissioned"],
  props: {
    canChange: Boolean,
    workOrderType: { type: String, required: true },
    workOrderId: { type: String },
    quantity: { type: Number, default: 1 },
    enableBarcode: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const materialForActions = ref(null)
    const sectionForAddMaterial = ref(null)
    const sectionForAddMaterialBarcode = ref(null)
    const showBomTemplates = ref(false)
    const materials = ref([])
    const sort = ref({ by: "", dir: "" })

    const loadMaterialsAsync = async (materialRelation, relationId) => {
      materials.value = await getMaterialsAsync(instance, materialRelation, relationId, sort.value)
    }

    const asyncAddMaterials = async (m) => {
      const materialsToAdd = []
      m.forEach((m) => {
        const found = materials.value.find((e) => e.materialID === m.materialID)
        if (!found) {
          m.isNew = true
          materials.value.push(m)
          materialsToAdd.push(m)
        } else if (!found.isForDelete) {
          found.quantity += m.quantity
          found.isDirty = true
          materialsToAdd.push(found)
        } else {
          found.quantity = m.quantity
          found.isDirty = true
          found.isForDelete = false
          materialsToAdd.push(found)
          console.warn("adding deleted material")
        }
      })
      const result = await saveMaterialsAsync(instance, materialsToAdd, props.workOrderType, props.workOrderId)
      if (result) {
        await loadMaterialsAsync(props.workOrderType, props.workOrderId)
        setSuccess("Material dodan..", true)
        showBomTemplates.value = false
      } else {
        console.error("materials not saved.")
      }
    }

    const asyncAddMaterialsCommission = async () => {
      const commissionMaterials = JSON.parse(JSON.stringify(materials.value))
      if (commissionMaterials.length === 0) {
        return
      }
      commissionMaterials.forEach((m) => {
        m.quantity = m.commissionQuantity
        m.isNew = true
      })
      const result = await saveMaterialsAsync(
        instance,
        commissionMaterials,
        `Commission${props.workOrderType}`,
        props.workOrderId
      )
      if (result) {
        emit("commissioned")
        setSuccess("Zahtevek za komisioniranje oddan.", true)
        await loadMaterialsAsync(props.workOrderType, props.workOrderId)
      } else {
        setError("Komisioniranje ni bilo uspešno.")
      }
    }

    watch(
      () => props.workOrderId,
      async (newId) => {
        if (newId) {
          await loadMaterialsAsync(props.workOrderType, props.workOrderId)
        }
      },
      { immediate: true }
    )

    watch(
      () => sort.value,
      async (newSort) => {
        await loadMaterialsAsync(props.workOrderType, props.workOrderId)
      },
      { deep: true }
    )

    const selectMaterial = (m) => {
      if (props.canChange) {
        materialForActions.value = m
      }
    }

    return {
      materials,
      loadMaterialsAsync,
      asyncAddMaterials,
      asyncAddMaterialsCommission,
      showBomTemplates,
      materialForActions,
      sectionForAddMaterial,
      sectionForAddMaterialBarcode,
      sort,
      selectMaterial,
    }
  },
}

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { getCustomerAsync, listEntitiesAsync } from "../api"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { EMPTY_GUID } from "../utils/constants"
import { useMsal } from '../composition-api/useMsal'
import { DeviceList } from '../components/DeviceList'

const CustomerEdit = {
  name: 'CustomerEdit',
  components: { DeviceList },
  template: `
  <div v-if="!!entity">
    <div class="row mb-3 mt-3">
      <div class="col-sm-4"><h1></h1></div>
      <div class="col-sm-4 d-none d-lg-block"><h3>Stranka</h3></div>
      <div class="col-sm-4 text-end"></div>
    </div>
    <div class="row mb-3">
      <label class="col-form-label col-sm-4">Naziv</label>
      <div class="col-sm-8">
        <input class="form-control" type="text" v-model="entity.fullName" :disabled="!canChange" required />
      </div>
    </div>
    <div class="row mb-3">
      <label class="col-form-label col-sm-4">Kratek naziv</label>
      <div class="col-sm-8">
        <input class="form-control" type="text" v-model="entity.shortName" :disabled="!canChange" required />
      </div>
    </div>
    <div class="row mb-3">
      <label class="col-form-label col-sm-4">Naslov</label>
      <div class="col-sm-8">
        <input class="form-control" type="text" v-model="entity.address" :disabled="!canChange" required />
      </div>
    </div>

   <RequiredWarning v-show="!isValid && canChange" />

    <div class="row mb-5">
      <div class="col-2 text-start">
      </div>
      <div class="col-10 text-end">
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Facilities' })">Prekliči</button>
        <button  v-show="false" class="btn btn-success btn-lg me-3" type="button" @click="saveEntityAsync" :disabled="!isValid || !canChange">Shrani</button>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <DeviceList :devices="devices" />
      </div>
    </div>

  </div>

  `,
  setup() {
    const route = VueRouter.useRoute()
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const entity = ref()
    const devices = ref([])

    const isNew = computed(() => route.params.deviceId === "new")
    const canChange = computed(() => worker.isAdmin.value)
    const isValid = computed(() =>
      entity.value?.fullName?.length > 0 &&
      entity.value?.shortName?.length > 0 &&
      entity.value?.address?.length > 0)

    const loadEntityAsync = async () => {
      devices.value = await listEntitiesAsync(instance, "Devices", `?variant=Full&customerId=${route.params.customerId}`)
      entity.value = await getCustomerAsync(instance, route.params.customerId)
    }

    const saveEntityAsync = async () => {
      const result = await saveCustomerAsync(instance, entity.value, isNew.value)
      if (!result) return
      if (isNew.value) {
        router.push({ name: 'CustomerEdit', params: { customerId: entity.value.id, keepMessages: true } })
      } else {
        await loadEntityAsync()
      }
    }

    watch(() => route.params.customerId, () => loadEntityAsync())

    onMounted(async () => {
      await loadEntityAsync()
    })

    return {
      entity,
      devices,
      canChange,
      isNew,
      isValid,
      saveEntityAsync
    }

  }
}

export default CustomerEdit

import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { listEntitiesAsync, saveSimpleEntityAsync } from '../api'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

export default Notifications = {
  template: `

  <div class="alert alert-primary" role="alert"
    v-for="n in notifications"
    v-if="!editing && !worker.isAdmin.value && notifications.length && notifications[0]?.content?.length">
    {{ n.content }}
  </div>

  <div class="alert alert-primary" role="alert" v-for="n in notifications" v-if="!editing && worker.isAdmin.value">
    <div class="row">
      <div class="col-10">
        {{ n.content }}
      </div>
      <div class="col" v-if="worker.isAdmin.value">
        <button type="button" class="btn btn-secondary" @click="editing=n">✎</button>
      </div>
    </div>
  </div>

  <div class="alert alert-danger" role="alert" v-if="!notifications.length && isDone && worker.isAdmin.value && !editing">
    <button type="button" class="btn btn-secondary" @click="editing = { isNew: true }">✎</button>
  </div>

  <div class="alert alert-info" role="alert" v-if="!!editing">
    <div class="row">
      <div class="col-10">
        <input type="text" class="form-control" v-model="editing.content">
      </div>
      <div class="col">
        <button type="button" class="btn btn-success" @click="saveEntityAsync">Shrani</button>
      </div>
    </div>
  </div>
  `,
  setup() {
    const notifications = ref([])
    const isDone = ref(false)
    const editing = ref(null)
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    onMounted(async () => {
      notifications.value = await listEntitiesAsync(instance, "Notifications")
      isDone.value = true
    })

    const saveEntityAsync = async () => {
      const result = await saveSimpleEntityAsync(instance, "Notification", editing.value, editing.value.isNew)
      if (!result) return
      editing.value = null
      notifications.value = await listEntitiesAsync(instance, "Notifications")
    }

    return {
      saveEntityAsync,
      editing,
      worker,
      isDone,
      notifications
    }
  }
}

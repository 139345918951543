import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { listEntitiesAsync } from '../api'
import { EMPTY_GUID } from '../utils/constants'

const Warehouses = {
  name: "Commissions",
  template: `
  <div class="row">
    <div class="col-sm-4">
      <label class="form-label" for="is-accepted">Prikaži tudi prenešene v dlančnik</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-accepted" v-model="includeAccepted" />
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Nalog (SDN/PDN)</th>
              <th scope="col">V skladišče</th>
              <th scope="col">Količina</th>
              <th scope="col">Material</th>
              <th scope="col">Datum komisioniranja</th>
              <th scope="col">V dlančnik</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cm in flatEntities" @click="select(cm)" role="button">
              <td>{{ cm.name }}</td>
              <td>{{ cm.warehouseName }}</td>
              <td>{{ cm.quantity }}</td>
              <td>{{ cm.opPisProductID }}</td>
              <td>{{ $dayjs.utc(cm.dateCreated).local().format('D.M.YY HH:mm') }}</td>
              <td>{{ cm.dateAccepted ? $dayjs.utc(cm.dateAccepted).local().format('D.M.YY HH:mm') : "N/A"  }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  `,
  setup (props, { emit }) {
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()

    const includeAccepted = ref(true)
    const entities = ref([])

    const flatEntities = computed(() => {
      return entities.value.flatMap(c => c.materials.map(m => { return {
        name: c.name,
        warehouseName: c.warehouseName,
        quantity: m.quantity,
        opPisProductID: m.opPisProductID,
        dateCreated: c.dateCreated,
        dateAccepted: c.dateAccepted,
        projectId: c.projectId,
        serviceOrderId: c.serviceOrderId,
      }}))
    })

    const select = row => {
      if (row.projectId !== EMPTY_GUID) {
        router.push({ name: 'Project', params: { projectId: row.projectId } })
      } else if (row.serviceOrderId !== EMPTY_GUID)  {
        router.push({ name: 'ServiceOrder', params: { workOrderId: row.serviceOrderId } })
      } else {
        console.error("No project or service order ID")
      }
    }

    const loadEntitiesAsync = async () =>
      entities.value = await listEntitiesAsync(instance, "CommissionsAdmin",
        `?variant=Full&limitDays=14&includeAccepted=${includeAccepted.value}`)

    watch(() => includeAccepted.value, loadEntitiesAsync)

    onMounted(async () => await loadEntitiesAsync())

    return { includeAccepted, flatEntities, entities, worker, select }
  }

}

export default Warehouses

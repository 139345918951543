import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import {
  getFacilityAsync,
  saveSimpleEntityAsync,
  listEntitiesAsync,
  saveFacilityContactAsync,
  deleteFacilityContactAsync,
} from "../api"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"
import { EMPTY_GUID } from "../utils/constants"
import { useMsal } from "../composition-api/useMsal"
import { DeviceList } from "../components/DeviceList"
import { FacilityContactEdit } from "../components/FacilityContactEdit"
import { useWorkOrders } from "../composition-api/useWorkOrders"
import { useProjects, ProjectsCard } from "../composition-api/useProjects"
import ServiceOrdersCard from "../components/ServiceOrdersCard"

const FacilityEdit = {
  name: "FacilityEdit",
  components: { DeviceList, FacilityContactEdit, ProjectsCard, ServiceOrdersCard },
  template: `
  <div v-if="!!entity">
    <div class="row mb-3 mt-3">
      <div class="col-sm-4"><h1></h1></div>
      <div class="col-sm-4 d-none d-lg-block"><h3>Objekt</h3></div>
      <div class="col-sm-4 text-end">
        <router-link :to="{  name: 'CustomerEdit', params: { customerId: entity.customerID } }" v-if="entity.customerID" class="link-warning ms-3">Stranka</router-link>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-8">
        <div class="row mb-3" v-show="!isNew">
          <label class="form-check-label col-sm-4" for="is-active">Aktiven</label>
          <div class="col-sm-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="is-active" v-model="entity.isActive"  />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Naziv</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="entity.name" :disabled="!canChange" required />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Stranka</label>
          <div class="col-sm-8">
            <SelectEntity apiEntity="Customers" v-model="entity.customerID" required />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Naslov</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="entity.address" :disabled="!canChange" required />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Poštna številka</label>
          <div class="col-sm-2">
            <input class="form-control" type="text" v-model="entity.postCode" :disabled="!canChange" required />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Kraj</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="entity.postCity" :disabled="!canChange" required />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Referenca stranke (unikatno!)</label>
          <div class="col-sm-8">
            <input class="form-control" type="text" v-model="entity.facilityNumber" :disabled="!canChange" />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Lat (45 do 47)</label>
          <div class="col-sm-4">
            <input class="form-control" pattern="[0-9.]+" type="number" step="any" v-model="entity.lat" :disabled="!canChange" required />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-form-label col-sm-4">Lng (13 do 17)</label>
          <div class="col-sm-4">
            <input class="form-control" pattern="[0-9.]+" type="number" step="any" v-model="entity.lng" :disabled="!canChange" required />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card mb-3" v-if="!isNew && !editFacilityContacts">
          <div class="card-body">
            <h5 class="card-title">Podpisniki <button class="btn btn-sm btn-outline-info" @click="editFacilityContacts = true">Urejaj</button></h5>
            <ul>
              <li v-for="c in entity.contacts" class="mt-1">
                {{ c.name }} [{{ c.email }}]
              </li>
            </ul>
          </div>
        </div>

        <div class="card mb-3" v-if="!isNew && editFacilityContacts">
          <div class="card-body">
            <h5 class="card-title">Podpisniki</h5>
            <ul>
              <li v-for="c in entity.contacts" class="mt-1">
                {{ c.name }} [{{ c.email }}]
                <button class="btn btn-sm btn-outline-danger" @click="localDeleteFacilityContactAsync(c)">Odstrani</button>
              </li>
            </ul>
            <hr />
            <FacilityContactEdit
              v-model="facilityContact"
              @save="localSaveFacilityContactAsync(facilityContact)" />
          </div>
        </div>
      </div>
    </div>

    <RequiredWarning v-show="!isValid && canChange" />

    <div class="row mb-5">
      <div class="col-2 text-start">
      </div>
      <div class="col-10 text-end">
        <a class="me-3" role="button" @click="$router.push({ name: 'DeviceEdit', params: { deviceId: 'new', customerId: entity.customerID,  facilityId: entity.id } })" v-show="!isNew">Nova naprava</a>
        <button class="btn btn-outline-secondary me-3" type="button" @click="$router.push({ name: 'Facilities' })">Prekliči</button>
        <button class="btn btn-success btn-lg me-3" type="button" @click="saveEntityAsync" :disabled="!isValid || !canChange">Shrani</button>
      </div>
    </div>

    <div class="row mt-5" v-show="!isNew" >
      <div class="col">
        <ServiceOrdersCard class="card mb-3"
          :facilityID="entity.id"
          :showLinkToMap="false"
          statuses="Completed,Finalized,AccountingDone"
          title="Delovni nalogi" subTitle="Completed,Finalized,AccountingDone" />
      </div>
      <div class="col">
        <ProjectsCard class="card mb-3"
          :projects="projects"
          :pagingData="projectPaging"
          subTitle="Finalized,Reviewed,AccountingDone" />

        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title d-none d-lg-block">Kontrolni listi termometrov</h5>
            <p class="card-text">
              <ul>
                <li v-for="cr in entity.devices.map(d => d.calibrationReports).flat()">
                  <a :href="$AZURE_URI + '/Generated/CalibrationReport/' + cr.serviceOrderID + '?deviceId=' + cr.deviceID"
                    target="_blank">{{ $dayjs(cr.dateCreated).format($DATE_FORMAT_DISPLAY) }}</a>
                </li>
              </ul>
            </p>
          </div>
        </div>

      </div>
    </div>

    <div class="row mt-5" v-show="!isNew && entity.devices?.length > 0" >
      <div class="col">
        <DeviceList :devices="entity.devices" />
      </div>
    </div>

  </div>

  `,
  setup() {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()
    const { resetPaging: resetProjectPaging, filters: projectFilters, projects, paging: projectPaging } = useProjects()

    const facilityContact = ref({})
    const entity = ref()
    const editFacilityContacts = ref(false)

    const isNew = computed(() => route.params.facilityId === "new")
    const canChange = computed(() => worker.isAdmin.value)
    const isValid = computed(
      () =>
        entity.value?.name?.length > 0 &&
        entity.value?.address?.length > 0 &&
        entity.value?.postCode?.length > 0 &&
        entity.value?.postCity?.length > 0 &&
        entity.value.customerID !== EMPTY_GUID &&
        entity.value?.lat >= 45 &&
        entity.value?.lat < 47 &&
        entity.value?.lng >= 13 &&
        entity.value?.lng < 17
    )

    const loadEntityAsync = async () => {
      entity.value = await getFacilityAsync(instance, route.params.facilityId, true)
      projectFilters.value.facility = entity.value.id
      projectFilters.value.status = "Finalized,Reviewed,AccountingDone"
      projectPaging.value.itemsPerPage = 25
    }

    const localDeleteFacilityContactAsync = async (c) => {
      await deleteFacilityContactAsync(instance, c)
      await loadEntityAsync()
    }

    const localSaveFacilityContactAsync = async (contact) => {
      await saveFacilityContactAsync(instance, contact, entity.value)
      facilityContact.value = {}
      await loadEntityAsync()
    }

    const saveEntityAsync = async () => {
      const result = await saveSimpleEntityAsync(instance, "Facility", entity.value, isNew.value)
      if (!result) return
      if (isNew.value) {
        router.push({ name: "FacilityEdit", params: { facilityId: entity.value.id, keepMessages: true } })
      } else {
        await loadEntityAsync()
      }
    }

    watch(
      () => route.params.facilityId,
      () => loadEntityAsync()
    )

    watch(
      () => ({ ...projectFilters.value }),
      () => resetProjectPaging() // will also fire getOrdersAsync()
    )

    onMounted(async () => {
      await loadEntityAsync()
    })

    return {
      instance,
      entity,
      canChange,
      isNew,
      isValid,
      localDeleteFacilityContactAsync,
      localSaveFacilityContactAsync,
      saveEntityAsync,
      facilityContact,
      loadEntityAsync,
      editFacilityContacts,
      projects,
      projectPaging,
    }
  },
}

export default FacilityEdit
